export default {
  form: {
    profileManagement: 'إدارة الملف الشخصي',
    myInfo: 'معلوماتي',
    myInfoOverview: 'نظرة عامة على معلوماتي',
    profilePic: 'صورة الملف الشخصي',
    legalNameTitle: 'الاسم القانوني واللقب',
    designation: 'التسمية الوظيفية',
    nationalID: 'الرقم الوطني',
    phoneNumber: 'رقم الهاتف',
    verified: 'تم التحقق',
    verify: 'تحقق',
    emailAddress: 'عنوان البريد الإلكتروني',
    address: 'العنوان',
    about: 'حول',
    updateMyInfo: 'تحديث معلوماتي',
  },
};
