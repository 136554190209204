import { PdfFileIcon } from '@/components/Icons/PdfFileIcon';
import { ExcelFileIcon } from '@/components/Icons/ExcelFileIcon';
import FolderIcon from '@/components/Icons/FolderIcon';
import { DocFileIcon } from '@/components/Icons/DocFileIcon';
import { PowerpointFileIcon } from '@/components/Icons/PowerpointFileIcon';
import { XlsFileIcon } from '@/components/Icons/XlsFileIcon';
import { PlainFileIcon } from '@/components/Icons/PlainFileIcon';
import { TextFileIcon } from '@/components/Icons/TextFileIcon';
import { ImageFileIcon } from '@/components/Icons/ImageFileIcon';

const FilesIcons = ({ mimeType }: { mimeType: string }) => {
  const iconsMap: { [key: string]: JSX.Element } = {
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': <XlsFileIcon />,
    'vnd.openxmlformats-officedocument.presentationml.presentation': <PowerpointFileIcon />,
    docx: <DocFileIcon />,
    'vnd.openxmlformats-officedocument.wordprocessingml.document': <DocFileIcon />,
    deleted: <PlainFileIcon />,
    folder: <FolderIcon />,
    csv: <ExcelFileIcon />,
    pdf: <PdfFileIcon />,
    doc: <DocFileIcon />,
    msword: <DocFileIcon />,
    plain: <TextFileIcon />,
    jpeg: <ImageFileIcon />,
    jpg: <ImageFileIcon />,
    png: <ImageFileIcon />,
  };

  return iconsMap[mimeType] ?? <PlainFileIcon />;
};

export default FilesIcons;
