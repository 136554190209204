import { Route, Routes } from 'react-router-dom';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import AddNewCompany from '../views/AddNewCompany';
import ExpiryChecker from '@/components/ExpiryChecker';
import { Suspense, lazy } from 'react';
import Dashboard from '../views/Dashboard';
import InvestmentOverview from '../views/InvestmentOverview';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const EditCompany = lazy(() => import('../views/EditCompany'));

export const CompaniesRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute allowedPermissions={['company.dashboard']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute allowedPermissions={['company.my-investment']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="investment-overview" element={<InvestmentOverview />} />
          </Route>
        </Route>
        <Route path="new" element={<AddNewCompany />} />
        <Route path=":id/edit" element={<EditCompany />} />
      </Routes>
    </Suspense>
  );
};
