const TaskIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
  active,
}: {
  width?: number;
  height?: number;
  stroke?: string;
  active?: boolean;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={active ? '#6430DA4A' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.27753 6.66016H13.215"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78503 6.66016L5.34753 7.22266L7.03503 5.53516"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.27754 11.9102H13.215"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78501 11.9102L5.34751 12.4727L7.03501 10.7852"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75006 16.5H11.2501C15.0001 16.5 16.5001 15 16.5001 11.25V6.75C16.5001 3 15.0001 1.5 11.2501 1.5H6.75006C3.00006 1.5 1.50006 3 1.50006 6.75V11.25C1.50006 15 3.00006 16.5 6.75006 16.5Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TaskIcon;
