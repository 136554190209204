import { SVGProps } from 'react';
const ActiveHomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#6430DA"
      fillOpacity={0.29}
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.9}
      d="m6.615 2.127-4.042 3.15c-.675.525-1.223 1.642-1.223 2.49v5.557a3.169 3.169 0 0 0 3.158 3.165h8.685a3.167 3.167 0 0 0 3.157-3.157v-5.46c0-.908-.607-2.07-1.35-2.588l-4.635-3.247c-1.05-.735-2.737-.698-3.75.09Z"
    />
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M8.55 13.49v-2.25"
    />
  </svg>
);
export default ActiveHomeIcon;
