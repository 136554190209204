import { Input } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  paddingBlock: themeToken.paddingXXS,
  paddingInline: themeToken.paddingSM,
};

const globalTheme = {
  borderRadius: themeToken.borderRadiusSM,
  borderRadiusLG: themeToken.borderRadiusSM,
};

export const inputTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledInput = styled(Input)`
  &.ant-input-group-wrapper {
    width: 100%;
    .ant-input-wrapper {
      height: ${themeToken.controlHeightLG}px;
      width: 100%;
      .ant-input {
        height: inherit;
        .ant-input-input-wrap {
          height: 100%;
        }
      }
    }
  }
  & ~ .ant-input-affix-wrapper {
    padding: 0;
    height: ${themeToken.controlHeightLG}px;
    padding-inline-end: ${themeToken.paddingSM}px;
    & > .ant-input {
      padding: ${themeToken.paddingXXS}px ${themeToken.paddingSM}px;
      border-radius: ${themeToken.borderRadiusSM}px;
    }
  }
`;
