import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Upload from 'antd/lib/upload/Upload';

import type { UploadProps } from 'antd/es/upload';

import { useUploadFile } from '../api/uploadFile';

import themeToken from '@lib/theme/tokens/index';

import { StyledButton } from '@/lib/theme/components/Button';

import UploadDocumentIcon from '@/components/Icons/UploadDocumentIcon';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useOutletContext } from 'react-router-dom';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { App } from 'antd';

const FileUpload = ({ folderId, disabled }: { folderId: number; disabled: boolean }) => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');
  const { recheckLimits } = useOutletContext<LimitsCheckerContext>() || {};
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [fileList, setFileList] = useState<File[]>([]);
  const [succeededUploads, setSucceededUploads] = useState<number>(0);

  const { mutate: uploadFileMutation, isPending } = useUploadFile({
    postSuccess: () => {
      recheckLimits();
      setFileList([]);
      setSucceededUploads((prev) => prev + 1);
    },
  });
  const { notification: toaster } = App.useApp();

  const uploadFiles = async () => {
    for (const file of fileList) {
      uploadFileMutation({
        company_id: selectedCompany?.cid as number,
        folder_id: folderId,
        file,
      });
    }
  };

  const uploadProps: UploadProps<File> = {
    showUploadList: false,
    multiple: true,
    beforeUpload: (_, list) => {
      setFileList([...list]);
      return false;
    },
  };

  useEffect(() => {
    if (fileList.length) {
      void uploadFiles();
    }
  }, [fileList]);

  useEffect(() => {
    if (succeededUploads === fileList.length && succeededUploads) {
      toaster.success({
        message: 'Added Successfully',
        description: 'Your File Added to data room Successfully',
      });
      sendMixpanelEvent('upload-file-btn-clicked');

      setFileList(() => []);
      setSucceededUploads(() => 0);

      void recheckLimits();
    }
  }, [succeededUploads]);

  return (
    <Upload {...uploadProps}>
      <StyledButton
        style={{ padding: `${themeToken.paddingXXS}px ${themeToken.paddingLG}px` }}
        size="small"
        ghost
        type="primary"
        icon={<UploadDocumentIcon />}
        loading={isPending}
        disabled={isPending || disabled}
      >
        {t('uploadFile')}
      </StyledButton>
    </Upload>
  );
};

export default FileUpload;
