import { UserDTO } from '@/types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface UserDataState {
  userData: UserDTO | null;
  setUserData: (userData: UserDTO | null) => void;
}

export const useUserDataStore = create<UserDataState>()(
  devtools(
    persist(
      (set) => ({
        userData: null,
        setUserData: (userData) => set(() => ({ userData })),
      }),
      { name: 'userDataStore' },
    ),
  ),
);
