import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { axiosClient } from '@/lib/axios';
import { AxiosError } from 'axios';
import { queryClient } from '@/lib/react-query';

export type ProfileUpdatePayload = {
  showSuccessToaster?: boolean;
  payload: {
    profile_image?: string;
    title?: string;
    name?: string;
    designation?: string;
    national_id?: string;
    mobile?: string;
    mail?: string;
    address?: string;
    about?: string;
    locale?: 'en' | 'ar';
  };
};

type ProfileUpdateResponse = {
  data: {
    message: 'string';
  };
};

export const updateProfile = async ({ payload }: ProfileUpdatePayload): Promise<ProfileUpdateResponse> => {
  const { data } = await axiosClient.put<ProfileUpdateResponse>(`/users`, payload);

  return data;
};

type UseUpdateProfileInfoOptions = {
  config?: Partial<
    UseMutationOptions<ProfileUpdateResponse, AxiosError<{ message?: string; error?: string }>, ProfileUpdatePayload>
  >;
  postSuccess?: (locale: 'en' | 'ar') => void;
};

export const useUpdateProfileInfo = ({ config, postSuccess }: UseUpdateProfileInfoOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message || error.response?.data.error,
      });
    },
    onSuccess: async (_, reqVars) => {
      await queryClient.invalidateQueries({
        queryKey: ['profile-info'],
      });
      if (reqVars.showSuccessToaster) {
        toaster.success({
          message: 'Updated Successfully',
          description: 'Profile updated successfully!',
        });
      }
      postSuccess?.(reqVars.payload.locale || 'en');
    },
    ...config,
    mutationFn: updateProfile,
  });
};
