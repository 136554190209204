import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import axiosClient from '@/lib/axios';

import type { DeleteFileResponse } from '../types';

type DeleteFileParams = { company_id: number; file_id: number };

export const deleteFile = async ({ company_id, file_id }: DeleteFileParams) => {
  const response = await axiosClient.delete<DeleteFileResponse>(`/companies/${company_id}/data-rooms/files/${file_id}`);

  return response.data;
};

type UseDeleteFileOptions = {
  postSuccess?: () => void;
  config?: Partial<UseMutationOptions<DeleteFileResponse, AxiosError<{ message: string }>, DeleteFileParams>>;
};

export const useDeleteFile = ({ postSuccess, config }: UseDeleteFileOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['folders'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['folder-data'],
      });

      toaster.success({
        message: 'Deleted Successfully',
      });

      postSuccess?.();
    },
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    mutationFn: deleteFile,
    ...config,
  });
};
