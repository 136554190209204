import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import 'antd/dist/reset.css';
import { ConfigProvider, App as AntdApp } from 'antd';
import theme from '@lib/theme/index.ts';
import '../i18n.ts';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query.ts';
import { BrowserRouter } from 'react-router-dom';
import { MixpanelProvider } from '@context/mixpanel/MixpanelProvider.tsx';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ar';
import * as Sentry from '@sentry/react';

dayjs.extend(weekday);
dayjs.extend(localeData);

const customRenderEmpty = () => <div style={{ textAlign: 'center' }}>Data Not Found</div>;

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;
const MIXPANEL_CONFIG = {
  debug: import.meta.env.MODE === 'development',
  ignore_dnt: true,
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.MODE !== 'development',
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider theme={theme} renderEmpty={customRenderEmpty}>
      <React.StrictMode>
        <AntdApp>
          <BrowserRouter>
            <MixpanelProvider token={MIXPANEL_TOKEN} config={MIXPANEL_CONFIG}>
              <App />
            </MixpanelProvider>
          </BrowserRouter>
        </AntdApp>
      </React.StrictMode>
    </ConfigProvider>
  </QueryClientProvider>,
);
