import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import { Country } from '@/types';

type CountriesResponse = {
  data: Country[];
};

export const geCountries = async (): Promise<Country[]> => {
  const { data } = await axiosClient.get<CountriesResponse>(`/misc/countries`);
  return data?.data;
};
type QueryFnType = typeof geCountries;

type UseGeCountriesOptions = {
  config?: QueryFunction<QueryFnType>;
};

export const useGetCountries = ({ config }: UseGeCountriesOptions) => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: geCountries,
    ...config,
  });
};
