import { QueryFunction, useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import type { InvestmentOverview } from '../types';

type GetInvestmentOverviewPayload = {
  companyID: number;
};

type GetInvestmentOverviewResponse = {
  data: InvestmentOverview;
};

export const getInvestmentOverview = async ({
  companyID,
}: GetInvestmentOverviewPayload): Promise<InvestmentOverview> => {
  const { data } = await axiosClient.get<GetInvestmentOverviewResponse>(
    `companies/${companyID}/dashboard/investment-overview`,
  );
  return data?.data;
};
type QueryFnType = typeof getInvestmentOverview;

type UseGetInvestmentOverviewOptions = {
  companyID: number;
  config?: QueryFunction<QueryFnType>;
};

export const useGetInvestmentOverview = ({ companyID, config }: UseGetInvestmentOverviewOptions) => {
  return useQuery({
    queryKey: ['investment-overview', companyID],
    queryFn: () => getInvestmentOverview({ companyID }),
    ...config,
  });
};
