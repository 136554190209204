import { useTranslation } from 'react-i18next';
import { Divider, Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

import { StyledDrawer } from '@/features/captable/components/Shared/StyledDrawer';
import { StyledButton } from '@/lib/theme/components/Button';

import DetailsItem from '@/features/captable/components/Shared/DetailsItem';

import { VestingSchedule } from '../../types';

const { Text } = Typography;

const InvestmentOverviewDetails = ({
  open = false,
  setOpen,
  data,
  grantName,
}: {
  data: VestingSchedule;
  open: boolean;
  setOpen: (open: boolean) => void;
  grantName: string;
}) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  return (
    <StyledDrawer
      className="drawer"
      placement="right"
      size="large"
      closable={false}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Flex className="drawer-header" justify="space-between" align="center">
        <Flex vertical>
          <Text
            style={{
              fontSize: themeToken.fontSizeLG,
              color: themeToken['branding-primary-6'],
              fontWeight: 600,
              marginBottom: themeToken.marginXXS,
            }}
          >
            {grantName}
          </Text>

          <Text
            style={{
              color: themeToken['branding-natural-6'],
              fontSize: themeToken.fontSize,
            }}
          >
            {t('grantName')}
          </Text>
        </Flex>
      </Flex>

      <Divider
        style={{
          marginBlock: 0,
          borderColor: themeToken['branding-natural-1'],
        }}
      />

      <Flex vertical className="drawer-body" justify="space-between">
        <div>
          <Flex>
            <DetailsItem isLoading={false} label={t('name')} value={data?.name} />
            <DetailsItem isLoading={false} label={t('cliff')} value={data?.cliff} />
          </Flex>

          <Divider
            style={{
              marginBlock: themeToken.margin,
              borderColor: themeToken['branding-natural-3'],
            }}
          />

          <Flex vertical>
            <Flex>
              <DetailsItem isLoading={false} label={t('duration')} value={data?.duration} />
              <DetailsItem isLoading={false} label={t('frequency')} value={data?.frequency} />
            </Flex>
            <Divider
              style={{
                marginBlock: themeToken.margin,
                borderColor: themeToken['branding-natural-3'],
              }}
            />
            <Flex>
              <DetailsItem isLoading={false} label={t('upfrontVestingAmount')} value={data?.upfront_vesting_amount} />
            </Flex>
          </Flex>
        </div>
        <StyledButton
          style={{
            width: '100%',
          }}
          type="primary"
          onClick={() => setOpen(false)}
        >
          {t('close')}
        </StyledButton>
      </Flex>
    </StyledDrawer>
  );
};

export default InvestmentOverviewDetails;
