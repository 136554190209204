const GoogleIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 10.8614C19.6 10.1523 19.5364 9.47048 19.4182 8.81593H10V12.6841H15.3818C15.15 13.9341 14.4455 14.9932 13.3864 15.7023V18.2114H16.6182C18.5091 16.4705 19.6 13.9068 19.6 10.8614Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 20.6342C12.6998 20.6342 14.9635 19.7387 16.618 18.2115L13.3862 15.7024C12.4907 16.3024 11.3453 16.6569 9.99984 16.6569C7.39529 16.6569 5.19075 14.8978 4.40439 12.5342H1.06348V15.1251C2.70893 18.3933 6.09075 20.6342 9.99984 20.6342Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40455 12.5343C4.20455 11.9343 4.09091 11.2934 4.09091 10.6343C4.09091 9.97517 4.20455 9.33427 4.40455 8.73427V6.14336H1.06364C0.386364 7.49336 0 9.02063 0 10.6343C0 12.2479 0.386364 13.7752 1.06364 15.1252L4.40455 12.5343Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 4.61143C11.468 4.61143 12.7862 5.11597 13.8226 6.10688L16.6908 3.2387C14.9589 1.62506 12.6953 0.634155 9.99984 0.634155C6.09075 0.634155 2.70893 2.87506 1.06348 6.14325L4.40439 8.73416C5.19075 6.37052 7.3953 4.61143 9.99984 4.61143Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
