const DocumentDownloadIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.71875 8.74023V13.3027L9.23958 11.7819" fill="#2A186A" />
      <path
        d="M7.71875 8.74023V13.3027L9.23958 11.7819"
        stroke="#2A186A"
        strokeWidth="1.14062"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71874 13.3021L6.19791 11.7812"
        stroke="#2A186A"
        strokeWidth="1.14062"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6042 7.97982V11.7819C17.6042 15.584 16.0833 17.1048 12.2813 17.1048H7.71876C3.91668 17.1048 2.39584 15.584 2.39584 11.7819V7.2194C2.39584 3.41732 3.91668 1.89648 7.71876 1.89648H11.5208"
        stroke="#2A186A"
        strokeWidth="1.14062"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6042 7.97982H14.5625C12.2813 7.97982 11.5208 7.2194 11.5208 4.93815V1.89648L17.6042 7.97982Z"
        stroke="#2A186A"
        strokeWidth="1.14062"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentDownloadIcon;
