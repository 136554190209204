import themeToken from '@lib/theme/tokens/index';
import { ReactElement } from 'react';
import { Flex, Grid, Layout } from 'antd';
import { StyledHeader, StyledLayout } from '@lib/theme/components/Layout';
import { useNavigate } from 'react-router-dom';
import { handleChangeLanguage } from '@/utils/handleChangeLanguage';
import { useTranslation } from 'react-i18next';

import LogoImg from '@/assets/logo_en.png';
import SearchIcon from '@/components/Icons/SearchIcon';
import { HeaderSearchInput } from '@/components/Layout/components/Header/SearchInput';
import { HeaderLanguageButton } from '@/components/Layout/components/Header/Language';
import { HeaderGoPremiumButton } from '@/components/Layout/components/Header/GoPreimum';
import { StyledButton } from '@/lib/theme/components/Button';

const { Content } = Layout;

const PublicDataRoomWrapper = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('layout', {
    keyPrefix: 'header',
  });
  return (
    <StyledLayout>
      <StyledHeader>
        <img src={LogoImg} alt="logo" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
        <HeaderSearchInput placeholder={t('searchFor')} prefix={<SearchIcon />} />
        <Flex gap={16} align="center">
          <HeaderLanguageButton
            size="small"
            icon={<>{i18n.language === 'en' ? 'ع' : 'En'}</>}
            onClick={() => {
              handleChangeLanguage(i18n.language === 'en' ? 'ar' : 'en');
            }}
            type="text"
          />
          <HeaderGoPremiumButton
            size="small"
            type="primary"
            style={{
              background: themeToken['branding-secondary-6'],
              paddingInline: ` ${themeToken.paddingLG}px`,
            }}
            onClick={() => navigate('/auth/signup')}
          >
            Signup
          </HeaderGoPremiumButton>
          <StyledButton
            ghost
            size="small"
            type="primary"
            style={{
              paddingInline: ` ${themeToken.paddingLG}px`,
            }}
            onClick={() => navigate('/auth/signin')}
          >
            Login
          </StyledButton>
        </Flex>
      </StyledHeader>
      <Flex
        style={{
          height: '100%',
        }}
      >
        <Content
          style={{
            background: '#F4F7F9',
            marginTop: screens.lg ? 80 : 108,
            marginInlineStart: screens.lg ? 32 : 16,
            marginInlineEnd: screens.lg ? 32 : 16,
          }}
        >
          {children}
        </Content>
      </Flex>
    </StyledLayout>
  );
};

export default PublicDataRoomWrapper;
