export const ChartSkeleton = () => (
  <svg width="100%" height="398" viewBox="0 0 573 398" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.00147533" y="368.694" width="572.999" height="0.913037" rx="0.456518" fill="#656E89" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M572.997 1L543 17L566.5 5.24328C566.5 5.24328 14.6708 286.984 0 295.4C0 366.334 0 369 0 369C223.769 369 349.227 369 572.997 369V1Z"
      fill="url(#paint0_linear_4594_110016)"
    />
    <rect x="10" y="378" width="36" height="10.5" rx="5.25" fill="url(#paint1_linear_4594_110016)" />
    <rect x="134.75" y="378" width="45" height="10.5" rx="5.25" fill="url(#paint2_linear_4594_110016)" />
    <rect x="259.5" y="378" width="34" height="10.5" rx="5.25" fill="url(#paint3_linear_4594_110016)" />
    <rect x="385.25" y="378" width="33" height="10.5" rx="5.25" fill="url(#paint4_linear_4594_110016)" />
    <rect x="509" y="378" width="39" height="10.5" rx="5.25" fill="url(#paint5_linear_4594_110016)" />
    <defs>
      <linearGradient
        id="paint0_linear_4594_110016"
        x1="-4.42079e-06"
        y1="188.228"
        x2="572.997"
        y2="188.228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.05" />
        <stop offset="0.5" stopColor="#DBDBDB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4594_110016"
        x1="46"
        y1="383.342"
        x2="10"
        y2="383.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.05" />
        <stop offset="0.5" stopColor="#DBDBDB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4594_110016"
        x1="179.75"
        y1="383.342"
        x2="134.75"
        y2="383.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.05" />
        <stop offset="0.5" stopColor="#DBDBDB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4594_110016"
        x1="293.5"
        y1="383.342"
        x2="259.5"
        y2="383.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.05" />
        <stop offset="0.5" stopColor="#DBDBDB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4594_110016"
        x1="418.25"
        y1="383.342"
        x2="385.25"
        y2="383.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.05" />
        <stop offset="0.5" stopColor="#DBDBDB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4594_110016"
        x1="548"
        y1="383.342"
        x2="509"
        y2="383.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.05" />
        <stop offset="0.5" stopColor="#DBDBDB" />
      </linearGradient>
    </defs>
  </svg>
);
