import { axiosClient } from '@/lib/axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { ConvertibleInstrumentsData } from '../../types';
import { Metadata, PaginationParams } from '@/types';

type ConvertibleInstrumentsResponse = {
  data: ConvertibleInstrumentsData[];
  metadata: Metadata;
};

type ConvertibleInstrumentsParams = {
  urlParams: {
    companyID: number;
  };
  queryParams: {
    sort_by?: keyof ConvertibleInstrumentsData;
    sort_dir?: 'asc' | 'desc';
    q?: string;
  } & PaginationParams;
};

export const getConvertibleInstruments = async ({
  urlParams,
  queryParams,
}: {
  urlParams: ConvertibleInstrumentsParams['urlParams'];
  queryParams: ConvertibleInstrumentsParams['queryParams'];
}): Promise<ConvertibleInstrumentsResponse> => {
  const { data } = await axiosClient.get<ConvertibleInstrumentsResponse>(
    `/companies/${urlParams.companyID}/cap-table/instruments`,
    {
      params: queryParams,
    },
  );
  return data;
};
type QueryFnType = typeof getConvertibleInstruments;

type UseGetConvertibleInstrumentsOptions = {
  queryParams: ConvertibleInstrumentsParams['queryParams'];
  urlParams: ConvertibleInstrumentsParams['urlParams'];
  config?: QueryFunction<QueryFnType>;
};

export const useGetConvertibleInstruments = ({
  urlParams,
  queryParams,
  config,
}: UseGetConvertibleInstrumentsOptions) => {
  return useQuery({
    queryKey: ['convertible-instruments', urlParams, queryParams],
    queryFn: () =>
      getConvertibleInstruments({
        urlParams,
        queryParams,
      }),
    ...config,
  });
};
