import { Flex } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const iR = 50;
const oR = 100;

const TachometerChart = ({ data }: { data: { value: number; color: string }[] }) => {
  return (
    <Flex align="center" justify="center" style={{ width: '100%', height: 210 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <text x="45%" y="50%" fill="#2A186A">
            {data[0].value}%
          </text>
        </PieChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default TachometerChart;
