import { useMemo, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, Input, Skeleton, Typography } from 'antd';
import styled from 'styled-components';

import { StyledButton } from '@/lib/theme/components/Button';

import PlusIcon from '@/components/Icons/PlusIcon';
import MinusIcon from '@/components/Icons/MinusIcon';
import themeToken from '@lib/theme/tokens/index';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const { Search } = Input;
const { Text } = Typography;
const { useBreakpoint } = Grid;

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const StyledDocument = styled(Document)`
  &.react-pdf__Document {
    & > .react-pdf__Page {
      & > .react-pdf__Page__canvas {
        margin: auto;
      }
    }
  }
`;

type Props = {
  fileUrl: string;
  windowWidth: number;
};

const scaleStep = 0.1;
const minScale = 0.1;
const maxScale = 2;

const PdfContainer = ({ fileUrl, windowWidth }: Props) => {
  const { t } = useTranslation('dataroom');
  const screens = useBreakpoint();
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const [pdfPages, setPdfPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPdfPages(numPages);
    setPageNumber(1);
  };

  const docOptions = useMemo(
    () => ({
      cMapUrl: '/cmaps/',
      standardFontDataUrl: '/standard_fonts/',
    }),
    [],
  );

  const goToNextPage = () => setPageNumber((prev) => Math.min(prev + 1, pdfPages || prev));
  const goToPrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));
  const goToInputPage = (value: string) => {
    setPageNumber(Math.min(+value || 1, pdfPages));
  };

  const handleZoomIn = () => {
    setPageScale((prev) => +Math.min(maxScale, prev + scaleStep).toFixed(1));
  };
  const handleZoomOut = () => {
    setPageScale((prev) => +Math.max(minScale, prev - scaleStep).toFixed(1));
  };

  const pdfPageWidth = useMemo(() => {
    const maxPdfWidth = 800;
    const PdfContainerPadding = 120;

    return !screens.lg ? Math.min(windowWidth, maxPdfWidth) - PdfContainerPadding : maxPdfWidth - PdfContainerPadding;
  }, [screens.lg, windowWidth]);

  return (
    <Flex
      vertical
      gap={16}
      ref={pdfContainerRef}
      style={{
        position: 'absolute',
        width: '100%',
        top: '0',
      }}
    >
      <Flex
        align="center"
        gap={8}
        justify="end"
        style={{
          marginBlockStart: '16px',
          paddingInline: '8px',
        }}
      >
        <Text
          style={{
            color: themeToken.colorPrimary,
            fontSize: themeToken.fontSize,
            fontWeight: themeToken.fontWeightStrong,
          }}
        >
          {t('zoom')}
        </Text>
        <StyledButton onClick={handleZoomIn} size="small" disabled={pageScale >= maxScale}>
          <PlusIcon />
        </StyledButton>
        <StyledButton onClick={handleZoomOut} size="small" disabled={pageScale <= minScale}>
          <MinusIcon />
        </StyledButton>
      </Flex>
      <StyledDocument
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        options={docOptions}
        onContextMenu={(ev: MouseEvent) => ev.preventDefault()}
        loading={<Skeleton />}
      >
        <Page pageNumber={pageNumber} width={pdfPageWidth} scale={pageScale} />
      </StyledDocument>
      <Flex align="center" gap={8} vertical>
        <Flex align="center" gap={8}>
          <StyledButton disabled={pageNumber <= 1} onClick={goToPrevPage} size="small">
            {t('previous')}
          </StyledButton>
          <StyledButton disabled={pageNumber >= (pdfPages || 1)} onClick={goToNextPage} size="small">
            {t('next')}
          </StyledButton>
          <Search
            addonBefore={t('goTo')}
            enterButton={t('page')}
            type="number"
            defaultValue={1}
            max={pdfPages}
            min={1}
            onSearch={goToInputPage}
          />
        </Flex>
        <Text>{t('pageOf', { pageNumber, pdfPages })}</Text>
      </Flex>
    </Flex>
  );
};

export default PdfContainer;
