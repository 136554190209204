import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { Flex } from 'antd';
export const StyledCompanyImageContainer = styled(Flex)<{ className?: string }>`
  cursor: pointer;
  border-inline-start: 2px solid
    ${(props) => (props.className === 'is-selected' ? themeToken['branding-secondary-6'] : 'transparent')};
  background-color: ${(props) =>
    props.className === 'is-selected' ? themeToken['branding-natural-5'] : 'transparent'};
  height: 60px;
  width: 60px;
  .ant-image {
    .ant-image-img.ant-image-img-placeholder {
      background: none;
    }
  }
  @media (min-width: ${themeToken.screenLG}px) {
    min-height: 64px;
    width: 64px;
    border-width: 3px;
  }
`;
