import { MutationFunction, useMutation } from '@tanstack/react-query';
import { App } from 'antd';

import { axiosClient } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import { CompanyLogoResponse } from '../types';
import { AxiosError } from 'axios';

type CompanyPayload = {
  file: File;
};

export const uploadCompanyLogo = async ({ file }: CompanyPayload): Promise<CompanyLogoResponse> => {
  const formData = new FormData();
  formData.append('type', 'company');
  formData.append('image', file, encodeURI(file.name));
  const { data } = await axiosClient.post<CompanyLogoResponse>(`/misc/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};
type UseUploadCompanyLogoOptions = {
  config?: MutationFunction<typeof uploadCompanyLogo>;
  postSuccess?: (url: string) => void;
};

export const UseUploadCompanyLogo = ({ config, postSuccess }: UseUploadCompanyLogoOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error: AxiosError<{ error: string }>) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      toaster.success({
        message: 'Added Successfully',
        description: 'Company Logo Added Successfully',
      });
      postSuccess?.(data.url);
    },
    ...config,
    mutationFn: uploadCompanyLogo,
  });
};
