export default {
  form: {
    editCompany: 'تعديل الشركة',
    createNewCompany: 'إنشاء شركة جديدة',
    personalizedExperience: 'تجربة شخصية متكاملة لملفك الشخصي، أضف معلومات شركتك لتحصل على أعلى قيمة من راس مال',
    companyLogo: 'شعار الشركة',
    addLogo: 'يرجى إضافة الشعار',
    companyName: 'اسم الشركة',
    addValue: 'يرجى إضافة القيمة',
    companyType: 'نوع الشركة',
    selectValue: 'يرجى اختيار القيمة',
    incorporationDate: 'تاريخ التأسيس',
    selectDate: 'اختر التاريخ',
    countryOfIncorporation: 'بلد التأسيس',
    countriesOfOperation: 'بلدان التشغيل',
    currency: 'العملة',
    submit: 'إرسال',
  },
};
