export const StarIcon = ({
  width = 16,
  height = 16,
  rotation = 0,
  fillColor = 'none',
}: {
  width?: number;
  height?: number;
  rotation?: number;
  fillColor?: string;
}) => (
  <svg
    style={{
      rotate: `${rotation}deg`,
      mixBlendMode: 'unset',
    }}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/star">
      <g id="star">
        <path
          id="Vector"
          d="M10.26 3.47334L11.2 5.35334C11.3267 5.61334 11.6667 5.86001 11.9533 5.91334L13.6533 6.19334C14.74 6.37334 14.9933 7.16001 14.2133 7.94667L12.8867 9.27334C12.6667 9.49334 12.54 9.92667 12.6133 10.24L12.9933 11.88C13.2933 13.1733 12.6 13.68 11.46 13L9.86667 12.0533C9.58 11.88 9.1 11.88 8.81334 12.0533L7.22 13C6.08 13.6733 5.38667 13.1733 5.68667 11.88L6.06667 10.24C6.12667 9.92001 6 9.48667 5.78 9.26667L4.45334 7.94001C3.67334 7.16001 3.92667 6.37334 5.01334 6.18667L6.71334 5.90667C7 5.86001 7.34 5.60667 7.46667 5.34667L8.40667 3.46667C8.92 2.45334 9.74667 2.45334 10.26 3.47334Z"
          fill={fillColor === 'none' ? 'white' : fillColor}
        />
        <path
          id="Vector_2"
          d="M5.33337 3.83333H1.33337C1.06004 3.83333 0.833374 3.60666 0.833374 3.33333C0.833374 3.05999 1.06004 2.83333 1.33337 2.83333H5.33337C5.60671 2.83333 5.83337 3.05999 5.83337 3.33333C5.83337 3.60666 5.60671 3.83333 5.33337 3.83333Z"
          fill={fillColor === 'none' ? 'white' : fillColor}
        />
        <path
          id="Vector_3"
          d="M3.33337 13.1667H1.33337C1.06004 13.1667 0.833374 12.94 0.833374 12.6667C0.833374 12.3933 1.06004 12.1667 1.33337 12.1667H3.33337C3.60671 12.1667 3.83337 12.3933 3.83337 12.6667C3.83337 12.94 3.60671 13.1667 3.33337 13.1667Z"
          fill={fillColor === 'none' ? 'white' : fillColor}
        />
        <path
          id="Vector_4"
          d="M2.00004 8.5H1.33337C1.06004 8.5 0.833374 8.27333 0.833374 8C0.833374 7.72667 1.06004 7.5 1.33337 7.5H2.00004C2.27337 7.5 2.50004 7.72667 2.50004 8C2.50004 8.27333 2.27337 8.5 2.00004 8.5Z"
          fill={fillColor === 'none' ? 'white' : fillColor}
        />
      </g>
    </g>
  </svg>
);
