import { Upload } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {};

export const uploadTheme = {
  ...defaultTheme,
};

export const StyledUpload = styled(Upload)<{ rounded?: boolean }>`
  &.ant-upload-wrapper {
    &.ant-upload-picture-card-wrapper {
      & > .ant-upload-list {
        & > .ant-upload,
        & > .ant-upload-list-item-container {
          width: 144px;
          height: 144px;
          background: ${themeToken['branding-natural-5']};
          margin: 0;
          border-radius: ${(props) => (props.rounded ? '100%' : themeToken.borderRadius)};
        }
      }
    }
  }
`;
