export const FolderCloudIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 16.5H5.25C2.25 16.5 1.5 15.75 1.5 12.75V5.25C1.5 2.25 2.25 1.5 5.25 1.5H6.375C7.5 1.5 7.74752 1.83001 8.17502 2.40001L9.30002 3.90001C9.58502 4.27501 9.75 4.5 10.5 4.5H12.75C15.75 4.5 16.5 5.25 16.5 8.25V9.75"
      stroke={stroke}
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.32 13.7398C8.55754 13.8673 8.55754 16.4173 10.32 16.5448H14.49C14.9925 16.5448 15.4875 16.3573 15.855 16.0198C17.0925 14.9398 16.4325 12.7798 14.805 12.5773C14.22 9.05979 9.13506 10.3948 10.3351 13.7473"
      stroke={stroke}
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
