export const PowerpointFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159964)">
      <path
        d="M7.01974 1H14.8336L21.3491 7.79114V21.3792C21.3491 22.8221 20.1712 24 18.7283 24H7.01974C5.57025 24 4.39893 22.8228 4.39893 21.3792V3.62082C4.39893 2.17056 5.56948 1 7.01974 1Z"
        fill="#E03303"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.6692 7.03106H15.5398V1.73495L20.6692 7.03106Z" fill="white" stroke="#EDEFF4" />
      <path
        d="M12.5756 12.3998C10.7754 12.4883 9.34408 13.986 9.36622 15.8084C9.39573 17.6233 10.8713 19.1062 12.6862 19.1284C14.5159 19.1579 16.0136 17.7266 16.0948 15.9264C16.1021 15.8379 16.0284 15.7641 15.9398 15.7641H12.8928C12.8043 15.7641 12.7305 15.6903 12.7305 15.6092V12.5547C12.7305 12.4662 12.6641 12.3998 12.5756 12.3998ZM13.3871 11.9055V14.9525C13.3871 15.041 13.4535 15.1074 13.542 15.1074H16.5964C16.685 15.1074 16.7514 15.0336 16.7514 14.9451C16.6702 13.2187 15.2832 11.8317 13.5494 11.7505C13.4609 11.7432 13.3871 11.8169 13.3871 11.9055Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159964">
        <rect width="24" height="24" fill="white" transform="translate(0.875 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
