const ShareIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.96 6.16992C18.96 7.55992 20.34 9.76992 20.62 12.3199"
        stroke="#2A186A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48999 12.3707C3.74999 9.8307 5.10999 7.6207 7.08999 6.2207"
        stroke="#2A186A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.18994 20.9395C9.34994 21.5295 10.6699 21.8595 12.0599 21.8595C13.3999 21.8595 14.6599 21.5595 15.7899 21.0095"
        stroke="#2A186A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.06 7.70062C13.5954 7.70062 14.84 6.45598 14.84 4.92062C14.84 3.38527 13.5954 2.14062 12.06 2.14062C10.5247 2.14062 9.28003 3.38527 9.28003 4.92062C9.28003 6.45598 10.5247 7.70062 12.06 7.70062Z"
        stroke="#2A186A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83005 19.9194C6.3654 19.9194 7.61005 18.6747 7.61005 17.1394C7.61005 15.604 6.3654 14.3594 4.83005 14.3594C3.2947 14.3594 2.05005 15.604 2.05005 17.1394C2.05005 18.6747 3.2947 19.9194 4.83005 19.9194Z"
        stroke="#2A186A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.17 19.9194C20.7054 19.9194 21.95 18.6747 21.95 17.1394C21.95 15.604 20.7054 14.3594 19.17 14.3594C17.6347 14.3594 16.39 15.604 16.39 17.1394C16.39 18.6747 17.6347 19.9194 19.17 19.9194Z"
        stroke="#2A186A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
