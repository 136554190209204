import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';

export const HeaderGoPremiumButton = styled(StyledButton)`
  &.ant-btn {
    display: none;
    @media (min-width: ${themeToken.screenMD}px) {
      display: flex;
      background: ${themeToken['branding-secondary-6']};
      padding-inline: ${themeToken.paddingLG}px;
    }
  }
`;
