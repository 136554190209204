import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import { StarIcon } from '@/assets/Icon/star';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

const Badge = styled(Flex)`
  display: flex;
  align-items: center;
  gap: 2px;
  position: absolute;
  top: 0;
  inset-inline-start: 12px !important;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(90deg, #6430da -5.18%, #d81059 113.59%);
  padding: 2px 4px;
  height: 16px;
`;

const PremiumBadge = () => {
  const { t } = useTranslation('common');

  return (
    <Badge>
      <StarIcon width={10} height={10} rotation={-15} />
      <Text
        style={{
          fontSize: `${themeToken.fontSizeXXSM}px`,
          lineHeight: '10px',
          color: '#fff',
          fontWeight: '500',
        }}
      >
        {t('premium')}
      </Text>
    </Badge>
  );
};

export default PremiumBadge;
