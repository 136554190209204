import { Progress } from 'antd';

import styled from 'styled-components';

import themeToken from '@lib/theme/tokens/index';

export const StyledProgress = styled(Progress)`
  &.ant-progress {
    & .ant-progress-inner {
      border-radius: ${themeToken.borderRadiusXS}px;
    }
    & .ant-progress-bg {
      height: 12px !important;
      border-radius: ${themeToken.borderRadiusXS}px;
      background-color: ${themeToken['branding-primary-6']};
    }
  }
`;
