import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { PageWrapper } from '@/components/Shared/Wrapper';
import GraphIcon from '@/components/Icons/GraphIcon';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import CommonTable from '@/components/Shared/CommonTable';
import HeaderWrapper from '@/components/Shared/HeaderWrapper';
import MainContainer from '@/components/Shared/MainContainer';
import type { SortDirection } from '@/types';

import { useGetCaptableHistory } from '../api/CaptableHistory/getCaptableHistory';
import type { CaptableHistoryData } from '../types';
import { captableHistoryColumns } from '../utils/captableHistoryColumns';

const { Title } = Typography;

const CaptableHistory = () => {
  const { t, i18n } = useTranslation('captable', {
    keyPrefix: 'history',
  });
  const { t: translate } = useTranslation('pageTitles', {
    keyPrefix: 'captable',
  });

  useDocumentTitle(translate('transactionsHistory'));

  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const [sortParams, setSortParams] = useState<{
    sort_by: keyof CaptableHistoryData;
    sort_dir: SortDirection;
  }>();
  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const { data: captableHistory, isLoading: isCaptableHistoryLoading } = useGetCaptableHistory({
    companyID: selectedCompany?.cid as number,
    queryParams: {
      ...sortParams,
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });

  const tableColumns = captableHistoryColumns(isCaptableHistoryLoading, t, i18n.language);

  useEffect(() => {
    if (captableHistory?.metadata) {
      const { total } = captableHistory.metadata;
      setPaginationParams((prev) => ({ ...prev, total }));
    }
  }, [captableHistory?.metadata]);

  return (
    <MainContainer
      title={t('transactionsHistory')}
      breadcrumbItems={[
        {
          href: '/captable/overview',
          title: (
            <>
              <GraphIcon width={12} height={20} />
              {t('captable')}
            </>
          ),
        },
        {
          title: t('transactionsHistory'),
        },
      ]}
    >
      <PageWrapper>
        <HeaderWrapper>
          <Title
            level={5}
            style={{
              color: themeToken['branding-primary-6'],
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            {t('transactionsHistory')}
          </Title>
        </HeaderWrapper>
        <CommonTable
          columns={tableColumns}
          dataSource={captableHistory?.data}
          isLoading={isCaptableHistoryLoading}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          setSortParams={setSortParams}
        />
      </PageWrapper>
    </MainContainer>
  );
};

export default CaptableHistory;
