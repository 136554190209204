import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useDocumentTitle = (title: string) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = t(title);
    return () => {
      document.title = t(prevTitle);
    };
  }, [i18n.language]);
};
