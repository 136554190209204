import { ThemeConfig } from 'antd';

import themeToken from './tokens';
import { buttonTheme } from './components/Button';
import { breadcrumbTheme } from './components/Breadcrumb';
import { inputTheme } from './components/Input';
import { selectTheme } from './components/Select';
import { radioTheme } from './components/Radio';
import { datePickerTheme } from './components/DatePicker';
import { uploadTheme } from './components/Upload';
import { layoutTheme } from './components/Layout';
import { avatarTheme } from './components/Avatar';
import { menuTheme } from './components/Menu';
import { tableTheme } from './components/Table';
import { collapseTheme } from './components/Collapse';
import { tabsTheme } from './components/Tabs';
import { treeTheme } from './components/Tree';

const theme = {
  token: {
    ...themeToken,
    fontFamily: 'Cairo, sans-serif',
    screenXXLMin: 1440,
    screenXLMax: 1439,
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.24)',
  },
  components: {
    Collapse: collapseTheme,
    Button: buttonTheme,
    Breadcrumb: breadcrumbTheme,
    Input: inputTheme,
    Select: selectTheme,
    Radio: radioTheme,
    DatePicker: datePickerTheme,
    Upload: uploadTheme,
    Layout: layoutTheme,
    Avatar: avatarTheme,
    Menu: menuTheme,
    Table: tableTheme,
    InputNumber: inputTheme,
    Tabs: tabsTheme,
    Tree: treeTheme,
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
  },
} as ThemeConfig;

export default theme;
