import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { FoldersList } from '../types';

type FoldersResponseData = {
  data: FoldersList[];
  metadata: Metadata;
};

type FoldersParams = {
  companyID: number;
  queryParams: {
    q: string;
  } & PaginationParams;
};

export const searchFolders = async ({ companyID, queryParams }: FoldersParams) => {
  const { data } = await axiosClient.get<FoldersResponseData>(`/companies/${companyID}/data-rooms/search`, {
    params: queryParams,
  });
  return data;
};

type UseSearchFoldersOptions = {
  companyID: number;
  queryParams: FoldersParams['queryParams'];
  config?: Partial<UseQueryOptions<FoldersResponseData>>;
};

export const useSearchFolders = ({ companyID, queryParams, config }: UseSearchFoldersOptions) => {
  return useQuery({
    queryKey: ['search_folders', companyID, queryParams],
    queryFn: () => searchFolders({ companyID, queryParams }),
    ...config,
    enabled: queryParams?.q?.length >= 2,
  });
};
