const PieChartIcon = ({ width = 48, height = 48 }: { width?: number; height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.9999 0C24.4483 0 24.001 0.447271 24.001 0.998962V22.9755C24.001 23.5272 24.4483 23.9744 24.9999 23.9744H46.9765C47.5281 23.9744 47.9754 23.5272 47.9754 22.9755C47.961 10.2924 37.6829 0.0143284 24.9999 0Z"
        fill="#BDB7D1"
      />
      <path
        d="M35.7781 42.3409L22.0029 24.6319V2.99701C22.0029 2.44532 21.5556 1.99805 21.0039 1.99805C9.4363 1.99805 0.0263672 12.305 0.0263672 24.9734C0.0406956 37.6565 10.3188 47.9346 23.0018 47.9489C27.6068 48.3067 32.1602 46.783 35.6223 43.7254C36.0406 43.3823 36.1097 42.7685 35.7781 42.3409Z"
        fill="#6430DA"
      />
      <path
        d="M46.9763 25.9727H26.9977C26.446 25.9727 25.9988 26.4201 25.999 26.9718C25.999 27.2 26.0772 27.4213 26.2206 27.5989L39.2067 43.6757C39.3809 43.8918 39.6372 44.0256 39.914 44.0453H39.9839C40.2367 44.0455 40.4802 43.9498 40.6652 43.7775C45.3187 39.4257 47.9646 33.3428 47.9754 26.9715C47.9753 26.4199 47.5281 25.9727 46.9763 25.9727Z"
        fill="#D81059"
      />
    </svg>
  );
};

export default PieChartIcon;
