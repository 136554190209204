import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthorizedRoute from '@/components/AuthorizedRoute';
import { RouteWrapper } from '../utils/RouteWrapper';
import LimitsChecker from '@/components/LimitsChecker';
import Dashboard from '../views/Dashboard';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const Questionnaire = lazy(() => import('../views/Questionnaire'));
const Financial = lazy(() => import('../views/Financial'));
const Reports = lazy(() => import('../views/Reports'));
export const ValuationRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute feature="valuation" allowedPermissions={['company.valuation']} />}>
          <Route element={<LimitsChecker feature="valuation" limitKeys={['company.valuation']} />}>
            <Route
              path="dashboard"
              element={
                <RouteWrapper routeName="dashboard">
                  <Dashboard />
                </RouteWrapper>
              }
            />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute feature="valuation" allowedPermissions={['company.valuation']} />}>
          <Route element={<LimitsChecker feature="valuation" limitKeys={['company.valuation']} />}>
            <Route
              path="questionnaire"
              element={
                <RouteWrapper routeName="questionnaire">
                  <Questionnaire />
                </RouteWrapper>
              }
            />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute feature="valuation" allowedPermissions={['company.valuation']} />}>
          <Route element={<LimitsChecker feature="valuation" limitKeys={['company.valuation']} />}>
            <Route
              path="financial"
              element={
                <RouteWrapper routeName="financial">
                  <Financial />
                </RouteWrapper>
              }
            />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute feature="valuation" allowedPermissions={['company.valuation']} />}>
          <Route element={<LimitsChecker feature="valuation" limitKeys={['company.valuation']} />}>
            <Route
              path="reports"
              element={
                <RouteWrapper routeName="reports">
                  <Reports />
                </RouteWrapper>
              }
            />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
