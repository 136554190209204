const MicrosoftIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_817_4801)">
        <path d="M9.52358 1.58654L0.952148 1.58654L0.952148 10.158H9.52358V1.58654Z" fill="#F25022" />
        <path d="M9.52358 11.1104H0.952148L0.952148 19.6818H9.52358V11.1104Z" fill="#00A4EF" />
        <path d="M19.048 1.58654L10.4766 1.58654V10.158H19.048V1.58654Z" fill="#7FBA00" />
        <path d="M19.048 11.1104H10.4766V19.6818H19.048V11.1104Z" fill="#FFB900" />
      </g>
      <defs>
        <clipPath id="clip0_817_4801">
          <rect width="20" height="20" fill="white" transform="translate(0 0.634155)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftIcon;
