import { Avatar } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  containerSize: 28,
};

const globalTheme = {};

export const avatarTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    font-size: ${themeToken.fontSizeXSM}px;
    color: ${themeToken.colorPrimary};
    background-color: ${themeToken.white};
    font-weight: 500;
    height: ${themeToken.sizeLG}px;
    width: ${themeToken.sizeLG}px;
    line-height: 22px;
    @media (min-width: ${themeToken.screenMD}px) {
      background-color: ${themeToken.colorPrimary};
      color: ${themeToken.white};
    }
  }
`;
