import axiosClient from '@/lib/axios';
import { useQuery } from '@tanstack/react-query';

import type { ExternalFoldersListResponse, FoldersListResponse } from '../types';

type FolderParams = { company_id: number; page: number; uuid?: string };

export const fetchFolders = async ({ company_id, page, uuid }: FolderParams) => {
  const reqURL = uuid ? `/public/data-rooms/${uuid}` : `/companies/${company_id}/data-rooms/folders`;
  const reqParams = { params: { page } };

  return axiosClient.get<FoldersListResponse & ExternalFoldersListResponse>(reqURL, reqParams).then((res) => {
    if (uuid) {
      const downloadable = res.data?.data?.downloadable;
      return {
        data: [{ ...res.data.data?.item, downloadable }],
        metadata: {
          current: 0,
          total: 0,
          next: 0,
          size: 0,
        },
      };
    }
    return res.data;
  });
};

export const useFetchFolders = ({
  company_id,
  page = 1,
  uuid,
}: {
  company_id: number;
  page: number;
  uuid?: string;
}) => {
  return useQuery({
    queryKey: ['folders', page],
    queryFn: () => fetchFolders({ company_id, page, uuid }),
  });
};
