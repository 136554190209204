export const ExcelFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159984)">
      <path
        d="M7.02414 1.375H14.8378L21.3503 8.16937V21.7494C21.3503 23.1984 20.1738 24.375 18.7247 24.375H7.02414C5.57512 24.375 4.39856 23.1984 4.39856 21.7494V4.00058C4.39856 2.55156 5.57512 1.375 7.02414 1.375Z"
        fill="#00733B"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.6698 7.40755H15.5406V2.11018L20.6698 7.40755Z" fill="white" stroke="#EDEFF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1853 18.4825H12.3718V19.6901H9.1853V18.4825ZM13.3663 12.3125H16.5629V13.51H13.3663V12.3125ZM9.1853 12.3125H12.3718V13.51H9.1853V12.3125ZM13.3663 14.3421H16.5629V15.5497H13.3663V14.3421ZM9.1853 14.3421H12.3718V15.5497H9.1853V14.3421ZM13.3663 16.4529H16.5629V17.6605H13.3663V16.4529ZM9.1853 16.4529H12.3718V17.6605H9.1853V16.4529ZM13.3663 18.4825H16.5629V19.6901H13.3663V18.4825Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159984">
        <rect width="24" height="24" fill="white" transform="translate(0.875 0.875)" />
      </clipPath>
    </defs>
  </svg>
);
