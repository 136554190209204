import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledForSaleModal = styled(Modal)`
  .ant-modal-content {
    padding: 0px;
    border-radius: 8px;
    box-shadow: unset;
  }
`;
