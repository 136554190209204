const CloseIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_275_4291)">
        <path
          d="M5.70735 18.7071C6.09626 19.096 6.73266 19.096 7.12156 18.7071L18.4353 7.3934C18.8242 7.00449 18.8242 6.36809 18.4353 5.97918C18.0464 5.59028 17.41 5.59028 17.0211 5.97918L5.70735 17.2929C5.31844 17.6818 5.31844 18.3182 5.70735 18.7071Z"
          fill="#6430DA"
        />
        <path
          d="M5.29289 7.12129L16.6066 18.435C16.9955 18.8239 17.6319 18.8239 18.0208 18.435C18.4097 18.0461 18.4097 17.4097 18.0208 17.0208L6.70711 5.70708C6.3182 5.31817 5.6818 5.31817 5.29289 5.70708C4.90398 6.09598 4.90398 6.73238 5.29289 7.12129Z"
          fill="#6430DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_275_4291">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
