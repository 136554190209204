import { axiosClient } from '@/lib/axios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { CreateUserFormData } from '../../types';
import { AxiosError } from 'axios';

type CreateNewUserResponse = {
  data: {
    message: 'string';
  };
};
export const createNewUser = async (
  userData: Omit<CreateUserFormData, 'country_code'>,
): Promise<CreateNewUserResponse> => {
  const { data } = await axiosClient.post<CreateNewUserResponse>('/auth/signup', userData);
  return data;
};

type UseCreateUserDTO = {
  config?: Partial<
    UseMutationOptions<CreateNewUserResponse, AxiosError<{ message: string }>, Omit<CreateUserFormData, 'country_code'>>
  >;
  handleAfterSuccess: () => void;
  handleAfterFailure: (code?: number) => void;
};

export const useCreateNewUser = ({ config, handleAfterSuccess, handleAfterFailure }: UseCreateUserDTO) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
      handleAfterFailure(error.response?.status);
    },
    onSuccess: async (res) => {
      toaster.success({
        message: 'Registered Successfully',
        description: res.data.message,
      });
      handleAfterSuccess?.();
    },
    ...config,
    mutationFn: createNewUser,
  });
};
