import { Flex, Spin } from 'antd';

const FallbackSpin = () => {
  return (
    <Flex vertical align="center" justify="center" style={{ height: '100vh' }}>
      <Spin />
    </Flex>
  );
};

export default FallbackSpin;
