export default {
  companyReviews: 'Company Reviews',
  addMainInfo: 'Add Main Info',
  mainInfo: 'Main Info',
  mainInfoDescription:
    'Investors often regard the team as a key factor in their decisions. Industry experience, complementarity and affinity of the members build up a team',
  gicsProgram: 'GICS Program',
  gicsSector: 'GICS Sector',
  companyDescription: 'Company Description',
  companyWebsite: 'Company Website',
  cancel: 'Cancel',
  submit: 'Submit',
  revisit: 'Revisit',
  submitNow: 'Submit Now',
  addNow: 'Add Now',
  notSubmitted: 'Not Submitted',
  optional: 'Optional',
  done: 'Done',
  allIsDone: 'All Is Done',
  completeSteps:
    'Please Complete all steps to submit your report, You have to complete {{count}} steps information first',
  submitReport: 'Submit Report',
  miskReport: 'Misk Report ',
  sendMiskReport: 'Sending Misk Report',
  addFinancialInfo: 'Add Financial Info',
  financialInfo: 'Financial Info',
  financialInfoDescription:
    'Investors often regard the team as a key factor in their decisions. Industry experience, complementarity and affinity of the members build up a team',
  teamInfo: 'Team Information',
  addTeamInfo: 'Add Team Information',
  miskDescription: 'To complete the process please fill the inputs below, Needed Before {{date}} “{{count}} days left"',
  miskReportSentsuccessfully: 'Your {{date}} Misk Report Sent Successfully',
  seeYou: 'See You Next Cycle ',
};
