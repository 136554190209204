import { QueryFunction, useQuery } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';

import type { FolderDataResponse } from '../types';

type FolderParams = {
  company_id: number;
  folder_id: number | undefined;
  isFolderDownloadable?: 0 | 1;
  page: number;
  uuid?: string;
};

export const fetchFolderData = async ({
  company_id,
  folder_id,
  isFolderDownloadable = 0,
  page,
  uuid,
}: FolderParams) => {
  const reqURL = uuid
    ? `/public/data-rooms/${uuid}/folders/${folder_id}`
    : `/companies/${company_id}/data-rooms/folders/${folder_id}`;
  const reqParams = { params: { page } };

  return axiosClient.get<FolderDataResponse>(reqURL, reqParams).then((res) => {
    const downloadable = res.data.downloadable ?? isFolderDownloadable;
    res.data.data = res.data.data.map((folder) => {
      return folder.entity_type === 'File' ? { ...folder, downloadable } : folder;
    });

    return res.data;
  });
};

type QueryFnType = typeof fetchFolderData;

type UseFetchFolderDataOptions = {
  company_id: number;
  folder_id: number;
  isFolderDownloadable?: 0 | 1;
  page: number;
  uuid?: string;
  config?: QueryFunction<QueryFnType>;
};

export const useFetchFolderData = ({
  company_id,
  folder_id,
  isFolderDownloadable,
  page,
  uuid,
  config,
}: UseFetchFolderDataOptions) => {
  return useQuery({
    queryKey: ['folder-data', folder_id, page],
    queryFn: () => fetchFolderData({ company_id, folder_id, isFolderDownloadable, page, uuid }),
    ...config,
    enabled: !!folder_id,
  });
};
