export default {
  fontSizeHeading1: 38,
  fontSizeHeading2: 30,
  fontSizeHeading3: 24,
  fontSizeHeading4: 20,
  fontSizeHeading5: 16,
  fontSizeHeading6: 14,
  fontSizeHeading7: 12,
  fontSizeXSM: 10,
  fontSizeXXSM: 8,
  fontWeightSM: 300,
  fontWeightNormal: 400,
  fontWeightMD: 500,
  fontWeightLG: 600,
  fontWeightXLG: 700,
};
