import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex, Grid, Layout } from 'antd';

import Header from './components/Header/Header';
import Sidebar from './Sidebar';

import { StyledLayout } from '@lib/theme/components/Layout';
import { useEffect, useMemo } from 'react';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

const { Content } = Layout;

const MainLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const isAddNewCompanyCurrentPath = useMemo(() => pathname === '/company/new', [pathname]);

  useEffect(() => {
    const page = pathname;
    if (page === '/') {
      navigate(selectedCompany?.is_owner ? '/company/dashboard' : '/company/investment-overview');
    }
  }, [pathname]);

  return (
    <StyledLayout>
      <Header />
      <Flex
        style={{
          height: '100%',
        }}
        vertical={!screens.lg}
      >
        <Sidebar />

        <Content
          style={{
            background: '#F4F7F9',
            marginTop: screens.xl ? 48 : 96,
            marginInlineStart: screens.xl ? (!isAddNewCompanyCurrentPath ? 295 : 65) : 0,
          }}
        >
          <Flex
            vertical
            style={{
              padding: '10px 0 25px 32px',
              paddingBlockStart: 15,
              paddingBlockEnd: 25,
              paddingInlineStart: screens.lg ? 32 : 16,
              paddingInlineEnd: screens.lg ? 32 : 16,
              position: 'relative',
            }}
          >
            <Outlet />
          </Flex>
        </Content>
      </Flex>
    </StyledLayout>
  );
};

export default MainLayout;
