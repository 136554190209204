import { useTranslation } from 'react-i18next';
import { StyledButton } from '@/lib/theme/components/Button';

import ExportIcon from '@/components/Icons/ExportIcon';
import themeToken from '@lib/theme/tokens/index';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { StyledContainer, StyledStepsBanner } from './StyledStepsBanner';

const StepsBanner = ({
  questionnaires,
  financials,
}: {
  questionnaires: boolean | undefined;
  financials: boolean | undefined;
}) => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <StyledStepsBanner
        size="small"
        current={1}
        style={{ width: '691px' }}
        items={[
          {
            title: t('initialCompanyInfo'),
          },
          {
            title: t('questionnaire'),
            subTitle: !questionnaires && (
              <Button
                type="link"
                style={{ display: 'contents', color: themeToken.colorError }}
                onClick={() => navigate('/valuation/questionnaire')}
              >
                {t('start_now')}
              </Button>
            ),
            status: !questionnaires ? 'process' : undefined,
          },
          {
            title: t('financialInfo'),
            subTitle: !financials && (
              <Button
                type="link"
                style={{ display: 'contents', color: themeToken.colorError }}
                onClick={() => navigate('/valuation/financials')}
              >
                {t('start_now')}
              </Button>
            ),
            status: !financials ? 'process' : undefined,
          },
        ]}
      />

      <StyledButton
        icon={<ExportIcon />}
        style={{ borderRadius: themeToken.paddingXL }}
        onClick={() => navigate('/valuation/reports')}
      >
        {t('generateReport')}
      </StyledButton>
    </StyledContainer>
  );
};

export default StepsBanner;
