import { Navigate, useRoutes } from 'react-router-dom';
import { PublicRoutes } from './public';
import { ProtectedRoutes } from './protected';
import { getCookie } from '@/utils/storage';
import PublicDataRoomWrapper from '@/HOCs/PublicDataRoomWrapper';
import DataRoomsMain from '@/features/dataRooms/views/DataRoomsMain';

const AppRoutes = () => {
  const isAuthenticated = getCookie('access_token') || false;
  const routes = isAuthenticated ? ProtectedRoutes : PublicRoutes;

  const element = useRoutes([
    {
      path: '/shared/files/:uuid',
      element: (
        <PublicDataRoomWrapper>
          <DataRoomsMain />
        </PublicDataRoomWrapper>
      ),
    },
    ...routes,
    // Add a catch-all route for unauthorized access
    {
      path: '*',
      element: <Navigate to={isAuthenticated ? '/' : '/auth/signin'} replace />,
    },
  ]);

  return <>{element}</>;
};

export default AppRoutes;
