export default {
  dashboard: 'Dashboard | Rasmal',
  addNewCompany: 'Add New Company | Rasmal',
  investmentOverview: 'Personal Holdings | Rasmal',
  consultationServices: 'Consultation Services | Rasmal',
  consultationDetails: 'Consultation Services - Consultation Details | Rasmal',
  support: 'support | Rasmal',
  dataRoom: 'Data Room | Rasmal',
  analytics: 'Data Room - Analytics | Rasmal',
  sharedLogs: 'Data Room - Shared Logs | Rasmal',
  companyReviews: {
    status: 'Company Reviews - Status | Rasmal',
    fiancialInfo: 'Company Reviews - Add Financial Info | Rasmal',
    teamInfo: 'Company Reviews - Add Team Information | Rasmal',
    mainInfo: 'Company Reviews - Add Main Info | Rasmal',
    final: 'Company Review - Submission | Rasmal',
  },
  profile: 'Profile Management | Rasmal',
  companyListing: 'Company Listing - Company Info | Rasmal',
  thankyou: 'Company Listing - Thank-you | Rasmal',
  captable: {
    captableOverview: 'Captable - Overview | Rasmal',
    transactionsHistory: 'Transactions - History | Rasmal',
    equities: 'Captable - Equities | Rasmal',
    addNewEquity: 'Captable - Equities - Add New Equity | Rasmal',
    equityDetails: 'Captable - Equities -  Equity Details | Rasmal',
    issueShares: 'Captable - Equities - Issue Shares | Rasmal',
    options: 'Captable - Options | Rasmal',
    addNewOption: 'Captable - Options - Add New Pool | Rasmal',
    grantOption: 'Captable - Options - Grant option | Rasmal',
    optionDetails: 'Captable - Options - Options Details | Rasmal',
    convertibleInstruments: 'Captable - Convertible Instruments | Rasmal',
    addNewConvertibleInstruments: 'Captable - Convertible Instruments - Add New Convertible Instruments | Rasmal',
    vestingSchedule: 'Captable - Vesting Schedule | Rasmal',
    addNewVestingSchedule: 'Captable - Vesting Schedule  - Add New Vesting Schedule | Rasmal',
    editVestingSchedule: 'Captable - Vesting Schedule  - Edit Vesting Schedule | Rasmal',
  },
  valuation: {
    dashboard: 'Dashboard - Valuation | Rasmal',
    questionnaire: 'Questionnaire - Valuation | Rasmal',
    financial: 'Financial - Valuation | Rasmal',
    reports: 'Reports - Valuation | Rasmal',
  },
  scenarioModeling: {
    investmentRound: 'InvestmentRound - ScenarioModeling  | Rasmal',
    exitModeling: 'ExitModeling - ScenarioModeling | Rasmal',
  },
  shareholders: {
    shareholdersList: 'ShareholdersList - Shareholders | Rasmal',
    accessLevels: 'Access Levels - Shareholders | Rasmal',
    activityLogs: 'Activity Logs - Shareholders | Rasmal',
  },
  login: 'Login | Rasmal',
  signup: 'Signup | Rasmal',
  confirmAccount: 'Account Verification  | Rasmal',
  documentGenerator: {
    contracts: 'Contracts | Rasmal',
    addDocumentInfo: 'Contracts - Add Contract Information | Rasmal',
    documentHistory: 'Contracts - Contracts History | Rasmal',
    reviewDocument: 'Contracts - Review Your Contract Carefully | Rasmal',
  },
};
