import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

import { axiosClient } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import type { CopyFilePayload } from '../types';

type FilePayload = {
  companyID: number;
  body: CopyFilePayload;
};

export const copyFile = async ({ companyID, body }: FilePayload): Promise<{ status: string }> => {
  const { data } = await axiosClient.post<{ status: string }>(`/companies/${companyID}/data-rooms/files/copy`, body);
  return data;
};

type UseCopyFileOptions = {
  config?: Partial<UseMutationOptions<{ status: string }, AxiosError<{ message: string }>, FilePayload>>;
  postSuccess?: () => void;
};

export const useCopyFile = ({ config, postSuccess }: UseCopyFileOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message ?? error.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['folders'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['folder-data'],
      });
      toaster.success({
        message: 'Copied Successfully',
        description: 'File Copied Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: copyFile,
  });
};
