import { Route, Routes } from 'react-router-dom';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import RouteWrapper from '../utils/RouteWrapper';
import { Suspense, lazy } from 'react';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const CompanyInfo = lazy(() => import('../views/CompanyInfo'));
const ThankYou = lazy(() => import('../views/ThankYou'));

export const CompanyListingRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute feature="company.forsale" allowedPermissions={['company.forsale']} />}>
          <Route element={<RouteWrapper />}>
            <Route path="company-info" element={<CompanyInfo />} />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute feature="company.forsale" allowedPermissions={['company.forsale']} />}>
          <Route element={<RouteWrapper />}>
            <Route path="thank-you" element={<ThankYou />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
