export default {
  black: '#000000',
  white: '#FFFFFF',

  primary_50: '#EEF2FF',
  primary_100: '#E0E7FF',
  primary_200: '#C7D2FE',
  primary_300: '#A5B4FC',
  primary_400: '#818CF8',
  primary_500: '#6366F1',
  primary_600: '#4F46E5',
  primary_700: '#4338CA',
  primary_800: '#3730A3',
  primary_900: '#312E81',
  neutral_50: '#F8FAFC',
  neutral_100: '#F3F4F6',
  neutral_200: '#E5E7EB',
  neutral_300: '#CBD5E1',
  neutral_400: '#94A3B8',
  neutral_500: '#6B7280',
  neutral_600: '#4B5563',
  neutral_700: '#374151',
  neutral_800: '#1F2937',
  neutral_900: '#111827',
  colorPrimary: '#2A186A',
  colorPrimaryHover: '#554688',
  colorError: '#E0407A',
  foundationNeutral: '#F6F8F9',
  'branding-primary-1': '#F4F3F7',
  'branding-primary-2': '#BDB7D1',
  'branding-primary-3': '#9D95BA',
  'branding-primary-4': '#70649B',
  'branding-primary-6': '#2A186A',
  'branding-primary-7': '#221355',
  'branding-primary-8': '#190E40',
  'branding-primary-10': '#080515',
  'branding-natural-0': '#FFFFFF',
  'branding-natural-1': '#EDEFF4',
  'branding-natural-3': '#CAD0DF',
  'branding-natural-4': '#B9C1D4',
  'branding-natural-5': '#F6F7FA',
  'branding-natural-6': '#868EA1',
  'branding-natural-7': '#646A79',
  'branding-natural-8': '#434750',
  'branding-natural-9': '#212328',
  'branding-natural-10': '#000',
  'branding-secondary-1': '#F0EAFB',
  'branding-secondary-2': '#CFBFF4',
  'branding-secondary-4': '#9774E6',
  'branding-secondary-6': '#6430DA',
  'branding-secondary-8': '#391085',
  'branding-red-primary-6': '#D81059',
  'table-color-fill-alter': '#00000005',
  'table-color-text': '#070708',
  'table-color-text-disabled': '#00000040',
  'table-color-split': '#0000000F',
  'neutral-color-white-black-400': '#514C63',
  'secondary-chrysler-blue-600': '#6430DA',
  'neutral-color-white-black-200': '#877EA4',
  colorText: '#070708',
  colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
  colorLink: '#2A186A',
  colorSuccessText: '#52C41A',
  colorWarningText: '#FA9F42',
  breadcrumbLastItemColor: '#9D95BA',
  colorBgTextHover: '#F0EAFB',
  optionSelectedBgColor: '#CDC9E0',
  menuItemSelectedBgColor: '#dce0e9',
  menuSubmenuBgColor: '#f6f7fa',
  menuSubmenuBorderColor: '#e5ebf1',
  tableHoverBg: '#f0f0f0',
  suggestedNameColor: '#D81059',
  tabsNavBg: '#f4f3f7',
  disabledColor: '#A7B1C9',
  boxShadow:
    '0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)',
};
