import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Grid, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { CompanyValuationDashboardData, DcfMethodData } from '@/features/valuation/types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { StyledSelect } from '@/lib/theme/components/Select';
import { StyledFlex } from '../StyledFlex';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const DCFMethods = ({
  data,
  dataDetails,
}: {
  data: CompanyValuationDashboardData['dcf_methods_chart'] | undefined;
  dataDetails: CompanyValuationDashboardData['dcf_methods_rightcol'] | undefined;
}) => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const screens = useBreakpoint();
  const [selectedOption, setSelectedOption] =
    useState<keyof CompanyValuationDashboardData['dcf_methods_chart']>('dcfmult');

  const chartData = Object.values(data?.[selectedOption] || {}).map((item: DcfMethodData) => ({
    name: item.year,
    Failure: item.failure * 100,
    Survival: item.survival * 100,
  }));

  const info = dataDetails?.[selectedOption];
  return (
    <Card
      bodyStyle={{
        padding: '0px',
        height: '100%',
        gap: '8px',
        flexDirection: 'column',
        display: 'flex',
      }}
      style={{
        width: '100%',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Flex align="center" justify="space-between">
        <Text
          style={{
            color: themeToken.colorPrimary,
            fontWeight: 600,
          }}
        >
          {selectedOption === 'vc_method' ? t('ventureCapitalMethod') : t('dcfMethods')}
        </Text>
        <StyledSelect
          options={[
            {
              label: t('multiples'),
              value: 'dcfmult',
            },
            {
              label: t('longTermGrowth'),
              value: 'dcfltg',
            },
            {
              label: t('ventureCapitalMethod'),
              value: 'vc_method',
            },
          ]}
          filterOption={false}
          value={selectedOption}
          onChange={setSelectedOption}
          style={{
            height: themeToken.controlHeight,
            width: '114px',
            borderColor: themeToken.colorPrimary,
          }}
        />
      </Flex>
      <StyledFlex>
        <ResponsiveContainer width={screens.lg ? '80%' : '100%'} height={222}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: screens.md ? 30 : 0,
              left: screens.md ? 20 : -20,
              bottom: 5,
            }}
            barSize={screens.md ? 50 : 25}
          >
            <CartesianGrid stroke={themeToken['branding-natural-3']} />
            <XAxis dataKey="name" tickLine={false} axisLine={false} interval={0} fontSize={12} />
            <YAxis tickLine={false} axisLine={false} tickFormatter={(value) => `${value}%`} />

            <Legend
              wrapperStyle={{
                insetInlineStart: '45%',
                width: 'max-content',
              }}
              formatter={(value: string) => t(value.toLowerCase())}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              contentStyle={{
                borderRadius: themeToken.borderRadius,
                borderColor: '#cccccc',
              }}
              formatter={(value: number, name: string) => [`${value.toFixed(2)}%`, t(name.toLowerCase())]}
            />
            <Bar dataKey="Survival" stackId="a" fill=" #6040D4" />
            <Bar dataKey="Failure" stackId="a" fill="#A7B1C9" />
          </BarChart>
        </ResponsiveContainer>
        <Flex
          vertical
          flex={1}
          wrap="wrap"
          style={{
            minWidth: '140px',
          }}
        >
          <Text
            style={{
              color: themeToken['branding-natural-6'],
              whiteSpace: 'nowrap',
            }}
          >
            {`${t('valuation')}:`}
          </Text>
          <Title level={4} style={{ color: themeToken.colorPrimary, marginBottom: themeToken.marginSM }}>
            {info?.pre_money}
          </Title>

          <Text
            style={{
              color: themeToken['branding-natural-6'],
              whiteSpace: 'nowrap',
            }}
          >
            {`${t('assumptions')}:`}
          </Text>
          <Flex
            align="center"
            gap={5}
            style={{
              marginBottom: themeToken.marginXS,
            }}
            wrap="wrap"
          >
            <Text style={{ color: themeToken.colorPrimary }}>{`${t('exitValue')}:`}</Text>
            <Text style={{ color: themeToken.colorPrimary, fontSize: themeToken.fontSizeSM, fontWeight: 600 }}>
              {info?.assumptions.exit_val?.val}
            </Text>
          </Flex>
          {info?.assumptions.MRP?.val && (
            <Flex
              align="center"
              gap={5}
              style={{
                marginBottom: themeToken.marginXS,
              }}
              wrap="wrap"
            >
              <Text style={{ color: themeToken.colorPrimary }}>{`${t('mrp')}:`}</Text>
              <Text style={{ color: themeToken.colorPrimary, fontSize: themeToken.fontSizeSM, fontWeight: 600 }}>
                {info?.assumptions.MRP?.val}
              </Text>
            </Flex>
          )}

          {info?.assumptions.WACC?.val && (
            <Flex
              align="center"
              gap={5}
              style={{
                marginBottom: themeToken.marginXS,
              }}
              wrap="wrap"
            >
              <Text style={{ color: themeToken.colorPrimary }}>{`${t('wacc')}:`}</Text>
              <Text style={{ color: themeToken.colorPrimary, fontSize: themeToken.fontSizeSM, fontWeight: 600 }}>
                {info?.assumptions.WACC?.val}
              </Text>
            </Flex>
          )}
          {info?.assumptions.ebitda?.val && (
            <Flex
              align="center"
              gap={5}
              style={{
                marginBottom: themeToken.marginXS,
              }}
              wrap="wrap"
            >
              <Text style={{ color: themeToken.colorPrimary }}>{`${t('lastEBITDA')}:`}</Text>
              <Text style={{ color: themeToken.colorPrimary, fontSize: themeToken.fontSizeSM, fontWeight: 600 }}>
                {info?.assumptions.ebitda?.val}
              </Text>
            </Flex>
          )}
          {info?.assumptions.ebitdamult?.val && (
            <Flex align="center" gap={5} wrap="wrap">
              <Text style={{ color: themeToken.colorPrimary }}>{`${t('ebitdaMultiple')}:`}</Text>
              <Text style={{ color: themeToken.colorPrimary, fontSize: themeToken.fontSizeSM, fontWeight: 600 }}>
                {info?.assumptions.ebitdamult?.val}
              </Text>
            </Flex>
          )}
        </Flex>
      </StyledFlex>
    </Card>
  );
};

export default DCFMethods;
