import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import DocumentIcon from '@/components/Icons/DocumentIcon';
import ActivityIcon from '@/components/Icons/ActivityIcon';
import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const DataroomHeaderButtons = () => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');

  const { isPremiumPlan } = useSelectedCompanyStore((state) => state);
  const { permissions } = usePermissionsStore((state) => state);

  const navigate = useNavigate();

  return (
    <>
      <StyledButton
        style={{ padding: `${themeToken.paddingXXS}px ${themeToken.paddingLG}px` }}
        size="small"
        ghost
        type="primary"
        onClick={() => {
          sendMixpanelEvent('shared-logs-btn-clicked');
          navigate('/data-rooms/shared-logs');
        }}
        icon={<DocumentIcon />}
        disabled={!permissions.includes('company.dataroom.shared-logs')}
      >
        {t('sharedLogs')}
      </StyledButton>
      <StyledButton
        style={{ padding: `${themeToken.paddingXXS}px ${themeToken.paddingLG}px` }}
        size="small"
        ghost
        type="primary"
        onClick={() => {
          sendMixpanelEvent('analytics-btn-clicked');
          navigate('/data-rooms/analytics');
        }}
        icon={<ActivityIcon />}
        disabled={!permissions.includes('company.dataroom.analytics') || !isPremiumPlan}
      >
        {t('analytics')}
      </StyledButton>
    </>
  );
};

export default DataroomHeaderButtons;
