import { useMutation } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';

import type { CreateSharedLogPayload } from '../types';
import type { NotificationInstance } from 'antd/es/notification/interface';
import { AxiosError } from 'axios';

export const createSharedLog = ({ company_id, payload }: { company_id: number; payload: CreateSharedLogPayload }) => {
  return axiosClient
    .post<{
      status: string;
    }>(`/companies/${company_id}/data-rooms/shared-logs`, { ...payload })
    .then((res) => res.status);
};

export const useCreateSharedLog = ({
  api,
  successCallback,
}: {
  api: NotificationInstance;
  successCallback: () => void;
}) => {
  return useMutation({
    onError: (error: AxiosError<{ message: string }>) => {
      api.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    onSuccess: () => {
      api.success({
        message: 'Shared Successfully',
        description: 'Your File Shared Successfully',
      });
      successCallback();
    },
    mutationFn: createSharedLog,
  });
};
