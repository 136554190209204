import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import themeToken from '@lib/theme/tokens/index';
import { Flex, Typography } from 'antd';
import GeneratingRepoerIcon from '@/components/Icons/GeneratingRepoerIcon';
import { StyledButton } from '@/lib/theme/components/Button';
import { useNavigate } from 'react-router-dom';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const StyledGeneratingReportBanner = styled(Flex)`
  background-image: linear-gradient(to left, #2a186a, #6430da);
  padding: ${themeToken.paddingMD}px;
  border-radius: ${themeToken.borderRadiusLG}px;
  margin-bottom: ${themeToken.margin}px;
`;

const { Text } = Typography;

const GeneratingReportBanner = () => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const { sendMixpanelEvent } = useTrackEvent({ page: 'valuation-dashboard' });
  const navigate = useNavigate();

  return (
    <StyledGeneratingReportBanner gap={16}>
      <GeneratingRepoerIcon />
      <Flex justify="space-between" align="center" style={{ width: '100%' }}>
        <Flex vertical justify="center">
          <Text
            style={{
              color: themeToken['branding-natural-0'],
              fontWeight: 600,
              marginBottom: '2px',
            }}
          >
            {t('startNowPrintingReports')}
          </Text>
          <Text
            style={{
              color: themeToken['branding-primary-3'],
              fontWeight: 400,
              fontSize: themeToken.fontSizeHeading5,
            }}
          >
            {t('downloadProfessionalReports')}
          </Text>
        </Flex>
        <StyledButton
          size="large"
          style={{ color: themeToken['branding-primary-6'] }}
          onClick={() => {
            sendMixpanelEvent('start-now-btn-clicked');
            navigate('/valuation/reports');
          }}
        >
          {t('startNow')}
        </StyledButton>
      </Flex>
    </StyledGeneratingReportBanner>
  );
};

export default GeneratingReportBanner;
