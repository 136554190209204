import { Flex, Typography, Grid } from 'antd';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import PentugramBanner from '../../../assets/PentugramBanner.png';
import { StyledButton } from '@/lib/theme/components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const StyledWrapper = styled(Flex)`
  background: ${themeToken.white};
  position: relative;
`;

const StyledBanner = styled(Flex)`
  height: 226px;
  position: relative;
  bottom: 10px;
  background-image: url(${PentugramBanner});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 16px;
`;

const Banner = () => {
  const { t, i18n } = useTranslation('companyListing');
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { sendMixpanelEvent } = useTrackEvent({ isGeneralEvent: true });

  return (
    <StyledWrapper vertical>
      <StyledBanner
        vertical
        gap={8}
        style={{
          width: screens.xs ? '90%' : '193px',
          left: i18n.language === 'ar' ? '0px' : '16px',
          right: i18n.language === 'ar' ? '16px' : '0px',
        }}
      >
        <Title style={{ fontSize: themeToken.fontSizeLG, color: themeToken.white, fontWeight: 600 }}>
          {t('bannerTitle')}
        </Title>
        <StyledButton
          type="primary"
          size="small"
          style={{ background: '#6430DA', alignSelf: 'stretch' }}
          onClick={() => {
            sendMixpanelEvent('company-listing-banner-btn-clicked');
            navigate('/company-listing/company-info');
          }}
        >
          {t('bannerStartNow')}
        </StyledButton>
      </StyledBanner>
    </StyledWrapper>
  );
};

export default Banner;
