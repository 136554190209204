import styled from 'styled-components';

import themeToken from '@lib/theme/tokens/index';

export const StyledActionIconContainer = styled('div')<{ isWhiteBgColor: boolean; isVertical: boolean }>`
  background: ${(props) => (props.isWhiteBgColor ? '#fff' : themeToken['branding-natural-5'])};
  padding: 0 ${themeToken.paddingXS};
  width: ${(props) => (props.isVertical ? '40px' : 'fit-content')};
  height: ${(props) => (props.isWhiteBgColor ? 'fit-content' : '40px')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
