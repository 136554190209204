import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledFlex = styled(Flex)`
  @media (max-width: 768px) {
    &.ant-flex {
      flex-direction: column !important;
    }
  }
`;
