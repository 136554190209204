import { Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

interface Props {
  title?: string;
  subTitle?: string;
}

function CommonTableTitle({ title, subTitle }: Props) {
  const titleStyle = {
    color: themeToken['branding-primary-6'],
    fontSize: themeToken.fontSizeLG,
    marginBottom: themeToken.marginXXS,
  };
  const subTitleStyle = {
    color: themeToken['branding-primary-4'],
    fontSize: themeToken.fontSize,
  };

  return (
    <Flex
      vertical
      gap={4}
      style={{
        marginTop: 0,
      }}
    >
      <Text style={titleStyle} strong>
        {title}
      </Text>

      <Text style={subTitleStyle}>{subTitle}</Text>
    </Flex>
  );
}

export default CommonTableTitle;
