import styled from 'styled-components';
import dots from '../../../assets/auth/bg-dots.webp';
import leftFrame from '../../../assets/auth/left-bg-frame.webp';
import rightFrame from '../../../assets/auth/right-bg-frame.webp';

import themeToken from '@lib/theme/tokens/index';
import logo from '../../../assets/main-english-logo.svg';
import { Divider, Flex, Grid } from 'antd';
import { StyledInput } from '@lib/theme/components/Input';
import MicrosoftIcon from '../../../components/Icons/MicrosoftIcon';
import GoogleIcon from '../../../components/Icons/GoogleIcon';
import {
  StyledContainer,
  StyledForm,
  StyledFormItem,
  StyledInputNumber,
  StyledLeftDotsImage,
  StyledLink,
  StyledLogoImage,
  StyledRightDotsImage,
  StyledSocialButton,
  StyledSubmitButton,
  StyledText,
  StyledTitle,
} from '../components';
import { PhoneCodesSelect } from '../../../components/Shared/PhoneCodesSelect';
import Carousel from '../components/Shared/Carousel';
import { useState } from 'react';
import TermsAndConditionsModal from '../components/SignUp/TermsAndConditionsModal';
import { StyledButton } from '@lib/theme/components/Button';
import { useTranslation } from 'react-i18next';
import { handleChangeLanguage } from '../../../utils/handleChangeLanguage';
import { handleOpenSocialLoginPopup } from '../utils/handleOpenSocialLoginPopup';
import { CreateUserFormData } from '../types';
import { useCreateNewUser } from '../api/signup/registerNewUser';
import { useNavigate } from 'react-router-dom';
import { getI18n } from 'react-i18next';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin: ${themeToken.marginLG}px 0;

    &::before,
    &::after {
      border-color: ${themeToken['branding-natural-3']};
    }
    .ant-divider-inner-text {
      color: ${themeToken['branding-natural-8']};
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

const StyledWrapper = styled.div`
  height: auto;
  display: flex;
  width: 100%;
`;

const SignupForm = styled(StyledForm)`
  .ant-form-item-label {
    text-align: start;
    label {
      font-weight: 600;
    }
  }
`;

const SignUp = () => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'sign-up' });
  const navigate = useNavigate();

  const [currentCountryCode, setCurrentCountryCode] = useState('');
  const [registerFormValues, setRegisterFormValues] = useState<CreateUserFormData>();
  const { useBreakpoint } = Grid;
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
  const screens = useBreakpoint();
  const { t } = useTranslation(['signup', 'common']);
  const { t: translate } = useTranslation('pageTitles');

  useDocumentTitle(translate('signup'));

  const { mutate: registerNewUser, isPending: isRegisterBtnLoading } = useCreateNewUser({
    handleAfterSuccess: () => {
      sendMixpanelEvent('signup', {}, { status: 'success', req_path: '/auth/signup' });
      navigate(
        `/auth/confirm-account?method=signup&phone=${currentCountryCode}${registerFormValues?.phone_number}&email=${encodeURIComponent(
          registerFormValues?.email as string,
        )}&otp_receiver=email`,
      );
    },
    handleAfterFailure: (code) => {
      sendMixpanelEvent('signup', { statusCode: code }, { status: 'fail', req_path: '/auth/signup' });
    },
  });

  const onSubmitSignupForm = (formValues: CreateUserFormData) => {
    sendMixpanelEvent('confirm-btn-clicked');
    setRegisterFormValues(formValues);
    registerNewUser({
      ...formValues,
      phone_number: `${currentCountryCode}${formValues.phone_number}`,
      locale: getI18n().language || 'en',
    });
  };

  return (
    <StyledContainer>
      <TermsAndConditionsModal
        isTermsAndConditionsModalOpen={isTermsAndConditionsModalOpen}
        setIsTermsAndConditionsModalOpen={setIsTermsAndConditionsModalOpen}
      />
      <StyledLeftDotsImage
        src={screens.md ? leftFrame : dots}
        width={screens.md ? 192 : 55}
        height={screens.md ? 80 : 180}
      />
      <StyledWrapper
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '36px',
          zIndex: 1,
        }}
      >
        <StyledWrapper>
          {screens.md && <Carousel />}
          <SignupForm layout="vertical" onFinish={onSubmitSignupForm}>
            <StyledLogoImage width={86} height={30} src={logo} alt="logo" />

            <StyledText color={themeToken['branding-natural-6']}>{t('hello')} !</StyledText>
            <StyledTitle level={screens.md ? 3 : 4} color={themeToken['branding-natural-9']} rootClassName="title">
              {t('createNewAccount')}
            </StyledTitle>
            <StyledFormItem<CreateUserFormData>
              label="Full name"
              name="user_name"
              rules={[{ required: true, message: 'Please add your full name' }]}
              className="form-item"
            >
              <StyledInput size="large" placeholder={t('common:fullName')} />
            </StyledFormItem>
            <StyledFormItem<CreateUserFormData>
              label="Phone number"
              name="phone_number"
              rules={[{ required: true, message: 'Please add your phone number' }]}
              rootClassName="form-item"
            >
              <StyledInputNumber
                addonBefore={<PhoneCodesSelect setCurrentCountryCode={setCurrentCountryCode} />}
                placeholder={t('common:phoneNumber')}
                size="large"
              />
            </StyledFormItem>
            <StyledFormItem<CreateUserFormData>
              label="Email address"
              name="email"
              rules={[{ required: true, message: 'Please add your email' }]}
              className="form-item"
              style={{
                marginBottom: '16px',
              }}
            >
              <StyledInput size="large" placeholder={t('common:emailAddress')} type="email" />
            </StyledFormItem>
            <Flex wrap="wrap">
              <StyledText color={themeToken['neutral-color-white-black-400']}>
                {t('byClickingOnTheButtonBelow')}{' '}
                <StyledLink
                  to="#"
                  onClick={() => {
                    setIsTermsAndConditionsModalOpen(true);
                  }}
                >
                  {t('common:termsAndConditions')}.
                </StyledLink>
              </StyledText>
            </Flex>
            <StyledSubmitButton
              type="primary"
              size="large"
              htmlType="submit"
              disabled={isRegisterBtnLoading}
              loading={isRegisterBtnLoading}
            >
              Confirm
            </StyledSubmitButton>
            <Flex wrap="wrap">
              <StyledText color={themeToken['neutral-color-white-black-400']}>{t('youHaveAnAccount')}&nbsp;</StyledText>
              <StyledLink to="/auth/signin">{t('loginNow')}</StyledLink>
            </Flex>
            <Flex
              vertical
              style={{
                paddingInline: screens.md ? '0' : '25px',
                width: '100%',
              }}
            >
              <StyledDivider>{t('common:auth:orContinueUsing')}</StyledDivider>

              <Flex
                vertical
                gap={12}
                style={{
                  width: '100%',
                }}
              >
                <StyledSocialButton
                  onClick={() => {
                    sendMixpanelEvent('microsoft-btn-clicked');
                    handleOpenSocialLoginPopup('microsoft');
                  }}
                >
                  <MicrosoftIcon /> {t('common:microsoftAccount')}
                </StyledSocialButton>
                <StyledSocialButton
                  onClick={() => {
                    sendMixpanelEvent('google-btn-clicked');
                    handleOpenSocialLoginPopup('google');
                  }}
                >
                  <GoogleIcon />
                  {t('common:googleAccount')}
                </StyledSocialButton>
              </Flex>
            </Flex>
          </SignupForm>
        </StyledWrapper>
        <Flex>
          <StyledButton
            size="small"
            type="link"
            style={{
              color: themeToken.colorPrimary,
            }}
            onClick={() => {
              handleChangeLanguage('ar');
            }}
          >
            العربية
          </StyledButton>
          <StyledButton
            size="small"
            type="link"
            style={{
              color: themeToken.colorPrimary,
            }}
            onClick={() => {
              handleChangeLanguage('en');
            }}
          >
            English(US)
          </StyledButton>
        </Flex>
      </StyledWrapper>
      <StyledRightDotsImage
        src={screens.md ? rightFrame : dots}
        width={screens.md ? 'auto' : 55}
        height={screens.md ? 'auto' : 180}
      />
    </StyledContainer>
  );
};

export default SignUp;
