import FallbackSpin from '@/components/Shared/FallbackSpin';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Consultation = lazy(() => import('../views/Consultation'));
const ConsultationDetails = lazy(() => import('../views/ConsultationDetails'));

export const ConsultationsRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route path="consultation" element={<Consultation />} />
        <Route path="consultation/:id" element={<ConsultationDetails />} />
      </Routes>
    </Suspense>
  );
};
