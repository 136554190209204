import { Flex, Typography, Skeleton } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

const DetailsItem = ({ label, value, isLoading }: { label: string; value?: string | number; isLoading: boolean }) => {
  return (
    <Flex
      vertical
      style={{
        flexGrow: 1,
      }}
    >
      <Text
        style={{
          color: themeToken['branding-natural-6'],
          fontSize: themeToken.fontSize,
          marginBottom: themeToken.marginXXS,
        }}
      >
        {label}
      </Text>
      {isLoading ? (
        <Skeleton.Button
          active
          size="small"
          shape="round"
          block
          style={{ height: 16, marginTop: themeToken.marginXXS }}
        />
      ) : (
        <Text
          style={{
            fontSize: themeToken.fontSizeLG,
            color: themeToken['branding-primary-6'],
            fontWeight: 600,
          }}
        >
          {value ?? '-'}
        </Text>
      )}
    </Flex>
  );
};

export default DetailsItem;
