import { Modal } from 'antd';
import styled from 'styled-components';

import themeToken from '@lib/theme/tokens/index';

export const StyledSharednModal = styled(Modal)`
  &.ant-modal {
    width: 100%;
  }
  .ant-modal-content {
    max-width: 443px;
    margin: 0 auto;
    padding: ${themeToken.paddingXL}px ${themeToken.paddingLG}px;

    .ant-modal-footer {
      display: flex;
      justify-content: ${(props: { className?: string }) => (props.className === 'ar' ? 'flex-start' : 'flex-end')};
      margin-top: ${themeToken.marginLG}px;
    }
  }
`;
