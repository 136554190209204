import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import CopyIcon from '@/components/Icons/CopyIcon';

import { StyledActionIconContainer } from './StyledActionIconContainer';

const { Text } = Typography;

type Props = {
  disabled: boolean;
  isWhiteBgColor?: boolean;
  hasLabel?: boolean;
  isVertical?: boolean;
  setSelectedFile?: () => void;
  setIsCopyModalOpen: () => void;
  closeDropdown?: () => void;
};

const CopyFile = ({
  disabled,
  closeDropdown,
  setIsCopyModalOpen,
  setSelectedFile,
  hasLabel = true,
  isWhiteBgColor = false,
  isVertical = true,
}: Props) => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');

  return (
    <Flex
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) return;
        sendMixpanelEvent('copy-file-btn-clicked');
        setSelectedFile?.();
        setIsCopyModalOpen();
        closeDropdown?.();
      }}
      vertical={isVertical}
      align="center"
      gap={isVertical ? 2 : 8}
      style={{ cursor: !disabled ? 'pointer' : 'not-allowed' }}
    >
      <StyledActionIconContainer isWhiteBgColor={isWhiteBgColor} isVertical={isVertical}>
        <CopyIcon width={18} height={18} fill="#2A186A" />
      </StyledActionIconContainer>
      {hasLabel && (
        <Text
          style={{
            color: themeToken['branding-primary-3'],
            fontSize: themeToken.fontSizeSM,
            fontWeight: 400,
          }}
        >
          {t('copyTo')}
        </Text>
      )}
    </Flex>
  );
};

export default CopyFile;
