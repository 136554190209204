import styled from 'styled-components';
import dots from '../../../assets/auth/bg-dots.webp';
import leftFrame from '../../../assets/auth/left-bg-frame.webp';
import rightFrame from '../../../assets/auth/right-bg-frame.webp';

import themeToken from '@lib/theme/tokens/index';
import logo from '../../../assets/main-english-logo.svg';
import { App, Grid } from 'antd';
import {
  StyledContainer,
  StyledForm,
  StyledLeftDotsImage,
  StyledRightDotsImage,
  StyledText,
  StyledTitle,
} from '../components';
import { useSocialAuth } from '../api/socialAuth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserDataStore } from '@/stores/UserData';
import { clearCookie, setCookie } from '@/utils/storage';
import { useGetCompanies } from '@/features/company/api/getCompanies';
import { useGetCompany } from '@/features/company/api/getCompany';
import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { queryClient } from '@/lib/react-query';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { Company } from '@/features/company/types';

const StyledWrapper = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledAccountVerificationForm = styled(StyledForm)`
  max-width: 470px;
  @media (min-width: ${themeToken.screenMD}px) {
    padding: ${themeToken.paddingXXL}px;
  }
`;

const SocialAuthenticate = () => {
  const { identifyMixpanelUser, mixpanel } = useTrackEvent({});
  const { useBreakpoint } = Grid;
  const { notification: toaster } = App.useApp();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { setUserData, userData } = useUserDataStore((state) => state);
  const { setPermissions } = usePermissionsStore((state) => state);
  const {
    setCompaniesList,
    setFullComapnyData,
    setIsPremiumPlan,
    setExpiresAt,
    setSelectedCompany: setSelectedCompanyToStore,
  } = useSelectedCompanyStore((state) => state);

  const { data: user, status, error } = useSocialAuth({});
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const {
    data: companiesList,
    refetch: getCompaniesList,
    status: companiesListFetchStatus,
  } = useGetCompanies({
    config: {
      enabled: false,
    },
  });

  const { data: companyData, refetch: getCompanyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
    config: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (status === 'error') {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.error,
      });
      navigate('/');
    } else if (status === 'success' && user) {
      setCookie('access_token', user.accessToken);
      setCookie('refresh_token', user.refreshToken);
      void identifyMixpanelUser(user.userInfo.mail);
      void mixpanel?.people.set({
        $name: user.userInfo.name,
        $email: user.userInfo.mail,
        $locale: user.userInfo.locale,
        $last_login: user.userInfo.lastLogin,
      });
      setUserData(user);
      // Get companies and set them to store
      void getCompaniesList();
    }
  }, [user, status]);

  useEffect(() => {
    queryClient.clear();
    clearCookie('access_token');
    clearCookie('refresh_token');

    setUserData(null);
    setPermissions([]);
    setCompaniesList([]);
    setFullComapnyData(null);
    setSelectedCompany(null);
  }, []);

  useEffect(() => {
    if (companiesListFetchStatus === 'success' && !companiesList.length) {
      // Navigate to create company
      navigate('/company/new');
    }
  }, [companiesListFetchStatus, companiesList]);

  useEffect(() => {
    // If there's a company selected before -> Set the selected company to store
    // If not -> Select the first company and set it to store
    if (companiesList && companiesList.length) {
      setCompaniesList(companiesList);
      if (userData?.userInfo.current_company) {
        const [fallbackCompany] = companiesList;
        const currentCompany = companiesList.find((company) => company.cid === userData?.userInfo.current_company);
        setSelectedCompany(currentCompany ?? fallbackCompany);
      }
    }
  }, [companiesList]);

  useEffect(() => {
    // Fetch the company details and permissions
    if (selectedCompany) {
      void getCompanyData();
    }
  }, [selectedCompany]);

  useEffect(() => {
    // Set full company data
    // Set Permissions
    // Navigate to company (is_owner -> dashboard) | (!is_owner -> my-investment)
    if (companyData) {
      setFullComapnyData(companyData);
      setExpiresAt(companyData.expired_at);
      setIsPremiumPlan(companyData?.current_plan?.price > 0);
      setPermissions(companyData.user_permissions);
      setSelectedCompanyToStore(selectedCompany);
      navigate(
        selectedCompany?.is_owner
          ? companyData.user_permissions.includes('company.forsale') && !companyData.company_data.is_listed
            ? '/company/dashboard?forsale_modal=1'
            : '/company/dashboard'
          : '/company/investment-overview',
      );
    }
  }, [companyData]);

  return (
    <StyledContainer>
      <StyledLeftDotsImage
        src={screens.md ? leftFrame : dots}
        width={screens.md ? 192 : 55}
        height={screens.md ? 80 : 180}
      />
      <StyledWrapper
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '36px',
          zIndex: 1,
        }}
      >
        <StyledWrapper>
          <StyledAccountVerificationForm>
            <img
              width={86}
              height={30}
              src={logo}
              alt="logo"
              style={{
                marginBottom: 24,
              }}
            />

            <StyledTitle
              level={screens.md ? 3 : 4}
              color={themeToken['branding-natural-9']}
              rootClassName="title"
              style={{
                marginBottom: '4px',
              }}
            >
              Authenticating
            </StyledTitle>

            <StyledText
              color={themeToken['branding-natural-7']}
              style={{
                textAlign: 'center',
                marginBottom: '24px',
              }}
            >
              Please wait while we are logging you in....
            </StyledText>
          </StyledAccountVerificationForm>
        </StyledWrapper>
      </StyledWrapper>
      <StyledRightDotsImage
        src={screens.md ? rightFrame : dots}
        width={screens.md ? 'auto' : 55}
        height={screens.md ? 'auto' : 180}
      />
    </StyledContainer>
  );
};

export default SocialAuthenticate;
