export const ImageAddIcon = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.28 1.46167H17.5661C16.763 1.46167 16.1723 1.79398 15.9046 2.38475C15.7569 2.65244 15.6923 2.96629 15.6923 3.33552V6.04936C15.6923 7.22167 16.3938 7.92321 17.5661 7.92321H20.28C20.6492 7.92321 20.963 7.85859 21.2307 7.7109C21.8215 7.44321 22.1538 6.85244 22.1538 6.04936V3.33552C22.1538 2.16321 21.4523 1.46167 20.28 1.46167ZM21.1476 5.08936C21.0553 5.18167 20.9169 5.24629 20.7692 5.25552H19.4676V5.72629L19.4769 6.53859C19.4676 6.69552 19.4123 6.82475 19.3015 6.93552C19.2092 7.02782 19.0707 7.09244 18.923 7.09244C18.6184 7.09244 18.3692 6.84321 18.3692 6.53859V5.24629L17.0769 5.25552C16.7723 5.25552 16.523 4.99706 16.523 4.69244C16.523 4.38782 16.7723 4.13859 17.0769 4.13859L17.8892 4.14782H18.3692V2.85552C18.3692 2.5509 18.6184 2.29244 18.923 2.29244C19.2276 2.29244 19.4769 2.5509 19.4769 2.85552L19.4676 3.5109V4.13859H20.7692C21.0738 4.13859 21.323 4.38782 21.323 4.69244C21.3138 4.84936 21.2492 4.97859 21.1476 5.08936Z"
        fill="#B9C1D4"
      />
      <path
        d="M9.23074 10.1199C10.4441 10.1199 11.4277 9.13632 11.4277 7.923C11.4277 6.70967 10.4441 5.72607 9.23074 5.72607C8.01741 5.72607 7.03381 6.70967 7.03381 7.923C7.03381 9.13632 8.01741 10.1199 9.23074 10.1199Z"
        fill="#B9C1D4"
      />
      <path
        d="M20.2801 7.92298H19.8462V12.1784L19.7262 12.0768C19.0062 11.4584 17.8431 11.4584 17.1231 12.0768L13.2831 15.3722C12.5631 15.9907 11.4001 15.9907 10.6801 15.3722L10.3662 15.1138C9.71083 14.5414 8.66775 14.4861 7.92929 14.9845L4.47698 17.3014C4.2739 16.7845 4.1539 16.1845 4.1539 15.483V7.7476C4.1539 5.14452 5.52929 3.76914 8.13236 3.76914H15.6924V3.33529C15.6924 2.96606 15.757 2.65221 15.9047 2.38452H8.13236C4.77236 2.38452 2.76929 4.3876 2.76929 7.7476V15.483C2.76929 16.4891 2.94467 17.3661 3.28621 18.1045C4.08006 19.8584 5.77852 20.8461 8.13236 20.8461H15.8678C19.2278 20.8461 21.2308 18.843 21.2308 15.483V7.71068C20.9631 7.85837 20.6493 7.92298 20.2801 7.92298Z"
        fill="#B9C1D4"
      />
    </svg>
  );
};
