import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';

import { GeolocationInfo } from '../types';

export const getUserCountry = async (): Promise<GeolocationInfo | string> => {
  if ('geolocation' in navigator) {
    let latitude, longitude;
    navigator.geolocation.getCurrentPosition((pos) => {
      latitude = pos.coords.latitude;
      longitude = pos.coords.longitude;
    });
    const { data } = await axiosClient.get<GeolocationInfo>(
      `https://api.bigdatacloud.net/data/reverse-geocode-client`,
      {
        params: {
          latitude,
          longitude,
          localityLanguage: 'en',
        },
      },
    );
    return data;
  }
  return 'Saudi Arabia';
};

type QueryFnType = typeof getUserCountry;

type UseGetUserOptions = {
  config?: QueryFunction<QueryFnType>;
};

export const useGetUserCountry = ({ config }: UseGetUserOptions) => {
  return useQuery({
    queryKey: ['user-country'],
    queryFn: () => getUserCountry(),
    ...config,
  });
};
