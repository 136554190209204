export default {
  upgradeCompanyProfileCapabilities: 'ترقية ملف شركتك وقدراتها',
  perfectPlanForYou: 'لدينا باقة مثالية لك',
  name: 'الاسم',
  tag: 'علامة',
  annualPlanPrice: 'سعر الباقة السنوية',
  validDays: 'صالح لمدة {{duration}} يوم',
  allIsFree: 'كل شيء مجاني',
  slogan: 'الشعار',
  unlimited: 'عدد غير محدود من',
  upTo: 'حتى',
  currentPlan: 'الباقة الحالية',
  go: 'اذهب',
  features: 'الميزات',
  everythingInFreePlanPlus: 'كل شيء في باقتنا المجانية بالإضافة إلى..',
  featuresIconsMap: 'خريطة أيقونات الميزات',
  check: 'تحقق',
  close: 'إغلاق',
  'Most Popular': 'الأكثر شيوعًا',
  'Save Plan': 'باقة التوفير',
  'Suitable for Small & Middle Size Companies': 'مناسب للشركات الصغيرة والمتوسطة',
  'Suitable for Big Company': 'مناسب للشركات الكبيرة',
  Shareholders: 'المساهمين',
  'Equity Classes': 'فئات الأسهم',
  'Convertible Instruments': 'السندات القابلة للتحويل',
  'Option Pools': 'خطط خيارات الأسهم',
  'Manage Users': 'إدارة المساهمين',
  Captable: 'جدول الملكية',
  'Vesting Schedules': 'جداول الاستحقاق',
  Dataroom: 'غرفة البيانات',
  'Data room Folders': 'مجلدات غرفة البيانات',
  'Uploaded Files': 'الملفات المرفوعة',
  'Active data room shares': 'مشاركات غرفة البيانات النشطة',
  'Scenario Modeling': 'النمذجة',
  'Scenerio Modeling': 'النمذجة',
  'Exit Scenario Modeling': 'نمذجة التخارج',
  Free: 'مجاني',
  'Suitable for Early Stage Companies': 'مناسب للشركات الناشئة',
  from: 'من',
  noAccess: 'عذرًا .. ليس لديك حق الوصول إلى',
  pageAvailable: 'هذه الصفحة متاحة فقط مع الباقات المميزة',
  knowMore: 'تعرف على المزيد حول أهم ميزة',
  explorePlans: 'استكشف باقاتنا',
  'company dashboard': 'لوحة التحكم',
  'my investments': 'نظرة عامة على الاستثمار',
  'captable overview': 'نظرة عامة على جدول الملكية',
  'captable equities': 'الاسهم',
  'captable options': 'خيارات الاسهم',
  'captable convertible instruments': 'السندات القابلة للتحويل',
  'captable vesting schedules': 'جدول الاستحقاق',
  'investment round': 'جولة الاستثمار',
  'Exit Modeling': 'نمذجة التخارج',
  Valuation: 'التقييم',
  dataroom: 'غرفة البيانات',
  'dataroom shared logs': 'سجلات المشاركة بغرفة البيانات',
  'Dataroom Analytics': 'تحليلات غرفة البيانات',
  'users list': 'قائمة المستخدمين',
  'roles list': 'الأدوار والصلاحيات',
  goPremium: 'احصل على النسخة المميزة',
  noAccessPage: {
    title: 'عذرًا! يبدو أنك لا تملك حق الوصول إلى هذه الصفحة',
    description:
      'يبدو أنك لا تمتلك حاليًا الإذن لعرض هذه الصفحة. إذا كنت بحاجة إلى الوصول، يرجى الاتصال بالمسؤول وطلب الأذونات اللازمة.',
    thanks: 'شكرًا لتفهمك',
  },
  mostPopularTag: 'الأكثر شيوعاً',
  upgradeYourLimits: 'ارفع حدودك',
  validDuration: 'صالح لمدة {{duration}} يوم',
  upgradePlanButton: 'ترقية الباقة',
  continueFreePlanButton: 'الاستمرار بالباقة المجانية',
  planName: 'باقة {{name}}',
  'Enjoy the captable': 'استمتع بجدول الرسملة',
  "Manage you company's data": 'إدارة بيانات شركتك',
  "Manage your company's data": 'إدارة بيانات شركتك',
  'See your future through the scenario': 'رؤية مستقبلك من خلال النمذجة',
  upgradeCompanyProfile: 'ترقية ملف الشركة',
  detailsAboutThePlanAndPromocode: 'تفاصيل عن الباقة والرمز الترويجي',
  upgradeTo: 'الترقية إلى',
  paidAnnually: 'مدفوع سنويًا',
  details: 'تفاصيل',
  promoCode: 'الرمز الترويجي',
  enterPromoCode: 'أدخل الرمز الترويجي',
  apply: 'تطبيق',
  reset: 'إعادة تعيين',
  summary: 'ملخص',
  plan: 'الباقة',
  discount: 'الخصم',
  total: 'المجموع',
  goToPayment: 'الانتقال إلى الدفع',
  paymentReceivedSubscriptionActive: 'تم استلام الدفع وتنشيط الاشتراك!',
  welcomeBoardEnjoyAccess: 'مرحبا بك! استمتع بالميزات الاضافية',
  yourRecentPaymentReceived: 'تم استلام دفعتك الأخيرة. تاريخ الفاتورة التالي هو',
  done: 'تم',
  'Easily control who can access and modify your company data.': 'تحكم بسهولة في من يمكنه الوصول لشركتك.',
  'Simplify equity management and visualize ownership structures.': 'ادر جدول رأس المال بسهولة ووضوح.',
  'Securely share critical documents with stakeholders.': 'شارك ملقاتك المهمة بسهولة وأمان.',
  'Predict and plan for future financial outcomes.': 'توقع وخطط لمستقبل الشركة.',
  'Obtain accurate business valuations for better decision-making.': 'احصل على تقييم لشركتك بناء على السوق الحالي.',
};
