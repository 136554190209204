export const PlainFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159960)">
      <path
        d="M6.76974 0.875H14.5836L21.0991 7.66614V21.2542C21.0991 22.6971 19.9212 23.875 18.4783 23.875H6.76974C5.32025 23.875 4.14893 22.6978 4.14893 21.2542V3.49582C4.14893 2.04556 5.31948 0.875 6.76974 0.875Z"
        fill="#868EA1"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.4186 6.90606H15.2892V1.60995L20.4186 6.90606Z" fill="white" stroke="#EDEFF4" />
      <path
        d="M15.7852 16.0233H9.46237C9.16725 16.0233 8.93115 16.2594 8.93115 16.5545C8.93115 16.8422 9.16725 17.0783 9.46237 17.0783H15.7852C16.0803 17.0783 16.3164 16.8422 16.3164 16.5545C16.3164 16.2593 16.0803 16.0233 15.7852 16.0233ZM15.7852 18.1333H9.46237C9.16725 18.1333 8.93115 18.3694 8.93115 18.6645C8.93115 18.9523 9.16725 19.1884 9.46237 19.1884H15.7852C16.0803 19.1884 16.3164 18.9523 16.3164 18.6645C16.3164 18.3694 16.0803 18.1333 15.7852 18.1333ZM12.5684 11.8105H9.46237C9.16725 11.8105 8.93115 12.0393 8.93115 12.3344C8.93115 12.6295 9.16725 12.8582 9.46237 12.8582H12.5684C12.8635 12.8582 13.0996 12.6295 13.0996 12.3344C13.0996 12.0393 12.8635 11.8105 12.5684 11.8105ZM15.7852 13.9132H9.46237C9.16725 13.9132 8.93115 14.1493 8.93115 14.4444C8.93115 14.7322 9.16725 14.9682 9.46237 14.9682H15.7852C16.0803 14.9682 16.3164 14.7321 16.3164 14.4444C16.3164 14.1493 16.0803 13.9132 15.7852 13.9132Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159960">
        <rect width="24" height="24" fill="white" transform="translate(0.625 0.375)" />
      </clipPath>
    </defs>
  </svg>
);
