import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { NestedFolders } from '../types';

type FoldersResponseData = {
  data: NestedFolders[];
  metadata: Metadata;
};

type FoldersParams = {
  companyID: number;
  queryParams?: PaginationParams;
};

export const getNestedFolders = async ({ companyID, queryParams }: FoldersParams) => {
  const { data } = await axiosClient.get<FoldersResponseData>(`/companies/${companyID}/data-rooms/nested-folders`, {
    params: queryParams,
  });
  return data;
};

type UseGetNestedFoldersOptions = {
  companyID: number;
  queryParams?: FoldersParams['queryParams'];
  config?: Partial<UseQueryOptions<NestedFolders>>;
};

export const useGetNestedFolders = ({ companyID, queryParams }: UseGetNestedFoldersOptions) => {
  return useQuery({
    queryKey: ['nested_folders', companyID, queryParams],
    queryFn: () => getNestedFolders({ companyID, queryParams }),
  });
};
