const UploadDocumentIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.00008 11.334V7.33398L4.66675 8.66732" fill="black" fillOpacity="0.88" />
      <path
        d="M6.00008 11.334V7.33398L4.66675 8.66732"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.33398L7.33333 8.66732"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6666 6.66732V10.0007C14.6666 13.334 13.3333 14.6673 9.99992 14.6673H5.99992C2.66659 14.6673 1.33325 13.334 1.33325 10.0007V6.00065C1.33325 2.66732 2.66659 1.33398 5.99992 1.33398H9.33325"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6666 6.66732H11.9999C9.99992 6.66732 9.33325 6.00065 9.33325 4.00065V1.33398L14.6666 6.66732Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadDocumentIcon;
