const RenameIcon = ({
  width = 24,
  height = 24,
  fill = '#6430DA',
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2599 3.60119L5.04985 12.2912C4.73985 12.6212 4.43985 13.2712 4.37985 13.7212L4.00985 16.9612C3.87985 18.1312 4.71985 18.9312 5.87985 18.7312L9.09985 18.1812C9.54985 18.1012 10.1799 17.7712 10.4899 17.4312L18.6999 8.74119C20.1199 7.24119 20.7599 5.53119 18.5499 3.44119C16.3499 1.37119 14.6799 2.10119 13.2599 3.60119Z"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8899 5.05078C12.3199 7.81078 14.5599 9.92078 17.3399 10.2008L11.8899 5.05078Z"
        fill={fill}
        fillOpacity="0.15"
      />
      <path
        d="M11.8899 5.05078C12.3199 7.81078 14.5599 9.92078 17.3399 10.2008"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3 22H21"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RenameIcon;
