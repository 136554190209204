import styled from 'styled-components';
import { Badge } from 'antd';

import themeToken from '@lib/theme/tokens/index';

export const StyledNotificationBadge = styled(Badge)`
  &.ant-badge {
    margin-inline: ${themeToken.marginXS}px;
    & > .ant-badge-count {
      top: 10px;
    }
  }
`;
