export const EditCompanyIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6772 13.8742V10.9492"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6772 5.5875V4.125"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6775 9.48789C12.7545 9.48789 13.6275 8.61485 13.6275 7.53789C13.6275 6.46094 12.7545 5.58789 11.6775 5.58789C10.6006 5.58789 9.72754 6.46094 9.72754 7.53789C9.72754 8.61485 10.6006 9.48789 11.6775 9.48789Z"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.32275 13.8746V12.4121"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.32275 7.05V4.125"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.32256 12.4117C7.39951 12.4117 8.27256 11.5387 8.27256 10.4617C8.27256 9.38476 7.39951 8.51172 6.32256 8.51172C5.2456 8.51172 4.37256 9.38476 4.37256 10.4617C4.37256 11.5387 5.2456 12.4117 6.32256 12.4117Z"
      stroke="#868EA1"
      strokeWidth="1.125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
