import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

import axiosClient from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import type { EditFilePayload } from '../types';

type EditFileParams = {
  company_id: number;
  file_id: number;
  payload: EditFilePayload;
};

export const editFile = async ({ company_id, file_id, payload }: EditFileParams) => {
  const response = await axiosClient.put<{ data: { message: string } }>(
    `/companies/${company_id}/data-rooms/files/${file_id}`,
    {
      ...payload,
    },
  );

  return response.data;
};

type UseEditFileDTO = {
  parentFolderLevel: number;
  postSuccess?: () => void;
  config?: Partial<
    UseMutationOptions<
      { data: { message: string } },
      AxiosError<{ message: string }>,
      { company_id: number; file_id: number; payload: EditFilePayload }
    >
  >;
};

export const useEditFile = ({ parentFolderLevel, postSuccess, config }: UseEditFileDTO) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    onSuccess: async () => {
      if (parentFolderLevel === 1)
        await queryClient.invalidateQueries({
          queryKey: ['folders'],
        });
      else
        await queryClient.invalidateQueries({
          queryKey: ['folder-data'],
        });

      toaster.success({
        message: 'Updated Successfully',
        description: 'Your File Updated Successfully',
      });
      postSuccess?.();
    },
    mutationFn: editFile,
    ...config,
  });
};
