export default {
  phoneNumber: 'رقم الجوال',
  confirm: 'تأكيد',
  microsoftAccount: 'حساب مايكروسوفت',
  googleAccount: 'حساب جوجل',
  emailAddress: 'البريد الإلكتروني',
  termsAndConditions: 'الشروط والأحكام',
  fullName: 'الاسم الكامل',
  addMore: 'أضف المزيد',
  settings: 'الإعدادات',
  auth: {
    orContinueUsing: 'أو الاستمرار باستخدام',
  },
  edit: 'تعديل',
  delete: 'حذف',
  search: 'بحث',
  reset: 'إعادة',
  close: 'إغلاق',
  searchBy: 'بحث ب',
  optional: 'إختياري',
  download: 'تحميل',
  premium: 'مميز',
  deleteWarning: 'انت على وشك حذف شئ مهم',
  yesDelete: 'نعم, حذف',
  deleteConfirmation: 'هل متأكد من حذف',
  impactCapTableWarning:
    'قد تؤثر هذه الخطوة على جدول الرسملة الخاص بك بشكل عام، مما قد يتسبب في أضرار وتغييرات في الأسهم الأخرى.',
  chart: {
    rate: 'معدل',
  },
  pinnedNotification: {
    oops: 'عفواً!',
    headsUp: 'تنبيه، أنت على وشك تجاوز أو تجاوزت الحد الأقصى لاستخدام الميز التالية داخل <b>{{feature}}</b>:',
    exceedMax:
      'تنبيه، أنت على وشك تجاوز الحد الأقصى لعدد <b>{{feature}}</b> المسموح به (<b>{{usage}}</b> من <b>{{limit}}</b> <b>{{feature}}</b>) في باقتك. لإضافة المزيد من <b>{{feature}}</b> تحتاج إلى الاشتراك',
    exceededMax:
      'للأسف، لقد تجاوزت الحد الأقصى لعدد <b>{{feature}}</b> المسموح به (<b>{{limit}}</b> <b>{{feature}}</b>) في باقتك. لإضافة المزيد من <b>{{feature}}</b> تحتاج إلى الاشتراك',
    addMoreUsage: 'لإضافة المزيد من استخدام <b>{{feature}}</b> لميزات مختلفة، تحتاج إلى ترقية باقتك.',
    yourSubscriptionEndsSoon:
      'ينتهي اشتراكك قريبًا في <b>{{expiryDate}}</b> (<b>{{expiryInterval}}</b> يومًا متبقية). جدد الآن أو قم بتغيير باقاتك.',
    valuationSubscriptionEndsSoon:
      'فترة تعديل معلومات التقييم علي وشك الانتهاء في <b>{{expiryDate}}</b> (<b>{{expiryInterval}}</b> يومًا متبقية) الرجاء التأكد من صحة البيانات المدخلة ومن اصدار التقرير.',
    valuationSubscriptionEnded:
      'فترة تعديل معلومات التقييم انتهت في <b>{{expiryDate}}</b>. اذا كنت بحاجة الي تقييم جديد، يمكنك شراء تقييم جديد.',
    exploreOurPlans: 'استكشاف باقاتنا',
    renewNow: 'جدد الآن',
    classes: 'فئات الأسهم',
    options: 'خيارات الاسهم',
    convertibles: 'السندات القابلة للتحويل',
    schedules: 'جداول الاستحقاق',
    dataroom: 'غرفة البيانات',
    users: 'المستخدمين',
    valuation: 'التقييم',
    folder: 'مجلد',
    folders: 'مجلد/مجلدات',
    file: 'ملف',
    files: 'ملف/ملفات',
    share: 'مشاركة فعالة',
    shares: 'مشاركة/مشاركات فعالة',
    limitUsage: '<b>{{usage}}</b> من <b>{{limit}}</b>',
  },
};
