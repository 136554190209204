import { axiosClient } from '@/lib/axios';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { UserDTO } from '@/types';
import { VerificationData } from '../types';
import { AxiosError } from 'axios';

const verifyOTP = async (verificationData: VerificationData): Promise<UserDTO> => {
  const { data } = await axiosClient.post<UserDTO>('/auth/verify-otp', verificationData);
  return data;
};

type UseVerifyOTPDTO = {
  config?: MutationFunction<typeof verifyOTP>;
  handleAfterSuccess: (userData: UserDTO) => void;
  handleAfterFailure: (code?: number) => void;
};

export const useVerifyOTP = ({ config, handleAfterSuccess, handleAfterFailure }: UseVerifyOTPDTO) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error: AxiosError<{ message: string }>) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error?.response?.data.message,
      });
      handleAfterFailure(error.response?.status);
    },
    onSuccess: async (res) => {
      handleAfterSuccess(res);
    },
    ...config,
    mutationFn: verifyOTP,
  });
};
