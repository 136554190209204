import { MutationFunction, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import { axiosClient } from '@/lib/axios';

type NotificationsParams = {
  companyID: number;
  notificationID: number;
};

type NotificationsResponse = {
  message: string;
};

export const markNotificationRead = async ({
  companyID,
  notificationID,
}: NotificationsParams): Promise<NotificationsResponse> => {
  const { data } = await axiosClient.put<NotificationsResponse>(
    `/companies/${companyID}/notifications/${notificationID}/read`,
  );
  return data;
};

type UseMarkNotificationReadOptions = {
  companyID?: number;
  notificationID?: number;
  config?: MutationFunction<typeof markNotificationRead>;
  postSuccess?: () => void;
};

export const useMarkNotificationsRead = ({ companyID, postSuccess, config }: UseMarkNotificationReadOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error: AxiosError<{ message: string }>) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data?.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['company', companyID],
      });

      postSuccess?.();
    },
    ...config,
    mutationFn: markNotificationRead,
  });
};
