const AddIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill="#6430DA" />
      <path d="M5 9H13M9 5L9 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default AddIcon;
