import { Carousel as AntCarousel, Flex, Typography } from 'antd';
import carouselImage from '@/assets/auth/carousel-dummy.png';
import themeToken from '@lib/theme/tokens/index';
import styled from 'styled-components';
import LogoIcon from '@/components/Icons/LogoIcon';
import { useTranslation } from 'react-i18next';

const StyledFlex = styled(Flex)`
  background-color: ${themeToken['branding-primary-6']};
  max-width: 631px;
  width: 100%;
  gap: 20px;
  padding-inline: 30px;
  @media (min-width: ${themeToken.screenXL}px) {
  }
`;
const StyledCarousel = styled(AntCarousel)`
  max-width: 451px;
  .slick-dots {
    bottom: -30px;
    li {
      width: 4px;
      height: 4px;
      border-radius: 1px;
      button {
        height: 4px;
        background-color: ${themeToken['branding-secondary-6']};
      }
    }
    li.slick-active {
      width: 6px;
      height: 6px;
      border-radius: 2px;
      button {
        height: 6px;
      }
    }
  }
`;

const { Text, Title } = Typography;

const StyledCarouselImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
`;

const StyledCarouselText = styled(Text)`
  &.ant-typography {
    color: ${themeToken['branding-primary-2']};
  }
`;

const StyledCarouselTitle = styled(Title)`
  &.ant-typography {
    color: ${themeToken['branding-primary-2']};
    margin: 0 !important;
  }
`;

const Carousel = () => {
  const { i18n } = useTranslation();
  return (
    <StyledFlex align="center" justify="center" vertical>
      <LogoIcon fill="white" />

      <StyledCarousel
        rtl={i18n.language === 'ar' ? true : false}
        rootClassName="carousel-wrapper"
        dots={{
          className: 'carousel-dots',
        }}
      >
        <div>
          <StyledCarouselImage src={carouselImage} alt="carousel" />
          <Flex vertical gap={2} align="center" justify="center">
            <StyledCarouselText>Equity management & more</StyledCarouselText>
            <StyledCarouselTitle level={2}>Simple and Clear</StyledCarouselTitle>
          </Flex>
        </div>
        <div>
          <StyledCarouselImage src={carouselImage} alt="carousel" />
          <Flex vertical gap={2} align="center" justify="center">
            <StyledCarouselText>Equity management & more</StyledCarouselText>
            <StyledCarouselTitle level={2}>Simple and Clear</StyledCarouselTitle>
          </Flex>
        </div>
        <div>
          <StyledCarouselImage src={carouselImage} alt="carousel" />
          <Flex vertical gap={2} align="center" justify="center">
            <StyledCarouselText>Equity management & more</StyledCarouselText>
            <StyledCarouselTitle level={2}>Simple and Clear</StyledCarouselTitle>
          </Flex>
        </div>
      </StyledCarousel>
    </StyledFlex>
  );
};

export default Carousel;
