export default {
  sendOTP: 'ارسل رمز التحقق',
  accountVerification: 'تأكيد الحساب',
  weHaveSentOTP:
    'لقد ارسلنا رمز تحقق فريد الي {{method}} {{contact}} المسجل لدينا. برجاء إدخال رمز التحقق بالاسفل للتحقق من هويتك',
  enterCode: 'ادخل الرمز',
  resendOTPAfter: 'اعادة ارسال رمز التحقق بعد',
  useEmailToReceiveOTP: 'استخدم البريد الالكتروني لاستقبال رمز التحقق',
  usePhoneToReceiveOTP: 'استخدم الهاتف لاستقبال رمز التحقق',
};
