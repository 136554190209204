import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Divider, Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

import { EditFilePayload } from '../types';

import { StyledInput } from '@/lib/theme/components/Input';
import { StyledButton } from '@/lib/theme/components/Button';
import { StyledForm } from '@/components/Shared/Form';
import CommonModal from '@/features/captable/components/Shared/CommonModal';

import RenameIcon from '@/components/Icons/RenameIcon';

import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useEditFile } from '../api/editFile';

const { Text } = Typography;

type Props = {
  isEditFileOpen: boolean;
  handleCancel: () => void;
  parentFolderLevel: number;
  fileId: number;
  fileName: string;
};

const EditFileForm = ({ isEditFileOpen, handleCancel, parentFolderLevel, fileId, fileName }: Props) => {
  const { t } = useTranslation('dataroom');
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [form] = Form.useForm();

  const resetForm = () => {
    handleCancel();
    form.resetFields();
  };

  const { mutate: editFileMutation, isPending } = useEditFile({
    parentFolderLevel,
    postSuccess: resetForm,
  });

  const onFinish = (values: { name: string }): void => {
    const payload: EditFilePayload = {
      name: values.name,
    };

    editFileMutation({ company_id: selectedCompany?.cid as number, file_id: fileId, payload });
  };

  useEffect(() => {
    form.setFieldsValue({ name: fileName });
  }, [form, fileName]);

  return (
    <CommonModal isModalOpen={isEditFileOpen} handleCancel={resetForm}>
      <Flex gap={14}>
        <RenameIcon />
        <Text
          style={{
            color: themeToken['branding-primary-6'],
            fontWeight: 600,
            fontSize: themeToken.fontSizeLG,
            marginBottom: themeToken.marginXXS,
          }}
        >
          {t('rename')}
        </Text>
      </Flex>
      <Divider
        style={{
          marginBlock: themeToken.margin,
          borderColor: themeToken['branding-natural-3'],
        }}
      />

      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Flex
          align="start"
          vertical
          style={{
            flex: 1,
          }}
        >
          <Form.Item
            label={t('fileName')}
            name="name"
            style={{
              width: '100%',
            }}
          >
            <StyledInput placeholder={t('fileName')} />
          </Form.Item>

          <Divider
            style={{
              marginBlock: themeToken.margin,
              borderColor: themeToken['branding-natural-3'],
            }}
          />

          <Flex style={{ width: '100%' }} justify="space-between">
            <StyledButton ghost type="primary" onClick={resetForm}>
              {t('back')}
            </StyledButton>
            <StyledButton disabled={isPending} type="primary" htmlType="submit">
              {t('submit')}
            </StyledButton>
          </Flex>
        </Flex>
      </StyledForm>
    </CommonModal>
  );
};

export default EditFileForm;
