import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { Notification } from '../../types';

type NotificationsParams = {
  companyID?: number;
  queryParams?: PaginationParams;
};

type NotificationsResponse = {
  data: Notification[];
  metadata: Metadata;
};

export const getNotifications = async ({
  companyID,
  queryParams,
}: NotificationsParams): Promise<NotificationsResponse> => {
  const { data } = await axiosClient.get<NotificationsResponse>(`/companies/${companyID}/notifications`, {
    params: queryParams,
  });
  return data;
};

type UseGetNotificationsOptions = {
  companyID?: number;
  queryParams?: NotificationsParams['queryParams'];
  config?: Partial<UseQueryOptions<NotificationsResponse>>;
};

export const useGetNotifications = ({ companyID, queryParams, config }: UseGetNotificationsOptions) => {
  return useQuery({
    queryKey: ['notifications', companyID, queryParams],
    queryFn: () => getNotifications({ companyID, queryParams }),
    ...config,
    enabled: !!companyID,
  });
};
