import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';

import type { Company } from '../types';

type CompaniesResponse = {
  data: Company[];
};

export const getCompanies = async (): Promise<Company[]> => {
  const { data } = await axiosClient.get<CompaniesResponse>(`/companies`);
  return data?.data;
};

type UseGetCompaniesOptions = {
  config?: Partial<UseQueryOptions<Company[]>>;
};

export const useGetCompanies = ({ config }: UseGetCompaniesOptions) => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: getCompanies,
    ...config,
  });
};
