import { useTranslation } from 'react-i18next';
import { Button, Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import ValuationRegisterIcon from '@/components/Icons/ValuationRegisterIcon';
import DownloadIcon from '@/components/Icons/DownloadIcon';
import { StyledButton } from '@/lib/theme/components/Button';
import valuation_report from '@/assets/valuation_report.pdf';
import { useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;

const Register = () => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const navigate = useNavigate();

  return (
    <Flex vertical align="center" justify="space-between" style={{ maxWidth: '600px' }}>
      <Flex vertical align="center" gap={8}>
        <ValuationRegisterIcon />
        <Title
          level={4}
          style={{
            color: themeToken['branding-primary-6'],
            marginTop: themeToken.marginXXS,
          }}
        >
          {t('companyValuationStartsHere')}
        </Title>
        <Text
          style={{
            color: themeToken['branding-primary-6'],
            fontSize: themeToken.fontSize,
            textAlign: 'center',
          }}
        >
          {t('fewQuestionsForValuation')}
          <br /> {t('letsGo')}
        </Text>
      </Flex>
      <StyledButton
        ghost
        icon={<DownloadIcon />}
        type="primary"
        style={{
          borderColor: 'transparent',
          color: themeToken['branding-secondary-6'],
          fontWeight: 600,
          marginTop: themeToken.marginXS,
        }}
        href={valuation_report}
        download={true}
      >
        {t('downloadSampleReport')}
      </StyledButton>
      <StyledButton
        type="primary"
        style={{
          background: themeToken['branding-primary-6'],
          marginBlock: themeToken.marginXL,
        }}
        onClick={() => navigate('/valuation/questionnaire')}
      >
        {t('startNow')}
      </StyledButton>
      <Flex vertical align="center" gap={4}>
        <Text
          style={{
            color: themeToken['branding-primary-4'],
            fontSize: themeToken.fontSizeSM,
          }}
        >
          {t('needHelpOrSeeHowItWorks')}
        </Text>
        <Button
          style={{
            color: themeToken['branding-primary-4'],
            fontSize: themeToken.fontSizeSM,
            height: 'auto',
            padding: 0,
          }}
          type="link"
        >
          <span style={{ textDecoration: 'underline' }}>{`${t('visitHelpAndSupport')}.`}</span>
        </Button>
      </Flex>
    </Flex>
  );
};

export default Register;
