import { useMixpanel } from './useMixpanel';

type EventPayload = {
  [key: string]: unknown;
};
export const useTrackEvent = ({
  page,
  isPageView,
  isGeneralEvent,
}: {
  page?: string;
  isPageView?: boolean;
  isGeneralEvent?: boolean;
}) => {
  const mixpanel = useMixpanel();
  return {
    sendMixpanelEvent: (
      event: string,
      payload?: EventPayload,
      req?: { status: 'success' | 'fail'; req_path: string },
    ) => {
      const eventName = req ? `${page}-page__req:${req.status}[${event}]` : `${page}-page__${event}`;
      if (isPageView) {
        mixpanel?.track(`${event}-page-view`);
      } else if (isGeneralEvent) {
        mixpanel?.track(event, { ...payload });
      } else {
        mixpanel?.track(eventName, { ...payload, req_path: req?.req_path });
      }
    },
    identifyMixpanelUser: (email: string) => {
      mixpanel?.identify(email);
    },
    mixpanel,
  };
};
