import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';

export const HeaderLanguageButton = styled(StyledButton)`
  &.ant-btn {
    font-size: ${themeToken.size}px;
    color: ${themeToken.white};

    @media (min-width: ${themeToken.screenMD}px) {
      background: #f5f4f8;
      color: ${themeToken['branding-primary-6']};
    }
  }
  &.ant-btn-text.ant-btn {
    &:hover {
      color: ${themeToken.white};
    }
  }
`;
