import styled from 'styled-components';
import { Collapse } from 'antd';
import themeToken from '../tokens';

const defaultTheme = {
  headerBg: themeToken['branding-primary-1'],
  headerPadding: `${themeToken.padding}px  ${themeToken.paddingXS}px`,
  contentPadding: `${themeToken.paddingLG}px  ${themeToken.padding}px`,
};

export const collapseTheme = {
  ...defaultTheme,
};

export const StyledCollapse = styled(Collapse)<{ isRoleCollapse: boolean; activeKey: 0 | 1 }>`
  &.ant-collapse {
    width: 100%;
    border-radius: ${themeToken.borderRadiusSM}px;
    border: 1px solid ${themeToken.tableHoverBg};
    margin-block-end: ${themeToken.marginLG}px;
    .ant-collapse-item {
      border: 0;
      display: flex;
      flex-direction: column;
      .ant-collapse-header {
        align-items: center;
        border-radius: ${themeToken.borderRadiusSM}px ${themeToken.borderRadiusSM}px 0 0;
        padding: ${themeToken.padding}px;
        background: ${(props) =>
          props.isRoleCollapse && !props.activeKey ? themeToken['branding-natural-5'] : 'inherit'};
        & > .ant-collapse-header-text {
          color: ${themeToken.colorPrimary};
          font-size: ${themeToken.fontSizeLG}px;
          font-weight: ${themeToken.fontWeightStrong};
          & > .ant-flex {
            & > .ant-flex {
              & > h5 {
                color: ${(props) =>
                  props.isRoleCollapse && !props.activeKey
                    ? themeToken['branding-natural-7']
                    : themeToken['branding-primary-6']};
              }
              & > span {
                color: ${(props) =>
                  props.isRoleCollapse && !props.activeKey
                    ? themeToken['branding-natural-7']
                    : themeToken['branding-primary-4']};
              }
            }
          }
        }

        & > .ant-collapse-expand-icon {
          padding: 0;
        }

        & .ant-switch-inner-checked,
        .ant-switch-inner-unchecked {
          height: 100%;

          & > .anticon {
            height: 100%;
          }
        }
      }
      .ant-collapse-content {
        display: ${(props) => (props.className === 'hasChildren' ? 'block' : 'none')};
        border: 0;
        border-radius: 0 0 ${themeToken.borderRadiusSM}px ${themeToken.borderRadiusSM}px;
        & > .ant-collapse-content-box {
          padding: ${themeToken.paddingLG}px ${themeToken.padding}px;
          & > .ant-form-item:last-child {
            margin: 0;
          }
        }
      }
    }
  }
`;
