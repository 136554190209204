import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import countryCodes from '@/utils/countryCodes.json';
import { useGetUserCountry } from '@/features/auth/api/getUserCountry';

const StyledSelect = styled(Select)<{ isVisible?: boolean }>`
  transition: all 0.3s ease-in-out;
  .ant-select-selection-item {
    color: ${themeToken['branding-primary-6']};
  }
`;

export const PhoneCodesSelect = ({
  disabled,
  setCurrentCountryCode,
}: {
  disabled?: boolean;
  isVisible?: boolean;
  setCurrentCountryCode?: (code: string) => void;
}) => {
  const [selectedCountry, setSelectedCountry] = useState('Saudi Arabia');
  const { data } = useGetUserCountry({});
  const modifiedCountryCodes = countryCodes.map(({ country, code, emoji, countryCode }) => ({
    label: `${emoji} ${country} (+${code})`,
    value: country,
    code,
    countryCode,
  }));

  const onCountryCodeChange = (country: string) => {
    const record = modifiedCountryCodes.find(({ value }) => value === country);
    setSelectedCountry(country);
    setCurrentCountryCode?.(record ? record.code : country);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    onCountryCodeChange('966');
  }, []);

  useEffect(() => {
    if (data) {
      if (typeof data === 'string') onCountryCodeChange(data);
      else {
        const record = modifiedCountryCodes.find(
          ({ value, countryCode }) => value === data?.countryName || countryCode === data?.countryCode,
        );
        setSelectedCountry(record?.value ?? data.countryName);
        setCurrentCountryCode?.(record?.code ?? data.countryName);
      }
    }
  }, [data]);

  return (
    <StyledSelect
      showSearch
      disabled={disabled}
      data-qa="login-form__country-code-select"
      defaultValue="Saudi Arabia"
      value={selectedCountry}
      popupClassName="select-dropdown"
      popupMatchSelectWidth={true}
      dropdownStyle={{
        width: '180px',
      }}
      filterOption={filterOption}
      options={modifiedCountryCodes}
      onChange={onCountryCodeChange}
    />
  );
};
