import { Card, Flex, Grid, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { CompanyValuationDashboardData } from '@/features/valuation/types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatNumber } from '@/utils/formatNumber';
import { useTranslation } from 'react-i18next';
import TachometerChart from '../TachometerChart';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const MethodsBreakdown = ({ data }: { data: CompanyValuationDashboardData['methods_module'] | undefined }) => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const screens = useBreakpoint();
  const { fullCompanyData } = useSelectedCompanyStore((state) => state);
  const barChartData = Object.values(data || {}).map((item) => ({
    name: item.name,
    valuation: item.value,
    value: item.weight,
  }));

  const tachometerData = barChartData.map((it) => [
    { value: it.value * 100, color: '#D81059' },
    { value: 100 - it.value * 100, color: '#DCE0E9' },
  ]);

  return (
    <Card
      bodyStyle={{
        padding: '0px',
        height: '100%',
        gap: '8px',
        flexDirection: 'column',
        display: 'flex',
      }}
      style={{
        width: '100%',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text
        style={{
          color: themeToken.colorPrimary,
          fontWeight: 600,
        }}
      >
        {t('methodsBreakdown')}
      </Text>
      <ResponsiveContainer width="100%" height={222}>
        <BarChart
          data={barChartData}
          margin={{
            top: 5,
            right: screens.md ? 30 : 0,
            left: screens.md ? 20 : -20,
            bottom: 5,
          }}
          barSize={screens.md ? 70 : 25}
        >
          <CartesianGrid stroke={themeToken['branding-natural-3']} />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            fontSize={12}
            tickFormatter={(value: string) => t(value)}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={(value: number) => {
              return value >= 1000000
                ? `${value / 1000000}M`
                : value >= 1000
                  ? `${value / 1000}K`
                  : `${value.toString()}`;
            }}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            contentStyle={{
              borderRadius: themeToken.borderRadius,
              borderColor: '#cccccc',
            }}
            formatter={(value, name: string) => [
              `${formatNumber(value as number)} ${fullCompanyData?.company_data.company_currency.code}`,
              t(name),
            ]}
          />
          <Bar dataKey="valuation" fill={themeToken.colorPrimary} />
        </BarChart>
      </ResponsiveContainer>
      <Text
        style={{
          color: themeToken.colorPrimary,
          fontWeight: 600,
        }}
      >
        {t('weightOfTheMethods')}
      </Text>

      <Flex gap={8}>
        {tachometerData.map((it, index) => (
          <TachometerChart key={index} data={it} />
        ))}
      </Flex>
    </Card>
  );
};

export default MethodsBreakdown;
