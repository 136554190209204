const ActivityIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99992 14.6673H9.99992C13.3333 14.6673 14.6666 13.334 14.6666 10.0007V6.00065C14.6666 2.66732 13.3333 1.33398 9.99992 1.33398H5.99992C2.66659 1.33398 1.33325 2.66732 1.33325 6.00065V10.0007C1.33325 13.334 2.66659 14.6673 5.99992 14.6673Z"
        stroke="#2A186A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.88672 9.65984L6.47339 7.59984C6.70005 7.30651 7.12005 7.25318 7.41339 7.47984L8.63339 8.43984C8.92672 8.66651 9.34672 8.61318 9.57339 8.32651L11.1134 6.33984"
        stroke="#2A186A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActivityIcon;
