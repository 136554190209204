import { isEmptyValue } from './isEmptyValue';

export const formatNumber = (
  number: string | number,
  options: Intl.NumberFormatOptions = { minimumFractionDigits: 0, maximumFractionDigits: 2 },
) => {
  if (isEmptyValue(number)) return '';

  let numberJoin = '';
  const [beforeDecimal, afterDecimal] = String(number).split('.');

  const fractionDigits = options?.maximumFractionDigits ?? 2;
  if (afterDecimal?.length > fractionDigits) {
    numberJoin = `${beforeDecimal}.${afterDecimal.slice(0, fractionDigits)}`;
  } else {
    numberJoin = String(number);
  }

  const formatted = new Intl.NumberFormat('en', {
    style: 'decimal',
    ...options,
  }).format(+numberJoin);

  return formatted;
};

export const formatMoneyValue = (number: number, currency: string = 'SAR') => {
  return formatNumber(number, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
