export default {
  form: {
    profileManagement: 'Profile Management',
    myInfo: 'My Info',
    myInfoOverview: 'My Info Overview',
    profilePic: 'Profile Pic',
    legalNameTitle: 'Legal Name & Title',
    designation: 'Designation',
    nationalID: 'National ID',
    phoneNumber: 'Phone Number',
    verified: 'Verified',
    verify: 'Verify',
    emailAddress: 'Email Address',
    address: 'Address',
    about: 'About',
    updateMyInfo: 'Update My Info',
  },
};
