export const UserIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.605 16.2141C12.945 16.4091 12.165 16.4991 11.25 16.4991H6.75002C5.83502 16.4991 5.05502 16.4091 4.39502 16.2141C4.56002 14.2641 6.56252 12.7266 9.00002 12.7266C11.4375 12.7266 13.44 14.2641 13.605 16.2141Z"
      stroke={stroke}
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 14.085 2.355 15.6375 4.395 16.215C4.56 14.265 6.5625 12.7275 9 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C15.645 15.6375 16.5 14.085 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5ZM9 10.6275C7.515 10.6275 6.315 9.42001 6.315 7.93501C6.315 6.45001 7.515 5.25 9 5.25C10.485 5.25 11.685 6.45001 11.685 7.93501C11.685 9.42001 10.485 10.6275 9 10.6275Z"
      stroke={stroke}
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6849 7.93501C11.6849 9.42001 10.4849 10.6275 8.99994 10.6275C7.51494 10.6275 6.31494 9.42001 6.31494 7.93501C6.31494 6.45001 7.51494 5.25 8.99994 5.25C10.4849 5.25 11.6849 6.45001 11.6849 7.93501Z"
      stroke={stroke}
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
