import { SVGProps } from 'react';
const ActiveChartSquareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M7.583 8.362H5.595a.855.855 0 0 0-.855.855v3.84h2.843V8.362v0Z"
    />
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M9.571 4.95h-1.14a.855.855 0 0 0-.855.855v7.245h2.843V5.805a.848.848 0 0 0-.848-.855ZM12.411 9.637h-1.987v3.413h2.842v-2.558a.864.864 0 0 0-.855-.855Z"
    />
    <path
      fill="#6430DA"
      fillOpacity={0.29}
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.35}
      d="M6.75 16.5h4.5c3.75 0 5.25-1.5 5.25-5.25v-4.5C16.5 3 15 1.5 11.25 1.5h-4.5C3 1.5 1.5 3 1.5 6.75v4.5C1.5 15 3 16.5 6.75 16.5Z"
    />
  </svg>
);
export default ActiveChartSquareIcon;
