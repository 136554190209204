import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';

import type { Plan } from '../types';

export const fetchPlans = async (): Promise<{ data: Plan[] }> => {
  const { data } = await axiosClient.get<{ data: Plan[] }>('/premium/plans');
  return data;
};

type UseGetPlansOptions = {
  config?: Partial<UseQueryOptions<{ data: Plan[] }>>;
};
export const useGetPlans = ({ config }: UseGetPlansOptions) => {
  return useQuery({
    queryKey: ['plans'],
    queryFn: fetchPlans,
    ...config,
  });
};
