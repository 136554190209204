export default {
  addOns: 'Add ons',
  legalAdvisor: 'Legal Advisor',
  consultationServices: 'Consultation Services',
  consultantDetails: 'Consultant Details',
  description: 'Description',
  aboutCompany: 'About Company',
  workWith: 'Work With',
  canHelpWith: 'Can Help With',
  hour: 'Hour',
  bookNow: 'Book Now',
  viewDetails: 'View Details',
  partners: 'Partners',
};
