export default {
  thankYou: 'Thank You for Listing Your Company!',
  reviewListingText: 'We will review your listing and contact you shortly.',
  listedSuccessfully: 'Listed Successfully',
  contentText:
    "To ensure a smooth and successful sale process, please keep your company's cap table and shareholder information updated and revised. Accurate and up-to-date information helps attract serious investors and facilitates a transparent transaction process",
  buttonText: 'Back to Dashboard',
  bannerTitle: 'Looking for a fundraise ?',
  bannerSubTitle: 'Easily set your terms and connect with potential investor.',
  bannerStartNow: 'Start Now',
  cancelListingModalTitle: 'You are About lose listing Opportunity',
  cancelListingModalContent: 'Are you certain about Cancel listing your company at our platform ?',
  cancelListingConfirmationText: 'Yes',
  forsaleModalContent:
    "Selling part or all of your company has never been easier. Start by providing basic details, and we'll guide you through the process",
  forsaleModalCancelButton: 'Dismiss',
  forsaleModalOKButton: 'Yes, Interested',
  cancelListingReasonSubtitle:
    'Please let us know why you decided to cancel your listing. Your feedback helps us improve our services.',
  cancelListingReason: 'Reason for Cancellation ',
  additionalComment: 'Additional Comments',
  reasonModalOKButton: 'Submit Feedback',
  typeOfListing: 'Type of listing',
  exitSell: 'Exit - Sell All company',
  needInvestmentRound: 'Need investment round',
  sellEquity: 'Sell Equity / percentage of company',
  listYourCompany: 'List Your Company for Sale on our Platform',
  startListing: 'Start Your Listing',
  dataSharingConsent: 'Data Sharing Consent',
  termsAndConditions: 'Terms and Conditions',
  companyLogo: 'Company logo',
  companyName: 'Comapny Name',
  description: 'Description',
  industry: 'Industry',
  modalTitle: "We're Sorry to See You Go",
  previous: 'Previous',
  next: 'Next',
  confirm: 'Confirm',
  submit: 'Submit',
  notNeedToSellMyCompany: 'Not Need to sell my company',
  changedMyMind: 'Changed My Mind',
  concernsAboutPrivacy: 'Concerns About Privacy',
  notReadyToSell: 'Not Ready to Sell',
  otherReason: 'Other Reason',
  additionalComments: 'Additional Comments',
  priefDesc: 'Describe the problem your startup is solving and your approach to solving it.',
  productStage: 'Product Stage',
  pitchDeck: 'Pitch Deck',
  pleaseAddValue: 'Please Add Value',
  optional: 'Optional',
  cancelListing: 'Cancel Listing',
};
