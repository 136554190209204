import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { Form } from 'antd';
export const StyledForm = styled(Form)`
  padding: ${themeToken.paddingLG}px;
  border-radius: ${themeToken.paddingXXS}px;
  background-color: ${themeToken.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: fit-content;
  /* Box shadow added by me because i was getting blinded working on white background form with white background body */
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @media (min-width: ${themeToken.screenMD}px) {
    max-width: 400px;
    min-width: 400px;
    padding: ${themeToken.paddingXL}px ${themeToken.paddingXXL}px;
  }
`;
