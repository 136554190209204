import { ReactNode } from 'react';
import { Modal } from 'antd';

type Props = {
  title?: string;
  isModalOpen: boolean;
  handleCancel: () => void;
  handleOk?: () => void;
  closeIcon?: boolean;
  children: ReactNode;
};

const CommonModal = ({ title, isModalOpen, handleCancel, handleOk, children, closeIcon = true }: Props) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk ?? handleCancel}
      onCancel={handleCancel}
      width={720}
      footer={null}
      centered
      closeIcon={closeIcon}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
