import { useMutation } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';

import type { CreateAnalyticsPayload, CreateAnalyticsResponse } from '../types';

export const createAnalytics = ({ company_id, payload }: { company_id: number; payload: CreateAnalyticsPayload }) => {
  const reqURL = payload.hash ? '/public/data-rooms/analytics' : `/companies/${company_id}/data-rooms/analytics`;
  return axiosClient.put<CreateAnalyticsResponse>(reqURL, { ...payload }).then((res) => res.status);
};

export const useCreateAnalytics = () => {
  return useMutation({
    mutationFn: createAnalytics,
  });
};
