import { Radio } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  dotSize: themeToken.sizeXS,
};

const globalTheme = {};

export const radioTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    & > .ant-radio {
      &.ant-radio-checked {
        & > .ant-radio-inner {
          background-color: ${themeToken.white};
          &::after {
            background-color: ${themeToken.colorPrimary};
          }
        }
      }
    }
  }
`;
