const ChartEmptyState = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <path
          fill="#A5B4FC"
          fillRule="nonzero"
          d="M61.754375,57.324625 L8.9825,57.324625 C7.123,57.322625 5.616125,55.81575 5.614125,53.95625 L5.614125,1.18425 C5.613375,0.5645 5.111125,0.06225 4.491375,0.0615 L1.12275,0.0615 C0.503,0.06225 0.00075,0.5645 0,1.18425 L0,61.815875 C0.00075,62.435625 0.503,62.937875 1.12275,62.938625 L61.754375,62.938625 C62.374125,62.937875 62.876375,62.435625 62.877125,61.815875 L62.877125,58.4475 C62.876375,57.827625 62.374125,57.325375 61.754375,57.324625 Z"
        />
        <path
          stroke="#C7D2FE"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M11.6557591,47.3346608 L21.2691659,29.0720612 C22.2981913,27.1172205 24.7170926,26.366699 26.6719332,27.3957243 C26.674392,27.3970186 26.6768495,27.3983155 26.6793056,27.3996149 L45.1268304,37.1595173 C47.0795223,38.1926143 49.4999825,37.4471353 50.5330795,35.4944434 C50.5990946,35.3696659 50.6584582,35.2414813 50.710921,35.1104276 L60.2302659,11.3308051 L60.2302659,11.3308051"
        />
      </g>
    </svg>
  );
};

export default ChartEmptyState;
