import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Grid, Image, Typography } from 'antd';

import { useGetCompanies } from '@/features/company/api/getCompanies';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import themeToken from '@lib/theme/tokens/index';

import AddIcon from '../Icons/AddIcon';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '../Icons/SettingsIcon';
import { StyledCompanyImageContainer } from './components/Sidebar/Companies/ImageContainer';
import { CompaniesSidebarContainer } from './components/Sidebar/Companies/Wrapper';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { setCookie } from '@/utils/storage';
import { CellSkeleton } from '../Shared/CellSkeleton';
import { useUserDataStore } from '@/stores/UserData';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;
type Props = {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
};

const CompaniesSidebar = ({ isSidebarCollapsed, setIsSidebarCollapsed }: Props) => {
  const { data: companiesList, isLoading: isCompaniesListLoading } = useGetCompanies({});
  const { selectedCompany, setSelectedCompany, setCompaniesList } = useSelectedCompanyStore((state) => state);
  const { userData } = useUserDataStore((state) => state);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('common');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { sendMixpanelEvent } = useTrackEvent({ isGeneralEvent: true });

  useEffect(() => {
    if (companiesList && companiesList.length > 0 && !selectedCompany?.cid) {
      const [fallbackCompany] = companiesList;
      const currentCompany = companiesList.find((company) => company.cid === userData?.userInfo.current_company);
      const company = currentCompany ?? fallbackCompany;
      if (pathname !== '/company/new') {
        setSelectedCompany(company);
        navigate(company.is_owner ? '/company/dashboard' : '/company/investment-overview');
      }
    }
    if (companiesList && !companiesList.length) {
      navigate('/company/new');
    }
  }, [companiesList, selectedCompany]);

  useEffect(() => {
    if (companiesList && companiesList.length) {
      setCompaniesList(companiesList);
    }
  }, [companiesList]);

  return (
    <CompaniesSidebarContainer vertical gap={24}>
      <Flex
        vertical
        gap={themeToken.size}
        style={{
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        {isCompaniesListLoading
          ? Array(4)
              .fill(0)
              .map((_, index) => (
                <StyledCompanyImageContainer key={index} align="center" justify="center">
                  <CellSkeleton block={false} shape="square" width="36px" height="36px" />
                </StyledCompanyImageContainer>
              ))
          : companiesList?.length && companiesList?.length > 0
            ? companiesList.map((company) => (
                <StyledCompanyImageContainer
                  vertical
                  key={company.cid}
                  className={company.cid === selectedCompany?.cid ? 'is-selected' : ''}
                  align="center"
                  justify="center"
                  onClick={() => {
                    sendMixpanelEvent('current-company-changed', {
                      from: selectedCompany?.cid,
                      to: company.cid,
                    });
                    setSelectedCompany(company);
                    setCookie('selected_company', JSON.stringify(company));
                    if (company.is_owner) navigate('/company/dashboard');
                    else navigate('/company/investment-overview');
                  }}
                >
                  <Image
                    preview={false}
                    src={company.logo}
                    alt={company.name}
                    width={screens.lg ? 32 : 36}
                    height={screens.lg ? 32 : 36}
                    placeholder
                  />
                </StyledCompanyImageContainer>
              ))
            : null}
      </Flex>
      <Flex
        vertical
        justify="center"
        align="center"
        gap={16}
        style={{
          cursor: 'pointer',
        }}
      >
        <Flex
          vertical
          justify="center"
          align="center"
          gap={4}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            sendMixpanelEvent('companies-add-more-btn-clicked');
            if (!screens.xl) {
              setIsSidebarCollapsed(!isSidebarCollapsed);
            }
            navigate('/company/new');
          }}
        >
          <AddIcon />
          <Text
            style={{
              fontWeight: 300,
              fontSize: `${themeToken.fontSizeXSM}px`,
              color: themeToken['branding-secondary-6'],
            }}
          >
            {t('addMore')}
          </Text>
        </Flex>
        {false && (
          <Flex
            vertical
            justify="center"
            align="center"
            gap={3}
            style={{
              cursor: 'pointer',
            }}
          >
            <SettingsIcon />
            <Text
              style={{
                fontWeight: 300,
                fontSize: `${themeToken.fontSizeXSM}px`,
                color: themeToken['branding-natural-6'],
              }}
            >
              {t('settings')}
            </Text>
          </Flex>
        )}
      </Flex>
    </CompaniesSidebarContainer>
  );
};

export default CompaniesSidebar;
