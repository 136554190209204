import login from '@/features/auth/locales/en/login';
import common from './common';
import layout from './layout';
import captable from './captable';
import signup from '@/features/auth/locales/en/signup';
import confirmAccount from '@/features/auth/locales/en/confirmAccount';
import valuation from './valuation';
import modeling from './modeling';
import dataroom from './dataroom';
import usersManagement from './usersManagement';
import company from './company';
import profile from './profile';
import plans from './plans';
import consultation from './consultation';
import companyListing from './companyListing';
import pageTitles from './pageTitles';
import documentGenerator from './documentGenerator';
import companyReviews from './companyReviews';

export default {
  login,
  common,
  signup,
  confirmAccount,
  layout,
  captable,
  valuation,
  modeling,
  dataroom,
  usersManagement,
  company,
  profile,
  plans,
  consultation,
  companyListing,
  pageTitles,
  documentGenerator,
  companyReviews,
};
