import { DatePicker } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  paddingInline: themeToken.paddingSM,
};

const globalTheme = {
  borderRadius: themeToken.borderRadiusSM,
};

export const datePickerTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    width: 100%;
  }
`;
