export default {
  thankYou: 'شكرا لطلبك عرض شركتك!',
  reviewListingText: 'سنراجع طلبك ونرد عليك في اقرب وقت',
  listedSuccessfully: 'تم رفع الطلب بنجاح',
  contentText:
    'لضمان عملية استثمار سلسة وناجحة، يرجى تحديث ومراجعة جدول الملكية ومعلومات المساهمين في شركتك بانتظام. المعلومات الدقيقة والمحدثة تساعد في جذب المستثمرين الجادين وتسهيل عملية المعاملة بشفافية.',
  buttonText: 'عودة للصفحة العامة',
  bannerTitle: 'هل تبحث عن فرصة استثمارية؟',
  bannerSubTitle: 'اكمل بياناتاك وتواصل مع مستثمرين محتملين',
  bannerStartNow: 'ابدأ',
  cancelListingModalTitle: 'انت على وشك فقدان فرصة ظهورك',
  cancelListingModalContent: 'هل انت متأكد من إلغاء ظهورك للمستثمرين؟',
  cancelListingConfirmationText: 'نعم',
  forsaleModalContent:
    'البحث عن مستثمر لشركتك أصبح أسهل من أي وقت مضى. سواء كنت تحتاج إلى جولة استثمارية أو تخارج. ابدأ بتقديم بعض التفاصيل الأساسية، وسنرشدك خلال العملية بالكامل.',
  forsaleModalCancelButton: 'تجاهل',
  forsaleModalOKButton: 'نعم, مهتم',
  cancelListingReasonSubtitle: 'الرجاء مشاركة سبب الغاءك, تعاونك يساعدنا على تحسن الخدمة ',
  cancelListingReason: 'سبب الإلغاء ',
  additionalComment: '',
  reasonModalOKButton: 'ارسل الملاحظات',
  typeOfListing: 'نوع البحث',
  exitSell: 'تخارج - بيع كامل الشركة',
  needInvestmentRound: 'جولة استثمارية',
  sellEquity: 'تخارج جزئي - بيع حصة من الشركة',
  listYourCompany: 'اظهر شركتك لمستثمرين محتملين',
  startListing: 'معلومات الشركة',
  dataSharingConsent: 'موافقة مشاركة البيانات',
  termsAndConditions: 'الشروط والاحكام ',
  companyLogo: 'شعار الشركة',
  companyName: 'اسم الشركة',
  description: 'وصف الشركة',
  industry: 'المجال',
  modalTitle: 'سنفتقدك',
  previous: 'السابق',
  next: 'التالي',
  confirm: 'تاكيد',
  submit: 'ارسال',
  notNeedToSellMyCompany: 'لست بحاجة لبيع الشركة',
  changedMyMind: 'غيرت رأيي',
  concernsAboutPrivacy: 'تحفظ تجاه الخصوصية',
  notReadyToSell: 'لست مستعدا للبيع',
  otherReason: 'اسباب أخرى',
  additionalComments: 'اسباب اضافية',
  priefDesc: '',
  productStage: '',
  pleaseAddValue: 'من فضلك ادخل القيمة',
  selectValue: 'من فضلك اختر القيمة',
  optional: 'اختياري',
  cancelListing: 'الغاء',
};
