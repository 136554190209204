import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthorizedRoute from '@/components/AuthorizedRoute';
import ExpiryChecker from '@/components/ExpiryChecker';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const InvestmentRound = lazy(() => import('../views/InvestmentRound'));
const ExitModeling = lazy(() => import('../views/ExitModeling'));

export const ScenarioModelingRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute allowedPermissions={['company.modeling', 'company.modeling.round']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="investment-round" element={<InvestmentRound />} />
          </Route>
        </Route>
        <Route
          element={
            <AuthorizedRoute
              feature="modeling.exit"
              allowedPermissions={['company.modeling', 'company.modeling.exit']}
            />
          }
        >
          <Route element={<ExpiryChecker />}>
            <Route path="exit-modeling" element={<ExitModeling />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
