import { useEffect, useState } from 'react';
import { Flex, Typography, FormInstance } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { UploadFile, RcFile } from 'antd/lib/upload';

import { ImageAddIcon } from '@/components/Icons/ImageAddIcon';
import { StyledUpload } from '@/lib/theme/components/Upload';
import themeToken from '@lib/theme/tokens/index';

import { UseUploadCompanyLogo } from '../api/uploadCompanyLogo';

const { Text } = Typography;

type Props = {
  currLogo?: string;
  disabled?: boolean;
  form: FormInstance;
};

const CompanyLogoUpload = ({ currLogo, form, disabled }: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { mutate, isPending } = UseUploadCompanyLogo({
    postSuccess: (url) => {
      form.setFieldsValue({ logo: url });
      setFileList((prev) => [{ ...prev[0], uid: '1', url, status: 'done' }]);
    },
  });

  const beforeUpload = async (file: RcFile) => {
    const { name } = file;
    const modifiedFile = new File([file], name.replace(/\s/g, ''), { type: file.type });
    setFileList([{ uid: '-1', name: name.replace(/\s/g, ''), status: 'uploading' }]);
    mutate({ file: modifiedFile });

    return file;
  };

  const handleLogoChange = ({ file }: { file: UploadFile }) => {
    const { status } = file;
    if (status === 'removed') {
      form.setFieldValue('logo', '');
      setFileList([]);
    } else setFileList((prev) => [{ ...prev[0], status: 'done' }]);
  };

  useEffect(() => {
    if (currLogo) {
      setFileList([
        {
          name: 'current logo',
          uid: '1',
          url: currLogo,
          status: 'done',
        },
      ]);
    }
  }, [currLogo]);

  return (
    <ImgCrop>
      <StyledUpload
        maxCount={1}
        listType="picture-card"
        beforeUpload={beforeUpload}
        onChange={handleLogoChange}
        customRequest={() => null}
        fileList={fileList}
        accept="image/*"
        showUploadList={{ showPreviewIcon: false }}
        disabled={isPending || disabled}
      >
        {fileList.length < 1 && (
          <Flex align="center" gap={8} vertical>
            <ImageAddIcon />
            <Text
              style={{
                color: themeToken['branding-natural-4'],
                fontSize: themeToken.fontSizeXL,
              }}
            >
              Upload
            </Text>
          </Flex>
        )}
      </StyledUpload>
    </ImgCrop>
  );
};

export default CompanyLogoUpload;
