import { Company, CompanyData } from '@/features/company/types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface SelectedCompanyState {
  selectedCompany: Company | null;
  companiesList: Company[];
  fullCompanyData: CompanyData | null;
  isPremiumPlan: boolean;
  expiresAt: string | null;
  setIsPremiumPlan: (isPremiumPlan: boolean) => void;
  setSelectedCompany: (company: Company | null) => void;
  setCompaniesList: (company: Company[]) => void;
  setFullComapnyData: (company: CompanyData | null) => void;
  setExpiresAt: (expiresAt: string | null) => void;
}

export const useSelectedCompanyStore = create<SelectedCompanyState>()(
  devtools(
    persist(
      (set) => ({
        selectedCompany: null,
        companiesList: [],
        fullCompanyData: null,
        isPremiumPlan: false,
        expiresAt: null,
        setIsPremiumPlan: (isPremiumPlan) => set(() => ({ isPremiumPlan })),
        setSelectedCompany: (selectedCompany) => set(() => ({ selectedCompany })),
        setCompaniesList: (companiesList) => set(() => ({ companiesList })),
        setFullComapnyData: (fullCompanyData) => set(() => ({ fullCompanyData })),
        setExpiresAt: (expiresAt) => set(() => ({ expiresAt })),
      }),
      { name: 'selectedCompanyStore' },
    ),
  ),
);
