const AddOnsIcon = ({
  active,
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  active?: boolean;
  width?: number;
  height?: number;
  stroke?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={active ? '#6430DA4A' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97503 6.08172L10.965 8.06172C11.1 8.33172 11.46 8.60172 11.76 8.64672L13.5525 8.94671C14.7 9.14171 14.97 9.96672 14.145 10.7917L12.75 12.1867C12.5175 12.4192 12.3825 12.8767 12.4575 13.2067L12.855 14.9392C13.17 16.3042 12.4425 16.8367 11.235 16.1242L9.55503 15.1267C9.24753 14.9467 8.75254 14.9467 8.44504 15.1267L6.76503 16.1242C5.55753 16.8367 4.83002 16.3042 5.14502 14.9392L5.54255 13.2067C5.61755 12.8842 5.48254 12.4267 5.25004 12.1867L3.85505 10.7917C3.03005 9.96672 3.30003 9.13421 4.44753 8.94671L6.24004 8.64672C6.54004 8.59422 6.90004 8.33172 7.03504 8.06172L8.02504 6.08172C8.55754 5.00922 9.44253 5.00922 9.97503 6.08172Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.50002 6.75V1.5" stroke={stroke} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 6.75V1.5" stroke={stroke} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00002 3V1.5" stroke={stroke} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddOnsIcon;
