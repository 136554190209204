import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { Permissions } from '@/types/permissions';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';

const AuthorizedRoute = ({ allowedPermissions, feature }: { allowedPermissions: Permissions[]; feature?: string }) => {
  const { fullCompanyData, companiesList } = useSelectedCompanyStore((state) => state);
  const { setPermissions, permissions } = usePermissionsStore((state) => state);
  const premiumFeatures = useMemo(() => {
    const features =
      fullCompanyData?.current_plan?.features
        ?.filter(({ premium }) => premium)
        .map(({ key, included, premium }) => ({ key, included, premium })) || [];
    return features;
  }, [fullCompanyData]);

  const shouldPassPlanChecks = () => {
    let shouldPass = false;
    allowedPermissions.forEach((permission) => {
      const premiumFeature = premiumFeatures.find((feat) => feat.key === permission);
      if (!premiumFeature) shouldPass = true;
      else if (premiumFeature && !premiumFeature.included) shouldPass = false;
      else shouldPass = true;
    });
    return shouldPass;
  };

  const location = useLocation();

  useEffect(() => {
    if (fullCompanyData) {
      setPermissions(fullCompanyData.user_permissions);
    }
  }, [fullCompanyData]);

  if (!companiesList.length) return <Navigate to="/company/new" replace />;
  if (!permissions.length) return <Outlet />;
  if (allowedPermissions.every((permission) => permissions.includes(permission))) {
    if (shouldPassPlanChecks()) return <Outlet />;
    else return <Navigate to={`/go-premium/upgrade?feature=${feature}`} state={{ from: location }} replace />;
  } else return <Navigate to="/go-premium/no-access" state={{ from: location }} replace />;
};

export default AuthorizedRoute;
