import { Route, Routes } from 'react-router-dom';
import SignUp from '../views/SignUp';
import AuthLayout from '../components/AuthLayout';
import SignIn from '../views/SignIn';
import ConfirmAccount from '../views/ConfirmAccount';
import SocialAuthenticate from '../views/SocialAuthenticate';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="signup" element={<SignUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="confirm-account" element={<ConfirmAccount />} />
        <Route path="social-authenticate" element={<SocialAuthenticate />} />
      </Route>
    </Routes>
  );
};
