import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthorizedRoute from '@/components/AuthorizedRoute';
import LimitsChecker from '@/components/LimitsChecker';
import ExpiryChecker from '@/components/ExpiryChecker';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const DataRoomsMain = lazy(() => import('../views/DataRoomsMain'));
const SharedLogs = lazy(() => import('../views/SharedLogs'));
const Analytics = lazy(() => import('../views/Analytics'));

export const DataRoomsRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute allowedPermissions={['company.dataroom', 'company.dataroom.main']} />}>
          <Route
            element={
              <LimitsChecker
                feature="dataroom"
                limitKeys={[
                  'limits.company.dataroom.folders',
                  'limits.company.dataroom.files',
                  'limits.company.dataroom.shares',
                ]}
              />
            }
          >
            <Route path="main" element={<DataRoomsMain />} />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute allowedPermissions={['company.dataroom', 'company.dataroom.shared-logs']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="shared-logs" element={<SharedLogs />} />
          </Route>
        </Route>
        <Route
          element={
            <AuthorizedRoute
              feature="dataroom.analytics"
              allowedPermissions={['company.dataroom', 'company.dataroom.analytics']}
            />
          }
        >
          <Route element={<ExpiryChecker />}>
            <Route path="analytics" element={<Analytics />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
