const SearchIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25031 15C11.9782 15 15.0003 11.9779 15.0003 8.25C15.0003 4.52208 11.9782 1.5 8.25031 1.5C4.52238 1.5 1.50031 4.52208 1.50031 8.25C1.50031 11.9779 4.52238 15 8.25031 15Z"
        stroke="#756D9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1975 15.5174C14.595 16.7174 15.5025 16.8374 16.2 15.7874C16.8375 14.8274 16.4175 14.0399 15.2625 14.0399C14.4075 14.0324 13.9275 14.6999 14.1975 15.5174Z"
        stroke="#756D9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
