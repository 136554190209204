import { UIEvent, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, Form, Skeleton, Typography } from 'antd';
import { DirectoryTreeProps } from 'antd/es/tree';

import themeToken from '@lib/theme/tokens/index';
import CommonModal from '@/features/captable/components/Shared/CommonModal';
import { StyledButton } from '@/lib/theme/components/Button';
import { StyledForm } from '@/components/Shared/Form';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { StyledDirectoryTree } from '@/lib/theme/components/Tree';
import { LimitsCheckerContext } from '@/components/LimitsChecker';

import FilesIcons from './FilesIcons';
import { useGetNestedFolders } from '../api/getNestedFolders';
import { convertToTreeData } from '../utils/convertToTreeData';
import { StyledDirectoryCard } from './StyledDirectoryCard';
import { useCopyFile } from '../api/copyFile';
import type { CopyFilePayload, NestedFolders } from '../types';

const { Text } = Typography;

type Props = { dataroomIds: number[]; isCopyFileOpen: boolean; handleCancel: () => void };

const CopyFileModal = ({ dataroomIds, isCopyFileOpen, handleCancel }: Props) => {
  const { recheckLimits } = useOutletContext<LimitsCheckerContext>() || {};
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [directoryListOptions, setDirectoryListOptions] = useState<NestedFolders[]>([]);
  const [directoryListPage, setDirectoryListPage] = useState(1);
  const { data: nestedFolders, isLoading: isNestedFoldersLoading } = useGetNestedFolders({
    companyID: selectedCompany?.cid as number,
    queryParams: {
      page: directoryListPage,
    },
  });
  const [nestedFoldersHasNextPage, setNestedFoldersHasNextPage] = useState(true);

  const onDirectoryScroll = (event: UIEvent<HTMLElement>) => {
    const target = event.currentTarget;

    if (
      nestedFoldersHasNextPage &&
      !isNestedFoldersLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setDirectoryListPage((prev) => prev + 1);
      target.scrollTo(0, target.scrollHeight);
    }
  };

  useEffect(() => {
    const totalRecords = nestedFolders?.metadata.total ?? 0;
    const pageSize = nestedFolders?.metadata.size ?? 10;
    const hasNextPage = Math.ceil(totalRecords / pageSize) > directoryListPage;

    setNestedFoldersHasNextPage(hasNextPage);

    setDirectoryListOptions((prev) => {
      const dirFolders = [...prev];
      const newRecords = nestedFolders?.data || [];

      newRecords.forEach((rec) => {
        if (!dirFolders.some((item) => item.id === rec.id)) dirFolders.push(rec);
      });
      return dirFolders;
    });
  }, [nestedFolders, directoryListPage]);

  const { t } = useTranslation('dataroom');
  const [form] = Form.useForm();
  const { mutate: copyFile, isPending: isCopyFilePending } = useCopyFile({
    postSuccess() {
      handleCancel();
      recheckLimits();
      form.resetFields();
    },
  });

  const directoryTreeData = useMemo(() => {
    return directoryListOptions.map((el) => convertToTreeData(el));
  }, [directoryListOptions]);

  const onFinish = (values: CopyFilePayload) => {
    const body = {
      fileIds: dataroomIds,
      destinationFolderId: values.destinationFolderId,
    };
    copyFile({
      companyID: selectedCompany?.cid as number,
      body,
    });
  };

  const onSelect: DirectoryTreeProps['onSelect'] = (keys) => {
    form.setFieldsValue({
      destinationFolderId: keys[0],
    });
  };

  return (
    <CommonModal isModalOpen={isCopyFileOpen} handleCancel={handleCancel}>
      <Flex gap={14}>
        <FilesIcons mimeType="folder" />

        <Text
          style={{
            color: themeToken['branding-primary-6'],
            fontWeight: 600,
            fontSize: themeToken.fontSizeLG,
            marginBottom: themeToken.marginXXS,
          }}
        >
          {t('chooseFolderToCopyTo')}
        </Text>
      </Flex>

      <Divider
        style={{
          marginBlock: themeToken.margin,
          borderColor: themeToken['branding-natural-3'],
        }}
      />

      <StyledForm form={form} layout="vertical" onFinish={onFinish} disabled={isCopyFilePending}>
        <Flex
          align="start"
          vertical
          style={{
            flex: 1,
          }}
        >
          <Form.Item name="destinationFolderId" label={t('copyTo')}>
            <StyledDirectoryCard>
              {isNestedFoldersLoading ? (
                <Skeleton
                  paragraph={{
                    rows: 8,
                  }}
                />
              ) : (
                <StyledDirectoryTree
                  height={250}
                  onSelect={onSelect}
                  treeData={directoryTreeData}
                  onScroll={onDirectoryScroll}
                />
              )}
            </StyledDirectoryCard>
          </Form.Item>
          <Divider
            style={{
              marginBlock: themeToken.margin,
              borderColor: themeToken['branding-natural-3'],
            }}
          />
          <Flex style={{ width: '100%' }} justify="space-between">
            <StyledButton
              ghost
              type="primary"
              onClick={() => {
                handleCancel();
                form.resetFields();
              }}
            >
              {t('back')}
            </StyledButton>
            <StyledButton type="primary" htmlType="submit">
              {t('copyHere')}
            </StyledButton>
          </Flex>
        </Flex>
      </StyledForm>
    </CommonModal>
  );
};

export default CopyFileModal;
