import styled from 'styled-components';
import dots from '../../../assets/auth/bg-dots.webp';
import leftFrame from '../../../assets/auth/left-bg-frame.webp';
import rightFrame from '../../../assets/auth/right-bg-frame.webp';

import themeToken from '@lib/theme/tokens/index';
import logo from '../../../assets/main-english-logo.svg';
import { Flex, Grid } from 'antd';
import MicrosoftIcon from '../../../components/Icons/MicrosoftIcon';
import GoogleIcon from '../../../components/Icons/GoogleIcon';
import {
  StyledContainer,
  StyledDivider,
  StyledForm,
  StyledFormItem,
  StyledLeftDotsImage,
  StyledLink,
  StyledLogoImage,
  StyledRightDotsImage,
  StyledSocialButton,
  StyledSubmitButton,
  StyledText,
  StyledTitle,
} from '../components';
import { useEffect, useState } from 'react';
import type { FormRule } from 'antd';
import { PhoneCodesSelect } from '../../../components/Shared/PhoneCodesSelect';
import { StyledInputGroup } from '../components/SignIn/InputGroup';
import Carousel from '../components/Shared/Carousel';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '@lib/theme/components/Button';
import { handleChangeLanguage } from '../../../utils/handleChangeLanguage';
import { handleOpenSocialLoginPopup } from '../utils/handleOpenSocialLoginPopup';
import { useNavigate } from 'react-router-dom';
import { usePhoneSignInUser } from '../api/signin/phoneSignin';
import { useEmailSignInUser } from '../api/signin/emailSignin';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

type FieldType = {
  fullname: string;
  phoneNumber: number;
  email: string;
};

const StyledWrapper = styled.div`
  height: auto;
  display: flex;
  width: 100%;
`;

const SignIn = () => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'sign-in' });
  const { useBreakpoint } = Grid;
  const [inputValue, setInputValue] = useState('');
  const [currentCountryCode, setCurrentCountryCode] = useState('');
  const [isEmail, setIsEmail] = useState(true);
  const [inputRules, setInputRules] = useState<FormRule[]>([]);
  const { useForm, useWatch } = StyledForm;
  const [form] = useForm();
  const values = useWatch('phoneNumber', form) as string | undefined;
  const [submittable, setSubmittable] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['login', 'common']);

  const { t: translate } = useTranslation('pageTitles');

  useDocumentTitle(translate('login'));

  const screens = useBreakpoint();

  useEffect(() => {
    if (inputValue && isNaN(parseFloat(inputValue))) {
      setIsEmail(true);
      setInputRules([
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ]);
    }
    if (inputValue && !isNaN(parseFloat(inputValue))) {
      setIsEmail(false);
      setInputRules([
        {
          required: true,
          message: 'Please input your phone number!',
        },
      ]);
    }
  }, [inputValue]);

  const { mutate: signInWithPhone, isPending: isPhoneConfirmLoading } = usePhoneSignInUser({
    handleAfterSuccess: () => {
      sendMixpanelEvent('phone-login', {}, { status: 'success', req_path: '/auth/phone-login' });
      navigate(`/auth/confirm-account?method=signin&phone=${currentCountryCode}${inputValue}&otp_receiver=sms`);
    },
    handleAfterFailure: (code) => {
      sendMixpanelEvent('phone-login', { statusCode: code }, { status: 'fail', req_path: '/auth/phone-login' });
    },
  });

  const { mutate: signInWithEmail, isPending: isEmailConfirmLoading } = useEmailSignInUser({
    handleAfterSuccess: () => {
      sendMixpanelEvent('email-login', {}, { status: 'success', req_path: '/auth/email-login' });
      navigate(`/auth/confirm-account?method=signin&email=${encodeURIComponent(inputValue)}&otp_receiver=email`);
    },
    handleAfterFailure: (code) => {
      sendMixpanelEvent('email-login', { statusCode: code }, { status: 'fail', req_path: '/auth/email-login' });
    },
  });

  const handleFormSubmit = (formValues: { [key: string]: string }) => {
    sendMixpanelEvent('login-btn-clicked', { method: isEmail ? 'email' : 'phone' });

    if (isEmail) {
      signInWithEmail(formValues.phoneNumber);
    } else {
      signInWithPhone(`${currentCountryCode}${formValues.phoneNumber}`);
    }
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values, form]);

  return (
    <StyledContainer>
      <StyledLeftDotsImage
        src={screens.md ? leftFrame : dots}
        width={screens.md ? 192 : 55}
        height={screens.md ? 80 : 180}
      />
      <StyledWrapper
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '36px',
          zIndex: 1,
        }}
      >
        <StyledWrapper>
          {screens.md && <Carousel />}
          <StyledForm form={form} onFinish={handleFormSubmit}>
            <StyledLogoImage width={86} height={30} src={logo} alt="logo" />

            <StyledText color={themeToken['branding-natural-6']}>{t('welcomeBack')}</StyledText>
            <StyledTitle level={screens.md ? 3 : 4} color={themeToken['branding-natural-9']} rootClassName="title">
              {t('loginToYourAccount')}
            </StyledTitle>

            <StyledFormItem<FieldType> name="phoneNumber" rules={inputRules} rootClassName="form-item">
              <StyledInputGroup
                classNames={isEmail ? 'is-email' : ''}
                rootClassName="input-container"
                addonBefore={<PhoneCodesSelect isVisible={!isEmail} setCurrentCountryCode={setCurrentCountryCode} />}
                size="large"
                placeholder={isEmail ? t('common:emailAddress') : t('common:phoneNumber')}
                type={isEmail ? 'email' : 'text'}
                data-qa="login-form__input-field"
                onChange={(e) => {
                  setInputValue(e?.target?.value);
                }}
              />
            </StyledFormItem>

            <StyledSubmitButton
              loading={isPhoneConfirmLoading || isEmailConfirmLoading}
              disabled={!submittable}
              data-qa="login-form__submit-btn"
              type="primary"
              size="large"
              htmlType="submit"
              style={{
                marginTop: 0,
                marginBottom: 24,
              }}
            >
              {t('common:confirm')}
            </StyledSubmitButton>
            <Flex wrap="wrap">
              <StyledText color={themeToken['neutral-color-white-black-400']}>
                {t('dontHaveAnAccountYet')}&nbsp;
              </StyledText>
              <StyledLink to="/auth/signup">{t('createAccount')}</StyledLink>
            </Flex>
            <Flex
              vertical
              style={{
                paddingInline: screens.md ? '0' : '25px',
                width: '100%',
              }}
            >
              <StyledDivider>{t('common:auth:orContinueUsing')}</StyledDivider>

              <Flex
                vertical
                gap={12}
                style={{
                  width: '100%',
                }}
              >
                <StyledSocialButton
                  onClick={() => {
                    sendMixpanelEvent('microsoft-btn-clicked');
                    handleOpenSocialLoginPopup('microsoft');
                  }}
                >
                  <MicrosoftIcon /> {t('common:microsoftAccount')}
                </StyledSocialButton>
                <StyledSocialButton
                  onClick={() => {
                    sendMixpanelEvent('google-btn-clicked');
                    handleOpenSocialLoginPopup('google');
                  }}
                >
                  <GoogleIcon />
                  {t('common:googleAccount')}
                </StyledSocialButton>
              </Flex>
            </Flex>
          </StyledForm>
        </StyledWrapper>
        <Flex>
          <StyledButton
            size="small"
            type="link"
            style={{
              color: themeToken.colorPrimary,
            }}
            onClick={() => {
              handleChangeLanguage('ar');
            }}
          >
            العربية
          </StyledButton>
          <StyledButton
            size="small"
            type="link"
            style={{
              color: themeToken.colorPrimary,
            }}
            onClick={() => {
              handleChangeLanguage('en');
            }}
          >
            English(US)
          </StyledButton>
        </Flex>
      </StyledWrapper>
      <StyledRightDotsImage
        src={screens.md ? rightFrame : dots}
        width={screens.md ? 'auto' : 55}
        height={screens.md ? 'auto' : 180}
      />
    </StyledContainer>
  );
};

export default SignIn;
