import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

const GradientTitle = styled(Title)`
  background: -webkit-linear-gradient(46deg, #6430da -5.18%, #e55f90 58.96%, #d81059 113.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default GradientTitle;
