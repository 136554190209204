import axiosClient from '@/lib/axios';
import { GenerateFileUrlResponse } from '../types';
import { useQuery } from '@tanstack/react-query';

// FE cached refresh time === access time ?
export const generateFileUrl = ({
  company_id,
  file_id,
  uuid,
}: {
  company_id: number;
  file_id: number;
  uuid?: string;
}) => {
  const reqURL = uuid
    ? `/public/data-rooms/${uuid}/files/${file_id}/generate-url`
    : `/companies/${company_id}/data-rooms/files/${file_id}/generate-url`;
  return axiosClient.get<GenerateFileUrlResponse>(reqURL).then((res) => res.data);
};

export const generateUrlQueryClientParams = ({
  company_id,
  file_id,
  uuid,
}: {
  company_id: number;
  file_id: number;
  uuid?: string;
}) => ({
  queryKey: ['generate_url', file_id],
  queryFn: () => generateFileUrl({ company_id, file_id, uuid }),
});

export const useGenerateFileUrl = ({
  company_id,
  file_id,
  uuid,
}: {
  company_id: number;
  file_id: number;
  uuid?: string;
}) => {
  return useQuery(generateUrlQueryClientParams({ company_id, file_id, uuid }));
};
