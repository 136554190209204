const DashboardIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.61501 2.1267L2.57251 5.2767C1.89751 5.8017 1.35001 6.9192 1.35001 7.7667V13.3242C1.35001 15.0642 2.76751 16.4892 4.50751 16.4892H13.1925C14.9325 16.4892 16.35 15.0642 16.35 13.3317V7.8717C16.35 6.9642 15.7425 5.8017 15 5.2842L10.365 2.0367C9.31501 1.3017 7.62751 1.3392 6.61501 2.1267Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.54985 13.4893V11.2393"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
