import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import { Checkbox, Flex, List, Tag, Typography } from 'antd';

import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import SharedModal from '@/components/Shared/SharedModal';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import themeToken from '@lib/theme/tokens/index';
import { usePermissionsStore } from '@/stores/Permissions';

import type { FolderData, FoldersList, ModalName } from '../types';

import FilePreview from './FilePreview';
import LoadingList from './LoadingList';
import ShareFileForm from './ShareFileForm';
import EditFileForm from './EditFileForm';
import CreateNewFolderForm from './CreateNewFolderForm';
import { useCreateAnalytics } from '../api/createAnalytics';
import { useDeleteFile } from '../api/deleteFile';
import ListItem from './ListItem';
import FileShare from './FileShare';
import CopyFileModal from './CopyFileModal';

const { Text } = Typography;

type Props = {
  selectedFolder: FolderData[] | FoldersList[] | undefined;
  isFetchFolderDataLoading: boolean;
  setFoldersBreadCrumbs: (
    folderBreadCrumb:
      | { title: string; id: number }[]
      | ((prevState: { title: string; id: number }[]) => { title: string; id: number }[]),
  ) => void;
  folderId: number;
  isLoadingFolderData: boolean;
  uuid?: string;
};

const DataRoomList = ({
  selectedFolder,
  isFetchFolderDataLoading,
  setFoldersBreadCrumbs,
  folderId,
  isLoadingFolderData,
  uuid,
}: Props) => {
  const { t, i18n } = useTranslation('dataroom');
  dayjs.locale(i18n.language);

  const { recheckLimits, limitations, disableRecordsAddition } = useOutletContext<LimitsCheckerContext>() || {};
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false);
  const [previewOpenDate, setPreviewOpenDate] = useState<Date>();

  const [openModals, setOpenModals] = useState({
    shareFile: false,
    deleteModal: false,
    editFile: false,
    editFolder: false,
    copyFile: false,
  });

  const [folderSettings, setFolderSettings] = useState<{ id: number; name: string; permissions: string[] }>();
  const [selectedFile, setSelectedFile] = useState<FolderData>();
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>();
  const { permissions } = usePermissionsStore((state) => state);

  const canShareFileOrFolder = useMemo(() => {
    if (uuid) return false;
    if (disableRecordsAddition) return false;
    const sharePlanlimit = limitations?.find(({ key }) => key === 'share');
    if (sharePlanlimit && sharePlanlimit.limit === 0) return true;
    if (sharePlanlimit && sharePlanlimit.usage === sharePlanlimit.limit) return false;
    return true;
  }, [limitations]);
  const canShare = permissions.includes('company.dataroom.share');

  const { mutate: createAnalyticsMutation } = useCreateAnalytics();

  const selectedItemsIds = useMemo(() => {
    //* filters selected items and return array with the selected ids
    if (selectedItems)
      return Object.entries(selectedItems)
        .filter(([, value]) => value)
        .map(([key]) => +key.split('-')[1]);
    else return [];
  }, [selectedItems]);

  const showModal = (modalName: ModalName) => {
    setOpenModals({
      shareFile: false,
      deleteModal: false,
      editFile: false,
      editFolder: false,
      copyFile: false,
      [modalName]: true,
    });
  };

  const closeModal = () => {
    setOpenModals({
      shareFile: false,
      deleteModal: false,
      editFile: false,
      editFolder: false,
      copyFile: false,
    });
  };

  const handleDownloadStart = () => {
    setIsFileDownloading(true);
  };
  const handleDownloadEnd = () => {
    setIsFileDownloading(false);
  };

  const handleSelectAllItems = useCallback(
    (isSelected: boolean = false) => {
      setSelectedItems(
        selectedFolder?.reduce((acc, curr) => {
          return { ...acc, [`item-${curr.id}`]: isSelected };
        }, {}),
      );
    },
    [selectedFolder],
  );

  const handleSelectItem = (itemID: number, isSelected: boolean) => {
    setSelectedItems((prev) => ({ ...prev, [`item-${itemID}`]: isSelected }));
  };

  const handleSingleItemShowModal = (modalName: ModalName) => {
    handleSelectAllItems(false);
    showModal(modalName);
  };

  const handlePreviewClose = () => {
    if (selectedFile && previewOpenDate) {
      createAnalyticsMutation({
        company_id: selectedCompany?.cid as number,
        payload: {
          hash: uuid,
          file_id: selectedFile.id,
          duration: (new Date().getTime() - previewOpenDate.getTime()) / 1000,
          downloaded: 0,
          viewed: 1,
        },
      });
    }

    setIsFilePreviewOpen(false);
  };

  const { mutate: deleteFileMutaion, isPending: isDeletePending } = useDeleteFile({
    postSuccess: () => {
      closeModal();
      handlePreviewClose();
      recheckLimits();
    },
  });

  const handleFileDelete = () => {
    if (selectedFile) deleteFileMutaion({ company_id: selectedCompany?.cid as number, file_id: selectedFile.id });
  };

  useEffect(() => {
    if (selectedFolder?.length !== 0) handleSelectAllItems();
  }, [selectedFolder, handleSelectAllItems]);

  return (
    <>
      {isLoadingFolderData || isFetchFolderDataLoading ? (
        <LoadingList />
      ) : (
        <>
          {selectedItemsIds.length > 0 && (
            <Flex
              gap={8}
              style={{
                marginBlockStart: `${themeToken.margin}px`,
                padding: `${themeToken.padding}px`,
                background: themeToken['branding-natural-5'],
                borderRadius: `${themeToken.borderRadiusSM}px`,
              }}
              justify="space-between"
              align="center"
            >
              <Flex gap={8}>
                <Checkbox onChange={(ev) => handleSelectAllItems(ev.target.checked)}>
                  <Text
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {t('selectAll')}
                  </Text>
                </Checkbox>
                <Tag closeIcon onClose={() => handleSelectAllItems(false)}>
                  {selectedItemsIds.length === 1
                    ? t('itemSelected', { count: selectedItemsIds.length })
                    : t('itemsSelected', { count: selectedItemsIds.length })}
                </Tag>
              </Flex>
              <FileShare
                disabled={!canShare || !canShareFileOrFolder}
                isVertical={false}
                setIsShareFileOpen={() => showModal('shareFile')}
              />
            </Flex>
          )}
          <List
            style={{ marginTop: themeToken.marginSM }}
            dataSource={selectedFolder}
            renderItem={(item) => (
              <ListItem
                item={item}
                isFetchFolderDataLoading={isFetchFolderDataLoading}
                setFoldersBreadCrumbs={setFoldersBreadCrumbs}
                setFolderSettings={setFolderSettings}
                setIsFilePreviewOpen={setIsFilePreviewOpen}
                setSelectedFile={setSelectedFile}
                handleSelectItem={handleSelectItem}
                isSelected={selectedItems?.[`item-${item.id}`]}
                showModal={handleSingleItemShowModal}
                uuid={uuid}
              />
            )}
          />
        </>
      )}

      {!uuid && (selectedFile || selectedItemsIds.length > 0) && (
        <>
          <ShareFileForm
            dataroomIds={selectedItemsIds.length > 0 ? selectedItemsIds : [selectedFile!.id]}
            isShareFileOpen={openModals.shareFile}
            handleCancel={() => closeModal()}
            closeFilePreview={isFilePreviewOpen ? handlePreviewClose : undefined}
          />
          <CopyFileModal
            dataroomIds={selectedItemsIds.length > 0 ? selectedItemsIds : [selectedFile!.id]}
            isCopyFileOpen={openModals.copyFile}
            handleCancel={() => closeModal()}
          />
        </>
      )}
      {selectedFile && (
        <>
          <FilePreview
            uuid={uuid}
            file={selectedFile}
            open={isFilePreviewOpen}
            setOpen={setIsFilePreviewOpen}
            isFileDownloading={isFileDownloading}
            handleDownloadStart={handleDownloadStart}
            handleDownloadEnd={handleDownloadEnd}
            setIsShareFileOpen={() => showModal('shareFile')}
            previewOpenDate={previewOpenDate}
            setPreviewOpenDate={setPreviewOpenDate}
            setIsDeleteModalOpen={() => showModal('deleteModal')}
            setIsEditModalOpen={() => showModal('editFile')}
          />

          <SharedModal
            open={openModals.deleteModal}
            setConfirmDeleteOpen={() => closeModal()}
            onOk={handleFileDelete}
            confirmationText={t('yesDelete')}
            title={t('deleteWarning')}
            content={
              <Text>
                {' '}
                {t('deleteConfirmation')} <span style={{ fontWeight: 'bold' }}> {selectedFile.name}</span> {t('file')} ?{' '}
              </Text>
            }
            isDeletePending={isDeletePending}
          />
          <EditFileForm
            isEditFileOpen={openModals.editFile}
            handleCancel={() => closeModal()}
            parentFolderLevel={folderId === 0 ? 1 : 2}
            fileId={selectedFile.id}
            fileName={selectedFile.name}
          />
        </>
      )}

      <CreateNewFolderForm
        isCreateNewFolderOpen={openModals.editFolder}
        handleCancel={() => closeModal()}
        parentFolderLevel={folderId === 0 ? 1 : 2}
        folderId={folderId}
        folderSettings={folderSettings}
      />
    </>
  );
};

export default DataRoomList;
