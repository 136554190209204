import styled from 'styled-components';

import { Drawer } from 'antd';

import themeToken from '@lib/theme/tokens/index';

export const StyledDrawer = styled(Drawer)`
  &.drawer {
    .ant-drawer-body {
      padding: 0;
      .drawer-header,
      .drawer-body {
        padding: ${themeToken.padding}px;
      }
      .drawer-body {
        height: calc(100% - 83px);
      }
    }
  }
`;
