import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import { ResponsiveContainer } from 'recharts';

import themeToken from '@lib/theme/tokens/index';
import ChartEmptyState from '@/components/Icons/ChartEmptyState';

const { Text } = Typography;

const VestingGraphEmptyState = () => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  return (
    <ResponsiveContainer width={'100%'} height={398}>
      <Flex vertical justify="center" align="center" style={{ height: '100%' }}>
        <ChartEmptyState />
        <Text style={{ marginTop: themeToken.marginSM, color: themeToken['branding-primary-4'], fontSize: 14 }}>
          {t('noChartData')}
        </Text>
      </Flex>
    </ResponsiveContainer>
  );
};

export default VestingGraphEmptyState;
