import { Flex, Skeleton, Typography } from 'antd';
import colors from '@lib/theme/tokens/colors';
const { Title, Text } = Typography;

type Props = {
  title: string;
  value: string;
  isLoading?: boolean;
};

const FinancialMetricDisplay = ({ title, value, isLoading }: Props) => {
  return (
    <Flex vertical align="center" gap={6} justify="center">
      <Text
        style={{
          color: colors['branding-natural-6'],
          textAlign: 'center',
        }}
      >
        {title}
      </Text>
      {isLoading ? (
        <Skeleton paragraph={false} active />
      ) : (
        <Title
          style={{
            color: colors['branding-primary-6'],
            fontSize: '20px',
          }}
        >
          {value}
        </Title>
      )}
    </Flex>
  );
};

export default FinancialMetricDisplay;
