import { StyledContent } from '@lib/theme/components/Layout';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

export const StyledContainer = styled(StyledContent)`
  &.ant-layout-content {
    background-color: ${themeToken['branding-natural-5']};
    display: flex;
    justify-content: center;
    margin-top: ${themeToken.paddingLG}px;
    margin-bottom: ${themeToken.paddingLG}px;
    height: calc(100vh - 48px);
    max-width: 1079px;
    @media (min-width: ${themeToken.screenMD}px) {
      align-items: center;
    }
  }
`;
