export default {
  phoneNumber: 'Phone Number',
  confirm: 'Confirm',
  microsoftAccount: 'Microsoft Account',
  googleAccount: 'Google Account',
  emailAddress: 'Email Address',
  termsAndConditions: 'Terms and Conditions',
  fullName: 'Full Name',
  addMore: 'Add More',
  settings: 'Settings',
  auth: {
    orContinueUsing: 'Or continue using',
  },
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',
  reset: 'Reset',
  close: 'Close',
  searchBy: 'Search ',
  optional: 'optional',
  download: 'Download',
  premium: 'Premium',
  deleteWarning: 'You are about to delete something important',
  deleteConfirmation: 'Are you certain about deleting the',
  impactCapTableWarning:
    'This action may impact your overall cap table, potentially causing damage and alterations to other equities.',
  yesDelete: 'Yes, Delete',
  chart: {
    rate: 'Rate',
  },
  pinnedNotification: {
    oops: 'Sorry!',
    headsUp:
      "Heads up, you're about to exceed, or exceeded, the maximum number of the following feature usage inside <b>{{feature}}</b>:",
    exceedMax:
      "Heads up, you're about to exceed the maximum number of <b>{{feature}}</b> allowed (<b>{{usage}}</b> out of <b>{{limit}}</b> <b>{{feature}}</b>) in your plan. To add more <b>{{feature}}</b> you need to subscribe",
    exceededMax:
      'Unfortunately, you have exceeded the maximum number of <b>{{feature}}</b> allowed (<b>{{limit}} {{feature}}</b>) in your plan. To add more <b>{{feature}}</b> you need to subscribe',
    addMoreUsage: 'To add more usage of <b>{{feature}}</b> different features, you need to upgrade your plan.',
    yourSubscriptionEndsSoon:
      'Your subscription ends soon on <b>{{expiryDate}}</b> (<b>{{expiryInterval}}</b> days left). Renew now or Change your plans.',
    valuationSubscriptionEndsSoon:
      'Your Valuation editing period is coming to an end on <b>{{expiryDate}}</b> (<b>{{expiryInterval}}</b> days left) . Please ensure your valuation information is finalized and report is generated.',
    valuationSubscriptionEnded:
      'Your valuation editing period has ended on <b>{{expiryDate}}</b>. If needed, you can purchase another valuation.',
    exploreOurPlans: 'Explore Our Plans',
    renewNow: 'Renew Now',
    classes: 'Classes',
    options: 'Options',
    convertibles: 'Convertibles',
    schedules: 'Schedules',
    dataroom: 'Data Room',
    users: 'Users',
    valuation: 'Valuation',
    folder: 'Folder',
    folders: 'Folder(s)',
    file: 'File',
    files: 'File(s)',
    share: 'Active Share',
    shares: 'Active Share(s)',
    limitUsage: '<b>{{usage}}</b> out of <b>{{limit}}</b>',
  },
};
