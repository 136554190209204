import { PaymentSuccessIcon } from '@/components/Icons/PaymentSuccessIcon';
import { Flex, Modal, Typography } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import dayjs from 'dayjs';

const StyledPaymentModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    .ant-input-wrapper {
      input {
        border-inline-end: 0;
      }
    }
  }
`;

const { Title, Text } = Typography;

const PaymentStatusModal = ({
  planName,
  date,
  isOpen,
  setIsOpen,
}: {
  planName: string;
  date: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t, i18n } = useTranslation('plans');
  dayjs.locale(i18n.language);
  const handlePaymentModalCancel = () => {
    setIsOpen(false);
  };

  return (
    <StyledPaymentModal
      open={isOpen}
      onCancel={handlePaymentModalCancel}
      width={670}
      footer={null}
      centered
      closeIcon={false}
    >
      <Flex vertical justify="center" align="center">
        <PaymentSuccessIcon />
        <Title
          style={{
            color: themeToken['branding-natural-10'],
            fontSize: '24px',
            fontWeight: '600',
            marginTop: '4px',
          }}
        >
          {t('paymentReceivedSubscriptionActive')}
        </Title>
        <Text
          style={{
            color: themeToken['branding-natural-6'],
            fontSize: '14px',
            fontWeight: '400',
            marginTop: '4px',
          }}
        >
          {`✨ ${t('welcomeBoardEnjoyAccess')} ✨`}
        </Text>
        <Flex
          vertical
          style={{
            marginTop: themeToken.marginLG,
            width: '100%',
            border: '1px solid',
            borderColor: themeToken['branding-natural-3'],
            borderRadius: themeToken.borderRadiusLG,
            padding: themeToken.paddingXL,
            gap: themeToken.paddingMD,
          }}
        >
          <Flex gap={2} align="center">
            <CheckOutlined
              style={{
                width: '24px',
                height: '24px',
                color: themeToken.colorSuccess,
              }}
            />
            <Title
              style={{
                color: themeToken['branding-natural-9'],
                fontSize: themeToken.fontSizeXL,
                fontWeight: themeToken.fontWeightLG,
              }}
            >
              {t('planName', { name: planName })}
            </Title>
          </Flex>
          <Text
            style={{
              color: themeToken['branding-natural-6'],
              fontSize: '14px',
              fontWeight: '400',
              marginTop: '4px',
            }}
          >
            {`${t('yourRecentPaymentReceived')} ${dayjs(date).format('DD MMMM YYYY')}`}
          </Text>
        </Flex>
        <Flex justify="end" style={{ width: '100%' }}>
          <StyledButton
            type="primary"
            style={{
              width: '160px',
              marginBlockStart: themeToken.margin,
            }}
            onClick={handlePaymentModalCancel}
          >
            {t('done')}
          </StyledButton>
        </Flex>
      </Flex>
    </StyledPaymentModal>
  );
};

export default PaymentStatusModal;
