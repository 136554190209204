import { Divider } from 'antd';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

export const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin: ${themeToken.marginLG}px 0;

    &::before,
    &::after {
      border-color: ${themeToken['branding-natural-3']};
    }
    .ant-divider-inner-text {
      color: ${themeToken['branding-natural-8']};
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
