import { Table, Typography } from 'antd';

import { CellSkeleton } from './CellSkeleton';

const { Text } = Typography;

interface Props {
  summaryTitle?: string;
  summaryTitleOffset?: number;
  summaryOffset?: number;
  summaryValues: string[];
  isLoading?: boolean;
}

function CommonTableSummary({ summaryTitle, summaryTitleOffset, summaryOffset, summaryValues, isLoading }: Props) {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={summaryTitleOffset}>
        {isLoading ? <CellSkeleton /> : summaryTitle}
      </Table.Summary.Cell>
      {summaryOffset && <Table.Summary.Cell index={1} colSpan={summaryOffset}></Table.Summary.Cell>}
      {summaryValues?.map((el, i) => (
        <Table.Summary.Cell key={2 + i} index={2 + i}>
          {isLoading ? <CellSkeleton /> : <Text>{el}</Text>}
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
}

export default CommonTableSummary;
