import Axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { clearCookie, getCookie } from '@/utils/storage';
import { queryClient } from './react-query';

export const axiosClient = Axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api/v2`,
});

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = getCookie('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}
axiosClient.interceptors.request.use(authRequestInterceptor);
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearCookie('access_token');
      clearCookie('refresh_token');
      localStorage.clear();
      queryClient.clear();
      if (!location.pathname.split('/').includes('confirm-account')) {
        location.pathname = '/';
      }
    }
    throw error;
  },
);

export default axiosClient;
