import { Divider, Flex, Typography } from 'antd';
import { StyledForSaleModal } from './StyledForsaleModal';
import ForSaleModalImage from '@/assets/ForSaleModalImage.png';
import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

const ForSaleModal = ({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('companyListing');

  return (
    <StyledForSaleModal closeIcon={false} open={isOpen} onCancel={onCancel} width={500} footer={null} centered>
      <Flex>
        <img src={ForSaleModalImage} style={{ width: '100%' }} />
      </Flex>

      <Flex gap={24} vertical style={{ padding: '16px' }}>
        <Title level={5} style={{ color: themeToken['branding-natural-9'], fontWeight: 600 }}>
          {t('bannerTitle')}
        </Title>

        <Text style={{ fontSize: themeToken.fontSize, color: themeToken['branding-natural-6'], fontWeight: 600 }}>
          {t('bannerSubTitle')}
        </Text>

        <Text style={{ fontSize: themeToken.fontSize, color: themeToken['branding-natural-6'], fontWeight: 400 }}>
          {t('forsaleModalContent')}
        </Text>
      </Flex>

      <Divider
        style={{
          margin: '0px',
          borderColor: themeToken['branding-natural-3'],
        }}
      />
      <Flex style={{ padding: '16px' }} gap={16}>
        <StyledButton onClick={onCancel}>{t('forsaleModalCancelButton')}</StyledButton>
        <StyledButton
          type="primary"
          style={{ background: themeToken['branding-secondary-6'], width: '356px' }}
          onClick={() => navigate('/company-listing/company-info')}
        >
          {t('forsaleModalOKButton')}
        </StyledButton>
      </Flex>
    </StyledForSaleModal>
  );
};

export default ForSaleModal;
