import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

import { axiosClient } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import { CompanyPayloadData } from '../types';

type CompanyPayload = {
  companyID: number;
  body: CompanyPayloadData;
};

export const editCompany = async ({ companyID, body }: CompanyPayload): Promise<{ message: string }> => {
  const { data } = await axiosClient.put<{ message: string }>(`/companies/${companyID}`, body);
  return data;
};
type UseEditCompanyOptions = {
  config?: Partial<UseMutationOptions<{ message: string }, AxiosError<{ message: string }>, CompanyPayload>>;
  postSuccess?: (companyData: CompanyPayloadData) => void;
};

export const useEditCompany = ({ config, postSuccess }: UseEditCompanyOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async (_, companyData) => {
      await queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['company'],
      });
      toaster.success({
        message: 'Updated Successfully',
        description: 'Company Updated Successfully',
      });
      postSuccess?.(companyData.body);
    },
    ...config,
    mutationFn: editCompany,
  });
};
