import Pagination from 'antd/lib/pagination';
import styled from 'styled-components';

import themeToken from '@lib/theme/tokens/index';

export const StyledPagination = styled(Pagination)`
  &.ant-pagination {
    .ant-pagination-item-active {
      border-color: ${themeToken['branding-primary-6']};
      a {
        color: ${themeToken['branding-primary-6']};
      }
    }
  }
`;
