import { useQuery } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';
import { sumArrayItems } from '../../utils/sumArrayItems';

import type {
  CaptableOverviewResponse,
  CaptableOverviewModifiedData,
  CaptableOverviewTableData,
  issued_shares,
  grouped_shares,
  DetailedCaptaibleTableData,
} from '../../types';

const accumulateDetailedData = (data: issued_shares, key: keyof grouped_shares) => {
  return data.grouped_shares.map((grouped_share) => grouped_share[key]);
};

const formatDetailedCaptableData = (issued_shares: issued_shares[]): DetailedCaptaibleTableData[] => {
  return issued_shares.map((issued_share: issued_shares) => ({
    key: accumulateDetailedData(issued_share, 'grant_id').join(','),
    shareholder: issued_share.shareholder,
    equity_class: accumulateDetailedData(issued_share, 'equity_class') as string[],
    equity_type: accumulateDetailedData(issued_share, 'equity_type') as string[],
    grant_id: accumulateDetailedData(issued_share, 'grant_id') as string[],
    issued_shares: sumArrayItems(accumulateDetailedData(issued_share, 'issued_shares') as number[]),
    ownership: `${sumArrayItems(
      issued_share.grouped_shares.map((grouped_share) => Number(grouped_share.ownership.replace('%', ''))),
    )}`,

    nestedData: issued_share.grouped_shares.map((group_share) => ({
      ...group_share,
      shareholder: '',
      key: [group_share.grant_id].join(','),
      grant_id: [group_share.grant_id],
      equity_type: [group_share.equity_type],
      equity_class: [group_share.equity_class],
    })),
  }));
};

export const fetchCaptable = async ({ company_id }: { company_id: number }): Promise<CaptableOverviewModifiedData> => {
  const res = await axiosClient
    .get<CaptableOverviewResponse>(`/companies/${company_id}/cap-table`)
    .then((response) => response);

  const formated_detailed_captable_data = formatDetailedCaptableData(res.data.data.issued_shares.data);

  const options_available_to_grant_random_color = '#E4913C';
  const options_outstanding_random_color = '#8359E1';

  return {
    ...res.data.data,
    detailed_shares: res.data.data.issued_shares.data,
    issued_shares: formated_detailed_captable_data,
    detailed_captable_issued_shares: res.data.data.issued_shares.total,

    modifiedEquities: [
      ...res.data.data.equities,
      {
        equity_class: 'Options available to grant',
        total_issued_shares: res.data.data.options_available_to_grant.value,
        ownership: res.data.data.options_available_to_grant.ownership,
        equity_class_color: options_available_to_grant_random_color,
      },
      {
        equity_class: 'Options Outstanding',
        total_issued_shares: res.data.data.options_outstanding.value,
        ownership: res.data.data.options_outstanding.ownership,
        equity_class_color: options_outstanding_random_color,
      },
    ],

    ownerShipByShareClass: [
      ...res.data.data.equities.map((equity: CaptableOverviewTableData) => ({
        name: equity.equity_class,
        value: equity.total_issued_shares,
        percent: equity.ownership,
        equity_class_color: equity.equity_class_color,
      })),

      {
        name: 'Options available to grant',
        value: res.data.data.options_available_to_grant.value,
        percent: res.data.data.options_available_to_grant.ownership,
        equity_class_color: options_available_to_grant_random_color,
      },

      {
        name: 'Options Outstanding',
        value: res.data.data.options_outstanding.value,
        percent: res.data.data.options_outstanding.ownership,
        equity_class_color: options_outstanding_random_color,
      },
    ],

    ownershipByShareholders: formated_detailed_captable_data.map((formated_detailed_captable_item) => ({
      name: formated_detailed_captable_item.shareholder,
      value: formated_detailed_captable_item.issued_shares,
      percent: formated_detailed_captable_item.ownership,
    })),
  };
};

export const useFetchCaptable = ({ company_id }: { company_id: number }) => {
  return useQuery({
    queryKey: ['captableOverview', company_id],
    queryFn: () => fetchCaptable({ company_id }),
  });
};
