import { SVGProps } from 'react';
const GeneratingRepoerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M61.45 6.2h-50.9c-1.675 0-3.032 1.29-3.032 2.882v60.036C7.518 70.71 8.875 72 10.55 72h50.9c1.674 0 3.032-1.291 3.032-2.883V9.082c0-1.592-1.358-2.883-3.033-2.883Z"
      />
      <path fill="#fff" d="M61.147 8.541h-50.29v58.972h50.29V8.541ZM51.713 6.2h-31.42V8.54h31.42V6.2Z" />
      <path
        fill="#D81059"
        d="M48.224 4.285H40.63C40.556 1.908 38.518 0 36 0s-4.556 1.908-4.63 4.285h-7.594c-.695 0-1.258.535-1.258 1.196v6.09h26.965V5.48c0-.66-.564-1.196-1.26-1.196Z"
      />
      <path fill="#6430DA" d="M18.967 31.334h-4.805v6.305h4.805v-6.305Z" />
      <path fill="#2A186A" d="M25.44 24.217h-4.804V37.64h4.805V24.217Z" />
      <path fill="#6430DA" d="M31.914 17.021H27.11V37.64h4.805V17.02Z" />
      <path fill="#2A186A" d="M38.388 26.674h-4.805v10.965h4.805V26.674Z" />
      <path
        fill="#DCE0E9"
        d="M57.839 40.867H14.162v1.412H57.84v-1.412ZM57.837 18.578h-14.8v1.412h14.8v-1.412ZM57.837 24.15h-14.8v1.412h14.8V24.15ZM57.837 29.725h-14.8v1.411h14.8v-1.411ZM57.837 35.295h-14.8v1.411h14.8v-1.411ZM57.839 46.443H14.162v1.412H57.84v-1.412ZM57.839 51.87H14.162v13.969H57.84v-13.97Z"
      />
      <path fill="#DCE0E9" d="M57.839 51.299H14.162v13.97H57.84v-13.97Z" />
      <path
        fill="#D81059"
        d="M14.965 64.886a.427.427 0 0 1-.27-.095.38.38 0 0 1-.043-.554l5.561-6.143a.428.428 0 0 1 .517-.086l6.161 3.299 5.449-5.362a.43.43 0 0 1 .507-.066l6.568 3.663 5.091-5.343a.427.427 0 0 1 .496-.085l5.91 2.888 5.094-4.16a.43.43 0 0 1 .583.042.38.38 0 0 1-.044.555L51.24 57.77a.433.433 0 0 1-.459.051l-5.873-2.87-5.104 5.355a.432.432 0 0 1-.517.075l-6.578-3.668-5.443 5.358a.432.432 0 0 1-.501.069l-6.145-3.29-5.34 5.899a.422.422 0 0 1-.314.136Z"
      />
      <path
        fill="#6430DA"
        d="M14.965 64.886a.415.415 0 0 1-.377-.23.385.385 0 0 1 .206-.521l6.822-2.946a.423.423 0 0 1 .331-.005l4.867 1.934 4.905-2.482a.432.432 0 0 1 .304-.033l6.553 1.71 6.026-3.426a.438.438 0 0 1 .307-.045l7.297 1.655 2.05-1.343a.43.43 0 0 1 .575.1.382.382 0 0 1-.107.548l-2.201 1.442a.44.44 0 0 1-.33.059l-7.312-1.659-6.032 3.43a.437.437 0 0 1-.321.043l-6.567-1.713-4.933 2.496a.428.428 0 0 1-.354.015l-4.88-1.94-6.658 2.876a.434.434 0 0 1-.171.035Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6.546 0h58.909v72H6.545z" />
      </clipPath>
    </defs>
  </svg>
);
export default GeneratingRepoerIcon;
