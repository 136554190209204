import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import axiosClient from '@/lib/axios';

import type { UploadFileResponse } from '../types';

type UploadFileParams = {
  company_id: number;
  folder_id: number;
  file: File;
};

export const uploadFile = async ({ company_id, folder_id, file }: UploadFileParams) => {
  const uploadFilePayload = new FormData();
  uploadFilePayload.append('folderId', folder_id as unknown as string);
  uploadFilePayload.append('file', file, encodeURI(file.name));

  const response = await axiosClient.post<UploadFileResponse>(
    `/companies/${company_id}/data-rooms/files`,
    uploadFilePayload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

type UseUploadFileOptions = {
  config?: Partial<
    UseMutationOptions<
      UploadFileResponse,
      AxiosError<{ message: string }>,
      { company_id: number; folder_id: number; file: File }
    >
  >;
  postSuccess?: () => void;
};

export const useUploadFile = ({ postSuccess, config }: UseUploadFileOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['folder-data'],
      });
      toaster.success({
        message: 'Added Successfully',
        description: 'Your File Added to data room Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: uploadFile,
    ...config,
  });
};
