import { PageWrapper } from '@/components/Shared/Wrapper';
import CompanyForm from '../components/CompanyForm';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const AddNewCompany = () => {
  const { t: translate } = useTranslation('pageTitles');
  useDocumentTitle(translate('addNewCompany'));
  return (
    <PageWrapper>
      <CompanyForm />
    </PageWrapper>
  );
};

export default AddNewCompany;
