import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

import axiosClient from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import { CreateNewFolderPayload, CreateNewFolderResponse } from '../types';

type EditFolderParams = {
  company_id: number;
  folder_id: number;
  payload: CreateNewFolderPayload;
};

export const editFolder = async ({ company_id, folder_id, payload }: EditFolderParams) => {
  const response = await axiosClient.put<CreateNewFolderResponse>(
    `/companies/${company_id}/data-rooms/folders/${folder_id}`,
    payload,
  );

  return response.data;
};

type UseEditFolderDTO = {
  config?: Partial<
    UseMutationOptions<
      CreateNewFolderResponse,
      AxiosError<{ message: string }>,
      { company_id: number; folder_id: number; payload: CreateNewFolderPayload }
    >
  >;
  parentFolderLevel: number;
  postSuccess?: () => void;
};

export const useEditFolder = ({ parentFolderLevel, postSuccess, config }: UseEditFolderDTO) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    onSuccess: async () => {
      if (parentFolderLevel === 1)
        await queryClient.invalidateQueries({
          queryKey: ['folders'],
        });
      else
        await queryClient.invalidateQueries({
          queryKey: ['folder-data'],
        });

      toaster.success({
        message: 'Updated Successfully',
        description: 'Your Folder Updated Successfully',
      });
      postSuccess?.();
    },
    mutationFn: editFolder,
    ...config,
  });
};
