export default {
  form: {
    editCompany: 'Edit Company',
    createNewCompany: 'Create New Company',
    personalizedExperience:
      'Total Personalised Experience for your profile add your company info to get highest Value from Rasmal',
    companyLogo: 'Company Logo',
    addLogo: 'Please add logo',
    companyName: 'Company Name',
    addValue: 'Please add value',
    companyType: 'Company type',
    selectValue: 'Please select value',
    incorporationDate: 'Incorporation Date',
    selectDate: 'Select date',
    countryOfIncorporation: 'Country Of Incorporation',
    countriesOfOperation: 'Countries Of Operation',
    currency: 'Currency',
    submit: 'Submit',
  },
};
