import { SVGProps } from 'react';
const ActiveFolderCloudIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M6.75 16.5h-1.5c-3 0-3.75-.75-3.75-3.75v-7.5c0-3 .75-3.75 3.75-3.75h1.125c1.125 0 1.373.33 1.8.9L9.3 3.9c.285.375.45.6 1.2.6h2.25c3 0 3.75.75 3.75 3.75v1.5"
    />
    <path
      fill="#6430DA"
      fillOpacity={0.29}
      d="M10.32 13.74c-1.763.127-1.763 2.677 0 2.805h4.17a2.03 2.03 0 0 0 1.365-.525c1.237-1.08.577-3.24-1.05-3.443-.585-3.517-5.67-2.182-4.47 1.17"
    />
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.35}
      d="M10.32 13.74c-1.763.127-1.763 2.677 0 2.805h4.17a2.03 2.03 0 0 0 1.365-.525c1.237-1.08.577-3.24-1.05-3.443-.585-3.517-5.67-2.182-4.47 1.17"
    />
  </svg>
);
export default ActiveFolderCloudIcon;
