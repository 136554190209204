import { Layout } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const { Header, Sider, Content } = Layout;

const defaultTheme = {
  headerBg: themeToken.white,
  headerPadding: `${themeToken.paddingXS}px ${themeToken.paddingLG}px`,
  paddingContentHorizontal: themeToken.paddingLG,
};

const globalTheme = {};

export const layoutTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledLayout = styled(Layout)`
  &.ant-layout {
    height: 100%;
    flex-direction: column;
    background: #f4f7f9;
  }
`;

export const StyledHeader = styled(Header)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 11;
    width: 100%;
    height: ${themeToken.sizeXXL}px;

    line-height: unset;
    background: ${themeToken['branding-primary-6']};
    img:first-child {
      filter: invert(99%) sepia(8%) saturate(0%) hue-rotate(326deg) brightness(103%) contrast(102%);
    }
    @media (min-width: ${themeToken.screenMD}px) {
      background: white;
      border-bottom: 1px solid #e5ebf1;
      img:first-child {
        filter: unset;
      }
    }
  }
`;

export const StyledSider = styled(Sider)<{ className?: string }>`
  &.ant-layout-sider {
    height: ${(props) => (props.className === 'is-sidebar-collapsed' ? 'unset' : 'calc(100vh - 48px)')};
    background: transparent;
    flex: 0 !important;
    width: 100%;
    top: 48px;
    position: fixed;
    z-index: 10;
    .ant-layout-sider-children {
      display: flex;
      flex-direction: ${(props) => (props.className === 'is-sidebar-collapsed' ? 'row' : 'column')};
      .sidebar-main-child {
        flex: 1;
        height: 100%;
        display: ${(props) => (props.className === 'is-sidebar-collapsed' ? 'none' : 'flex')};
        width: 100%;
      }
    }

    @media (min-width: ${themeToken.screenXL}px) {
      inset-inline-start: 0;
      inset-inline-end: unset;
      bottom: 0;
      overflow: auto;
      height: 100vh;
    }
  }
`;

export const StyledContent = styled(Content)`
  &.ant-layout-content {
    max-width: 1392px;
    padding-inline: ${themeToken.paddingLG}px;
    margin-inline: auto;
    width: 100%;
  }
`;
