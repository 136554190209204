import { SVGProps } from 'react';
const ExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#2A186A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8 1.334a6.67 6.67 0 0 0-6.667 6.667A6.669 6.669 0 0 0 8 14.667a6.669 6.669 0 0 0 6.666-6.666"
    />
    <path
      stroke="#2A186A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.667 7.334 5.467-5.467M14.666 4.554v-3.22h-3.22"
    />
  </svg>
);
export default ExportIcon;
