const GraphIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.74 8.99989C15.69 8.99989 16.5 8.24989 15.78 5.78989C15.2925 4.13239 13.8675 2.70739 12.21 2.21989C9.75 1.49989 9 2.30989 9 4.25989V6.41989C9 8.24989 9.75 8.99989 11.25 8.99989H13.74Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 11.0251C14.3024 14.4976 10.9724 17.0176 7.18495 16.4026C4.34245 15.9451 2.05495 13.6576 1.58995 10.8151C0.982448 7.04257 3.48745 3.71257 6.94495 3.00757"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GraphIcon;
