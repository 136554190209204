import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import DocumentDownloadIcon from '@/components/Icons/DocumentDownloadIcon';

import themeToken from '@lib/theme/tokens/index';

import { generateUrlQueryClientParams } from '../api/generateFileUrl';
import { useCreateAnalytics } from '../api/createAnalytics';

import { StyledActionIconContainer } from './StyledActionIconContainer';

import { useSelectedCompanyStore } from '@/stores/selectedCompany';

import { FolderData } from '../types';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;

const FileDownload = ({
  fileId,
  entityType,
  hasLabel = true,
  isWhiteBgColor = false,
  isVertical = true,
  disabled,
  uuid,
  file,
  closeDropdown,
  handleDownloadStart,
  handleDownloadEnd,
}: {
  fileId: number;
  file: FolderData;
  entityType: string;
  disabled: boolean;
  hasLabel?: boolean;
  isWhiteBgColor?: boolean;
  isVertical?: boolean;
  uuid?: string;
  closeDropdown?: () => void;
  handleDownloadStart: () => void;
  handleDownloadEnd: () => void;
}) => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const queryClient = useQueryClient();

  const { mutate: createAnalyticsMutation } = useCreateAnalytics();

  const DownloadFile = async (id: number) => {
    try {
      if (handleDownloadStart) handleDownloadStart();

      const data = await queryClient.fetchQuery(
        generateUrlQueryClientParams({
          company_id: selectedCompany?.cid as number,
          file_id: id,
          uuid,
        }),
      );
      const link = document.createElement('a');
      link.href = data?.data?.url;
      link.setAttribute('target', `_blank`);
      link.setAttribute('download', `${file.name}.${data.data.file_extension}`);

      document.body.appendChild(link);

      link.click();
      createAnalyticsMutation({
        company_id: selectedCompany?.cid as number,
        payload: {
          hash: uuid,
          file_id: id,
          duration: 0,
          downloaded: 1,
          viewed: 0,
        },
      });

      link.parentNode?.removeChild(link);
    } catch (e) {
      console.log({ e });
    } finally {
      if (handleDownloadEnd) handleDownloadEnd();
    }
  };

  return (
    <Flex
      key="download-action"
      vertical={isVertical}
      align="center"
      gap={isVertical ? 2 : 8}
      style={{ cursor: !disabled ? 'pointer' : 'not-allowed' }}
      onClick={(e) => {
        if (disabled) return;
        sendMixpanelEvent('download-btn-clicked');
        e.stopPropagation();
        closeDropdown?.();
        if (entityType?.toLowerCase() === 'file') void DownloadFile(fileId);
      }}
    >
      <StyledActionIconContainer isWhiteBgColor={isWhiteBgColor} isVertical={isVertical}>
        <DocumentDownloadIcon />
      </StyledActionIconContainer>

      {hasLabel && (
        <Text
          style={{
            color: themeToken['branding-primary-3'],
            fontSize: themeToken.fontSizeSM,
            fontWeight: 400,
          }}
        >
          {t('download')}
        </Text>
      )}
    </Flex>
  );
};

export default FileDownload;
