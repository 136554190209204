import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import NegativeValuationErrorIcon from '@/components/Icons/NegativeValuationErrorIcon';
import DangerIcon from '@/components/Icons/DangerIcon';

const { Title, Text } = Typography;

const NegativeValuation = () => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'negative',
  });
  const navigate = useNavigate();

  return (
    <Flex vertical style={{ maxWidth: '600px' }} align="start">
      <Flex vertical gap={8} align="start">
        <NegativeValuationErrorIcon />
        <Title
          level={4}
          style={{
            color: themeToken['branding-red-primary-6'],
            marginTop: themeToken.marginXXS,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DangerIcon /> &nbsp;{t('negativeValuation')}
        </Title>
        <Text
          style={{
            color: themeToken['branding-primary-6'],
            fontSize: themeToken.fontSize,
            fontWeight: 600,
          }}
        >
          {t('dontWorry')}
        </Text>
        <ul
          style={{
            paddingInline: themeToken.paddingXL,
          }}
        >
          <li
            style={{
              marginBottom: themeToken.marginSM,
            }}
          >
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {t('doubleCheckLastYear')}
            </Text>
          </li>
          <li
            style={{
              marginBottom: themeToken.marginSM,
            }}
          >
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {t('doubleCheckProjections')}
            </Text>
          </li>
          <li
            style={{
              marginBottom: themeToken.marginSM,
            }}
          >
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {t('consultGuide')}
            </Text>
          </li>
          <li
            style={{
              marginBottom: themeToken.marginSM,
            }}
          >
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {t('contactUsChat')}
            </Text>
          </li>
        </ul>
      </Flex>

      <StyledButton
        type="primary"
        style={{
          background: themeToken['branding-primary-6'],
          marginBlock: themeToken.marginXL,
        }}
        onClick={() => navigate('/valuation/financial')}
      >
        {t('goToFinancial')}
      </StyledButton>
    </Flex>
  );
};

export default NegativeValuation;
