export default {
  contracts: 'Contracts',
  main: 'Main',
  contractsHistory: 'Contracts History',
  contractsList: 'Contracts list',
  contractListDescription: 'Easily Create and Customize Professional Documents in Minutes',
  searchText: 'Search File, Documents etc.',
  view: 'View',
  startCreating: 'Start',
  generatedocumentHistory: 'Generated Contracts History',
  fileName: 'File name',
  createdAt: 'Created at',
  createdBy: 'Created by',
  actions: 'Actions',
  reviewContract: 'Review Your Contract Carefully',
  back: 'Back',
  generate: 'Generate',
  addContractInformation: 'Add Contract Information',
  confirmAndGenerate: 'Confirm and Generate',
  confirmation: 'Confirmation',
  documentName: 'Contract Name',
  delete: 'Delete',
  cancel: 'Cancel',
  edit: 'Edit',
  save: 'Save',
  sureToCancel: 'Sure to cancel',
  sureToDelete: 'Sure to delete',
  addNewRecord: 'Add New Record',
  viewInDataRoom: 'View In DataRoom',
  confirmationModalContent:
    ' Please confirm that you have reviewed and revised all input fields. Once confirmed, your contract will be generated, Ensure all information is accurate and complete.',
  documentGenratorInProgress: 'Document Generation in Progress',
  documentData: '{{name}} is ready for download',
  notDocumentData: 'Please wait while we generate your document ({{name}}). This may take a few moments.',
  download: 'Download',
};
