import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Typography, Form, Divider } from 'antd';
import dayjs from 'dayjs';

import { StyledForm } from '@/components/Shared/Form';
import { StyledButton } from '@/lib/theme/components/Button';
import themeToken from '@lib/theme/tokens/index';
import BuildingIcon from '@/components/Icons/BuildingIcon';
import { StyledInput } from '@/lib/theme/components/Input';
import { StyledRadio } from '@/lib/theme/components/Radio';
import { StyledDatePicker } from '@/lib/theme/components/DatePicker';
import { StyledSelect } from '@/lib/theme/components/Select';
import { useGetCountries } from '@/features/misc/getCountries';
import { useGetCurrencies } from '@/features/misc/getCurrencies';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

import { useGetCompanyTypes } from '../api/getCompanyTypes';
import CompanyLogoUpload from './CompanyLogoUpload';
import { useAddCompany } from '../api/addCompany';
import type { CompanyPayloadData } from '../types';
import { useGetCompany } from '../api/getCompany';
import { useEditCompany } from '../api/editCompany';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;

const CompanyForm = () => {
  const { t } = useTranslation('company', {
    keyPrefix: 'form',
  });
  const { selectedCompany: storeSelectedCompany, setSelectedCompany } = useSelectedCompanyStore((state) => state);
  const { id } = useParams<{ id: string }>();
  const { sendMixpanelEvent } = useTrackEvent({
    page: id ? 'edit-company-page' : 'add-company-page',
  });
  const { data: selectedCompany } = useGetCompany({
    companyID: id ? +id : undefined,
  });
  const { data: companyTypes } = useGetCompanyTypes({});
  const { data: countries } = useGetCountries({});
  const { data: currencies } = useGetCurrencies({});
  const navigate = useNavigate();
  const { mutate: mutateAddCompany, isPending: isAddCompanyPending } = useAddCompany({
    postSuccess: (companyData) => {
      setSelectedCompany(companyData);
      navigate('/company/dashboard?upselling_plan=1');
    },
  });
  const { mutate: mutateEditCompany, isPending: isEditCompanyPending } = useEditCompany({
    postSuccess: (companyData) => {
      const { name, logo } = companyData;
      if (storeSelectedCompany) setSelectedCompany({ ...storeSelectedCompany, name, logo });
      navigate('/company/dashboard');
    },
  });

  const [form] = Form.useForm();

  const onFinish = (values: CompanyPayloadData) => {
    sendMixpanelEvent('submit-btn-clicked');
    if (id) {
      mutateEditCompany({
        companyID: +id,
        body: values,
      });
    } else {
      mutateAddCompany({
        body: values,
      });
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      const { company_data } = selectedCompany;
      const companyCountries = company_data?.company_operational_countries.map((el) => el.id);
      form.setFieldsValue({
        logo: selectedCompany?.company_data?.logo,
        name: company_data?.name,
        company_type_id: company_data?.company_type.id,
        incorporation_date: dayjs(company_data?.incorporation_date),
        company_country_id: company_data?.company_country.id,
        company_ops_country_ids: companyCountries,
        company_currency_id: company_data?.company_currency.id,
      });
    }
  }, [form, selectedCompany]);

  return (
    <StyledForm
      layout="vertical"
      form={form}
      onFinish={onFinish}
      disabled={isAddCompanyPending || isEditCompanyPending}
      size="large"
    >
      <Flex align="start" vertical>
        <Flex gap={16}>
          <BuildingIcon width="60px" height="60px" />
          <Flex vertical>
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontWeight: 500,
                marginBottom: themeToken.marginXXS,
              }}
            >
              {id ? t('editCompany') : t('createNewCompany')}
            </Text>
            <Text
              style={{
                color: themeToken['branding-natural-6'],
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('personalizedExperience')}
            </Text>
          </Flex>
        </Flex>
        <Divider
          style={{
            marginBlock: themeToken.margin,
            borderColor: themeToken['branding-natural-3'],
          }}
        />
        <Form.Item label={t('companyLogo')} name="logo" rules={[{ required: true, message: t('addLogo') }]}>
          <CompanyLogoUpload currLogo={selectedCompany?.company_data?.logo} form={form} />
        </Form.Item>
        <Form.Item label={t('companyName')} name="name" rules={[{ required: true, message: t('addValue') }]}>
          <StyledInput placeholder={t('companyName')} />
        </Form.Item>
        <Form.Item
          label={t('companyType')}
          name="company_type_id"
          rules={[{ required: true, message: t('selectValue') }]}
        >
          <StyledRadio.Group>
            <Flex gap={12} vertical>
              {companyTypes?.map((el, i) => (
                <Flex key={i} gap={4} vertical>
                  <StyledRadio value={el.id}>{el.name}</StyledRadio>
                </Flex>
              ))}
            </Flex>
          </StyledRadio.Group>
        </Form.Item>
        <Form.Item
          label={t('incorporationDate')}
          name="incorporation_date"
          rules={[{ required: true, message: t('selectDate') }]}
        >
          <StyledDatePicker placeholder={t('selectDate')} />
        </Form.Item>
        <Form.Item
          label={t('countryOfIncorporation')}
          name="company_country_id"
          rules={[{ required: true, message: t('selectValue') }]}
        >
          <StyledSelect
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={countries?.map((el) => ({ value: el.id, label: el.name })) || []}
            placeholder={t('countryOfIncorporation')}
          />
        </Form.Item>
        <Form.Item
          label={t('countriesOfOperation')}
          name="company_ops_country_ids"
          rules={[{ required: true, message: t('selectValue') }]}
        >
          <StyledSelect
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            mode="multiple"
            options={countries?.map((el) => ({ value: el.id, label: el.name })) || []}
            placeholder={t('countriesOfOperation')}
          />
        </Form.Item>
        <Form.Item
          label={t('currency')}
          name="company_currency_id"
          rules={[{ required: true, message: t('selectValue') }]}
        >
          <StyledSelect
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={currencies?.map((el) => ({ value: el.id, label: el.name })) || []}
            placeholder={t('currency')}
          />
        </Form.Item>
        <Divider
          style={{
            marginBlock: themeToken.margin,
            borderColor: themeToken['branding-natural-3'],
            marginBlockStart: 'auto',
          }}
        />
        <Flex style={{ width: '100%' }} justify="flex-end">
          <StyledButton type="primary" htmlType="submit" loading={isAddCompanyPending || isEditCompanyPending}>
            {t('submit')}
          </StyledButton>
        </Flex>
      </Flex>
    </StyledForm>
  );
};

export default CompanyForm;
