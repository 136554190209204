import { axiosClient } from '@/lib/axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { CompanyType } from '../types';

type CompanyTypeResponse = {
  data: CompanyType[];
};

export const getCompanyTypes = async (): Promise<CompanyType[]> => {
  const { data } = await axiosClient.get<CompanyTypeResponse>(`/companies/type`);
  return data?.data;
};
type QueryFnType = typeof getCompanyTypes;

type UseGetCompanyTypesOptions = {
  config?: QueryFunction<QueryFnType>;
};

export const useGetCompanyTypes = ({ config }: UseGetCompanyTypesOptions) => {
  return useQuery({
    queryKey: ['company-types'],
    queryFn: getCompanyTypes,
    ...config,
  });
};
