import { theme } from 'antd';
import colors from './colors';
import spacings from './spacings';
import fonts from './fonts';

const themeValues = theme.getDesignToken();

export { colors, spacings };

export default {
  ...themeValues,
  ...colors,
  ...spacings,
  ...fonts,
};
