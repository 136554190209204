import { axiosClient } from '@/lib/axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';

export type CompanyValuationStatusResponse = {
  financials: boolean;
  questionnaires: boolean;
  has_valuation: boolean;
  valuation_registered: boolean;
  valuation_negative: boolean;
};

type CompanyValuationStatusParams = {
  urlParams: {
    companyID: number;
  };
};

export const getCompanyValuationStatus = async ({
  urlParams,
}: {
  urlParams: CompanyValuationStatusParams['urlParams'];
}): Promise<CompanyValuationStatusResponse> => {
  const { data } = await axiosClient.get<CompanyValuationStatusResponse>(
    `/companies/${urlParams.companyID}/valuation/status`,
  );
  return data;
};

type QueryFnType = typeof getCompanyValuationStatus;

type UseGetCompanyValuationStatusOptions = {
  urlParams: CompanyValuationStatusParams['urlParams'];
  config?: QueryFunction<QueryFnType>;
};

export const UseGetCompanyValuationStatus = ({ urlParams, config }: UseGetCompanyValuationStatusOptions) => {
  return useQuery({
    queryKey: ['valuation', 'company-status', urlParams],
    queryFn: () =>
      getCompanyValuationStatus({
        urlParams,
      }),
    enabled: !!urlParams.companyID,
    ...config,
  });
};
