export const PdfFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159971)">
      <path
        d="M4.15051 3.74943V3.74942C4.15049 2.29844 5.32392 1.125 6.77494 1.125H14.5857L21.0995 7.91452V21.5006C21.0995 22.9522 19.9255 24.125 18.4811 24.125H6.77494C5.32395 24.125 4.15051 22.9516 4.15051 21.5006V3.74943Z"
        fill="#E5252A"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.4197 7.15949H15.2926V1.86078L20.4197 7.15949Z" fill="white" stroke="#EDEFF4" />
      <path
        d="M7.12244 18.5322V14.1484H8.98751C9.44928 14.1484 9.81511 14.2744 10.091 14.5322C10.3668 14.7841 10.5048 15.126 10.5048 15.5517C10.5048 15.9775 10.3668 16.3194 10.091 16.5712C9.81511 16.8291 9.44928 16.955 8.98751 16.955H8.24388V18.5322H7.12244ZM8.24388 16.0015H8.86158C9.02948 16.0015 9.16142 15.9655 9.25139 15.8816C9.34133 15.8036 9.38933 15.6957 9.38933 15.5518C9.38933 15.4079 9.34136 15.2999 9.25139 15.2219C9.16145 15.138 9.02951 15.102 8.86158 15.102H8.24388V16.0015ZM10.9665 18.5322V14.1484H12.5197C12.8256 14.1484 13.1134 14.1904 13.3833 14.2804C13.6532 14.3703 13.899 14.4963 14.1149 14.6702C14.3308 14.8381 14.5047 15.066 14.6307 15.3538C14.7506 15.6417 14.8166 15.9715 14.8166 16.3433C14.8166 16.7092 14.7506 17.039 14.6307 17.3268C14.5047 17.6147 14.3308 17.8426 14.1149 18.0105C13.899 18.1844 13.6532 18.3103 13.3833 18.4003C13.1134 18.4902 12.8256 18.5322 12.5197 18.5322H10.9665ZM12.064 17.5787H12.3878C12.5617 17.5787 12.7236 17.5608 12.8736 17.5188C13.0175 17.4768 13.1554 17.4108 13.2874 17.3209C13.4133 17.2309 13.5152 17.105 13.5872 16.9371C13.6592 16.7692 13.6951 16.5712 13.6951 16.3433C13.6951 16.1095 13.6592 15.9116 13.5872 15.7437C13.5152 15.5758 13.4133 15.4498 13.2874 15.3598C13.1554 15.2699 13.0175 15.2039 12.8736 15.162C12.7236 15.12 12.5617 15.102 12.3878 15.102H12.064V17.5787ZM15.3803 18.5322V14.1484H18.4988V15.102H16.5017V15.8036H18.0969V16.7511H16.5017V18.5322H15.3803Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159971">
        <rect width="24" height="24" fill="white" transform="translate(0.625 0.625)" />
      </clipPath>
    </defs>
  </svg>
);
