import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const UpgradePlan = lazy(() => import('../views/UpgradePlan'));
const NoAccess = lazy(() => import('../views/NoAccess'));
const Plans = lazy(() => import('../views/Plans'));

export const GoPremiumRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route path="upgrade" element={<UpgradePlan />} />
        <Route path="no-access" element={<NoAccess />} />
        <Route path="plans" element={<Plans />} />
      </Routes>
    </Suspense>
  );
};
