import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';

import type { InvitedUsers } from '../types';

type UsersPayload = {
  companyID: number;
};
type UsersResponse = {
  data: InvitedUsers;
};

export const getInvitedUsers = async ({ companyID }: UsersPayload): Promise<InvitedUsers> => {
  const { data } = await axiosClient.get<UsersResponse>(`/companies/${companyID}/dashboard/invited-users-count`);
  return data?.data;
};
type QueryFnType = typeof getInvitedUsers;

type UseGetInvitedUsersOptions = {
  companyID: number;
  config?: QueryFunction<QueryFnType>;
};

export const useGetInvitedUsers = ({ companyID, config }: UseGetInvitedUsersOptions) => {
  return useQuery({
    queryKey: ['invited-users', companyID],
    queryFn: () => getInvitedUsers({ companyID }),
    ...config,
  });
};
