import { Flex, Grid, List } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';

const { useBreakpoint } = Grid;

const LoadingList = () => {
  const screens = useBreakpoint();

  return (
    <List
      style={{ marginTop: themeToken.marginSM }}
      dataSource={Array(3).fill(0)}
      renderItem={(_, index) => (
        <List.Item
          key={index}
          id={`${index}`}
          style={{
            padding: `0 ${themeToken.paddingXXS}`,
            alignItems: 'center',
          }}
        >
          <Flex align="center" style={{ width: '50%' }}>
            <List.Item.Meta
              avatar={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '38px',
                  }}
                >
                  <CellSkeleton shape="square" width="24px" height="24px" />
                </div>
              }
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '38px',
                  }}
                >
                  <CellSkeleton shape="round" width="280px" height="16px" />
                </div>
              }
            />

            {!screens.xs && (
              <Flex gap={24}>
                <CellSkeleton shape="round" width="150px" height="16px" />
              </Flex>
            )}
          </Flex>
        </List.Item>
      )}
    />
  );
};
export default LoadingList;
