import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const ReviewStatus = lazy(() => import('../views/ReviewStatus'));
const ReviewLayout = lazy(() => import('../components/ReviewLayout'));
const FinalStep = lazy(() => import('../views/FinalStep'));
const MailnInfo = lazy(() => import('../views/MainInfo'));
const FinancialInfo = lazy(() => import('../views/FinancialInfo'));
const TeamInformation = lazy(() => import('../views/TeamInformation'));

export const CompanyReviewRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<ReviewLayout />}>
          <Route path="status" element={<ReviewStatus />} />
          <Route path="final" element={<FinalStep />} />
        </Route>

        <Route path="main-info" element={<MailnInfo />} />
        <Route path="financial" element={<FinancialInfo />} />
        <Route path="team" element={<TeamInformation />} />
      </Routes>
    </Suspense>
  );
};
