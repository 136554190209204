import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  & > .ant-flex {
    flex: 1;
  }
  .ant-form-item {
    width: 100%;
    .ant-form-item-label {
      text-align: start;
      label {
        font-weight: 600;
        max-height: 26px;
      }
    }
  }
`;
