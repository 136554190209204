import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import FolderSettingsIcon from '@/components/Icons/FolderSettingsIcon';
import { StyledButton } from '@/lib/theme/components/Button';

import { StyledActionIconContainer } from './StyledActionIconContainer';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;

type Props = {
  disabled: boolean;
  isWhiteBgColor?: boolean;
  hasLabel?: boolean;
  isVertical?: boolean;
  setIsEditFolderModalOpen: () => void;
  onClickHandler: () => void;
  closeDropdown?: () => void;
};

const EditFolder = ({
  disabled,
  closeDropdown,
  setIsEditFolderModalOpen,
  onClickHandler,
  hasLabel = true,
  isWhiteBgColor = false,
  isVertical = true,
}: Props) => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');

  return (
    <StyledButton
      style={{
        padding: 0,
        background: 'none',
      }}
      onClick={(e) => {
        sendMixpanelEvent('edit-folder-btn-clicked');
        e.stopPropagation();
        onClickHandler();
        setIsEditFolderModalOpen();
        closeDropdown?.();
      }}
      type="text"
      disabled={disabled}
    >
      <Flex vertical={isVertical} align="center" gap={isVertical ? 2 : 8}>
        <StyledActionIconContainer isWhiteBgColor={isWhiteBgColor} isVertical={isVertical}>
          <FolderSettingsIcon />
        </StyledActionIconContainer>
        {hasLabel && (
          <Text
            style={{
              color: themeToken['branding-primary-3'],
              fontSize: themeToken.fontSizeSM,
              fontWeight: 400,
            }}
          >
            {t('settings')}
          </Text>
        )}
      </Flex>
    </StyledButton>
  );
};

export default EditFolder;
