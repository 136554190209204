import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Grid, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { StyledBreadcrumb } from '@/lib/theme/components/Breadcrumb';
import type { BreadcrumbItem } from '@/types';

const { Title } = Typography;
const { useBreakpoint } = Grid;

type Props = {
  title: string;
  breadcrumbItems: BreadcrumbItem[];
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  hasHeader?: boolean;
};

const MainContainer = ({ title, breadcrumbItems, children, headerContent, hasHeader = true }: Props) => {
  const screens = useBreakpoint();
  const modifiedBreadcrumbItems = useMemo(() => {
    if (breadcrumbItems.length === 1) return breadcrumbItems;

    return breadcrumbItems.map((el) => {
      if (el.href)
        return {
          title: <Link to={el.href ?? ''}>{el.title}</Link>,
        };
      else return el;
    });
  }, [breadcrumbItems]);

  return (
    <Flex vertical>
      {hasHeader && (
        <Flex vertical={screens.xs} gap={8} justify="space-between" align={screens.xs ? 'flex-start' : 'center'}>
          <Flex vertical>
            <Title
              level={5}
              style={{
                color: themeToken['branding-primary-6'],
              }}
            >
              {title}
            </Title>
            <StyledBreadcrumb items={modifiedBreadcrumbItems} />
          </Flex>
          {headerContent && (
            <Flex gap={12} style={{ marginBottom: `${themeToken.margin}px` }}>
              {headerContent}
            </Flex>
          )}
        </Flex>
      )}
      {children}
    </Flex>
  );
};

export default MainContainer;
