import { axiosClient } from '@/lib/axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { CompanyValuationDashboardData } from '../../types';

export type CompanyDashboardResponse = {
  data: CompanyValuationDashboardData;
};

type CompanyDashboardParams = {
  urlParams: {
    companyID: number;
  };
};

export const getCompanyDashboard = async ({
  urlParams,
}: {
  urlParams: CompanyDashboardParams['urlParams'];
}): Promise<CompanyDashboardResponse> => {
  const { data } = await axiosClient.get<CompanyDashboardResponse>(
    `/companies/${urlParams.companyID}/valuation/dashboard`,
  );
  return data;
};

type QueryFnType = typeof getCompanyDashboard;

type UseGetCompanyDashboardOptions = {
  urlParams: CompanyDashboardParams['urlParams'];
  config?: QueryFunction<QueryFnType>;
};

export const UseGetCompanyDashboard = ({ urlParams, config }: UseGetCompanyDashboardOptions) => {
  return useQuery({
    queryKey: ['company-dashboard', urlParams],
    queryFn: () =>
      getCompanyDashboard({
        urlParams,
      }),
    enabled: !!urlParams.companyID,
    ...config,
  });
};
