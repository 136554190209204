import { SVGProps } from 'react';
const ActiveGraphIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#6430DA"
      fillOpacity={0.29}
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.35}
      d="M13.74 9c1.95 0 2.76-.75 2.04-3.21-.487-1.657-1.912-3.083-3.57-3.57C9.75 1.5 9 2.31 9 4.26v2.16C9 8.25 9.75 9 11.25 9h2.49Z"
    />
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M15 11.025a6.818 6.818 0 0 1-7.815 5.377c-2.842-.457-5.13-2.745-5.595-5.587a6.825 6.825 0 0 1 5.355-7.807"
    />
  </svg>
);
export default ActiveGraphIcon;
