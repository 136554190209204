import type { ColumnsType } from 'antd/es/table';
import type { CaptableOverviewTableData, DetailedCaptaibleTableData } from '../types';
import { Flex, Tag } from 'antd';
import { TFunction } from 'i18next';

import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { formatNumber } from '@/utils/formatNumber';

export const ClassColorTag = ({ color }: { color: string }) => {
  return (
    <div
      style={{
        width: 12,
        height: 12,
        background: color ?? '#770931',
        borderRadius: 2,
      }}
    ></div>
  );
};

export const CaptableOverViewColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'overview'>,
): ColumnsType<CaptableOverviewTableData | Record<string, number>> => [
  {
    title: t('class'),
    dataIndex: 'equity_class',
    render: withLoadingSkeleton(isLoading, (equity_class: string, record) => (
      <Flex gap={8} align="center" wrap="wrap">
        <ClassColorTag color={record?.equity_class_color as string} /> <span>{equity_class}</span>
      </Flex>
    )),
  },
  {
    title: t('numberOfShares'),
    dataIndex: 'total_issued_shares',
    render: withLoadingSkeleton(isLoading, (total_issued_shares: number) => formatNumber(total_issued_shares)),
  },
  {
    title: t('ownershipFDPercentage'),
    dataIndex: 'ownership',
    render: withLoadingSkeleton(
      isLoading,
      (ownership: string) => `${formatNumber(ownership, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`,
    ),
  },
];

export const DetailedCaptableColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'overview'>,
): ColumnsType<DetailedCaptaibleTableData> => [
  {
    title: t('shareholder'),
    dataIndex: 'shareholder',
    render: withLoadingSkeleton(isLoading, (shareholder: string) => shareholder),
  },
  {
    title: t('prefix'),
    dataIndex: 'grant_id',
    render: withLoadingSkeleton(isLoading, (grant_id: string[], record) => {
      const getTagColor = (index: number) => {
        if (record?.equity_type[index] === 'common') return 'magenta';
        else if (record?.equity_type[index] === 'preferred') return 'green';
        else return 'orange';
      };

      return (
        <div>
          {grant_id.slice(0, grant_id.length > 3 ? 2 : 3).map((id: string, index) => (
            <Tag key={id} color={getTagColor(index)}>
              {id}
            </Tag>
          ))}
          {grant_id.length > 3 && <Tag color="cyan">+{grant_id.length - 2}</Tag>}
        </div>
      );
    }),
  },
  {
    title: t('equityType'),
    dataIndex: 'equity_type',
    render: withLoadingSkeleton(isLoading, (equity_type: string[]) => {
      return (
        <div style={{ textTransform: 'capitalize' }}>
          {equity_type.every((val: string) => val === equity_type[0])
            ? `${equity_type.length > 1 ? t('all') : ''}  ${t(equity_type[0].toLowerCase())}`
            : [...new Set(equity_type)].map((el) => t(el.toLowerCase())).join(', ')}
        </div>
      );
    }),
  },
  {
    title: t('class'),
    dataIndex: 'equity_class',
    render: withLoadingSkeleton(isLoading, (equity_class: string[]) => {
      return (
        <div>
          {equity_class?.slice(0, 2).join(', ')}
          {equity_class?.length > 2 ? `, +${equity_class.length - 2}` : ''}
        </div>
      );
    }),
  },
  {
    title: t('numberOfShares'),
    dataIndex: 'issued_shares',
    render: withLoadingSkeleton(isLoading, (issued_shares: number) => formatNumber(issued_shares)),
  },
  {
    title: t('ownershipFDPercentage'),
    dataIndex: 'ownership',
    render: withLoadingSkeleton(
      isLoading,
      (ownership: string) => `${formatNumber(ownership, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`,
    ),
  },
];
