import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import { TooltipProps } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import themeToken from '@lib/theme/tokens/index';
import { formatNumber } from '@/utils/formatNumber';

import type { Option } from '../../types';

const { Text } = Typography;

const VestingCustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  if (active && payload?.length) {
    return (
      <Flex
        className="custom-tooltip"
        gap={8}
        vertical
        style={{
          padding: '8px 16px',
          borderRadius: '8px 8px 8px 0px',
          background: themeToken.suggestedNameColor,
        }}
      >
        <Flex gap={16} justify="space-between">
          <Flex vertical>
            <Text
              style={{
                color: themeToken.white,
                fontSize: 12,
              }}
            >
              {t('percentage')}
            </Text>
            <Text
              style={{
                color: themeToken.white,
                fontSize: 14,
              }}
              strong
            >
              {formatNumber((payload[0].payload as Option).shares_percentage)}%
            </Text>
          </Flex>
          <Flex vertical>
            <Text
              style={{
                color: themeToken.white,
                fontSize: 12,
              }}
            >
              {t('totalVested')}
            </Text>
            <Text
              style={{
                color: themeToken.white,
                fontSize: 14,
              }}
              strong
            >
              {`${formatNumber((payload[0].payload as Option).vested_shares)} ${t('shares')}`}
            </Text>
          </Flex>
        </Flex>
        <Flex vertical>
          <Text
            style={{
              color: themeToken.white,
              fontSize: 14,
            }}
            strong
          >
            {(payload[0].payload as Option)?.date}
          </Text>
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default VestingCustomTooltip;
