import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import { Currency } from '@/types';

type CurrenciesResponse = {
  data: Currency[];
};

export const geCurrencies = async (): Promise<Currency[]> => {
  const { data } = await axiosClient.get<CurrenciesResponse>(`/misc/currencies`);
  return data?.data;
};
type QueryFnType = typeof geCurrencies;

type UseGeCurrenciesOptions = {
  config?: QueryFunction<QueryFnType>;
};

export const useGetCurrencies = ({ config }: UseGeCurrenciesOptions) => {
  return useQuery({
    queryKey: ['currencies'],
    queryFn: geCurrencies,
    ...config,
  });
};
