import { MutationFunction, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import { axiosClient } from '@/lib/axios';

type NotificationsParams = {
  companyID: number;
};

type NotificationsResponse = {
  message: string;
};

export const markAllNotificationsRead = async ({ companyID }: NotificationsParams): Promise<NotificationsResponse> => {
  const { data } = await axiosClient.put<NotificationsResponse>(`/companies/${companyID}/notifications/read`);
  return data;
};

type UseMarkAllNotificationReadOptions = {
  companyID?: number;
  config?: MutationFunction<typeof markAllNotificationsRead>;
  postSuccess?: () => void;
};

export const useMarkAllNotificationsRead = ({ companyID, postSuccess, config }: UseMarkAllNotificationReadOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error: AxiosError<{ message: string }>) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data?.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['company', companyID],
      });

      postSuccess?.();
    },
    ...config,
    mutationFn: markAllNotificationsRead,
  });
};
