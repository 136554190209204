import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { axiosClient } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

type ConsultantsResponseData = {
  message: string;
};

type ConsultantsParams = {
  consultantID?: number;
  body: { event_url: string };
};

export const bookConsultant = async ({ consultantID, body }: ConsultantsParams): Promise<ConsultantsResponseData> => {
  const { data } = await axiosClient.put<ConsultantsResponseData>(`/consultations/${consultantID}/book-event`, body);
  return data;
};
type UseBookConsultantOptions = {
  config?: Partial<
    UseMutationOptions<ConsultantsResponseData, AxiosError<{ message?: string; error?: string }>, ConsultantsParams>
  >;
  postSuccess?: () => void;
};

export const useBookConsultant = ({ config, postSuccess }: UseBookConsultantOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message ?? error.response?.data.error,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['consultant'],
      });
      toaster.success({
        message: 'Booked Successfully',
        description: 'Consultant Booked Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: bookConsultant,
  });
};
