import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import axiosClient from '@/lib/axios';

import type { CreateNewFolderPayload, CreateNewFolderResponse } from '../types';

type CreateNewFolderParams = {
  company_id: number;
  payload: CreateNewFolderPayload;
};

export const createNewFolder = async ({ company_id, payload }: CreateNewFolderParams) => {
  const response = await axiosClient.post<CreateNewFolderResponse>(
    `/companies/${company_id}/data-rooms/folders`,
    payload,
  );

  return response.data;
};

type UseCreateNewFoldersDTO = {
  config?: Partial<
    UseMutationOptions<
      CreateNewFolderResponse,
      AxiosError<{ message: string }>,
      { company_id: number; payload: CreateNewFolderPayload }
    >
  >;
  parentFolderLevel: number;
  postSuccess?: () => void;
};

export const useCreateNewFolder = ({ parentFolderLevel, postSuccess, config }: UseCreateNewFoldersDTO) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    onSuccess: async () => {
      if (parentFolderLevel === 1)
        await queryClient.invalidateQueries({
          queryKey: ['folders'],
        });
      else
        await queryClient.invalidateQueries({
          queryKey: ['folder-data'],
        });

      await queryClient.invalidateQueries({
        queryKey: ['nested_folders'],
      });

      toaster.success({
        message: 'Added Successfully',
        description: 'Your Folder Added to data room Successfully',
      });
      postSuccess?.();
    },
    mutationFn: createNewFolder,
    ...config,
  });
};
