import styled from 'styled-components';
import { Card } from 'antd';

import themeToken from '@lib/theme/tokens/index';

export const StyledDirectoryCard = styled(Card)`
  &.ant-card {
    .ant-card-body {
      padding: ${themeToken.paddingXS}px ${themeToken.paddingSM}px;
      border-radius: 0 0 ${themeToken.borderRadiusSM}px ${themeToken.borderRadiusSM}px;
    }
  }
`;
