import { Permissions } from '@/types/permissions';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface PermissionsState {
  permissions: Permissions[];
  setPermissions: (permissions: Permissions[]) => void;
}

export const usePermissionsStore = create<PermissionsState>()(
  devtools(
    persist(
      (set) => ({
        permissions: [],
        setPermissions: (permissions) => set(() => ({ permissions })),
      }),
      { name: 'permissionsStore' },
    ),
  ),
);
