import { App } from 'antd';
import { axiosClient } from '@/lib/axios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { Company, CompanyPayloadData } from '../types';
import { AxiosError } from 'axios';

type CompanyPayload = {
  body: CompanyPayloadData;
};

export const addCompany = async ({ body }: CompanyPayload): Promise<{ data: Company }> => {
  const { data } = await axiosClient.post<{ data: Company }>(`/companies`, body);
  return data;
};
type UseAddCompanyOptions = {
  config?: Partial<UseMutationOptions<{ data: Company }, AxiosError<{ message: string }>, CompanyPayload>>;
  postSuccess?: (companyData: Company) => void;
};

export const useAddCompany = ({ config, postSuccess }: UseAddCompanyOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async (companyData) => {
      await queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      toaster.success({
        message: 'Added Successfully',
        description: 'Company Added Successfully',
      });
      postSuccess?.({ ...companyData.data, is_owner: true });
    },
    ...config,
    mutationFn: addCompany,
  });
};
