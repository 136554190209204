import styled from 'styled-components';
import { Flex, Grid } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { useBreakpoint } = Grid;

const StyledWrapper = styled(Flex)`
  &.ant-flex {
    & > .ant-btn {
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
`;

type Props = {
  gap?: number;
  justify?: 'center' | 'start' | 'end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';
  children: React.ReactNode;
};

const HeaderWrapper = ({ gap = 4, justify = 'space-between', children }: Props) => {
  const screens = useBreakpoint();

  return (
    <StyledWrapper
      justify={justify}
      style={{
        marginBottom: themeToken.margin,
      }}
      align={!screens.sm ? 'flex-start' : 'center'}
      gap={gap}
      vertical={!screens.sm}
    >
      {children}
    </StyledWrapper>
  );
};

export default HeaderWrapper;
