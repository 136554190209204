import { Steps } from 'antd';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

export const StyledStepsBanner = styled(Steps)`
  &.ant-steps.ant-steps-horizontal {
    .ant-steps-item {
      padding: 0;
      &-icon {
        background: ${themeToken['branding-secondary-4']};
        border-color: ${themeToken['branding-secondary-4']};
      }
      &-title {
        font-size: ${themeToken.fontSize}px;
        color: ${themeToken['branding-secondary-4']}!important;
      }
      ::after {
        background-color: ${themeToken.colorPrimaryHover} !important;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-title {
        color: ${themeToken['branding-secondary-1']} !important;
      }
      .ant-steps-item-icon {
        background-color: ${themeToken['branding-secondary-1']};
        border-color: ${themeToken['branding-secondary-1']};
      }
      .ant-steps-icon {
        color: ${themeToken['branding-primary-6']};
      }
    }
    .ant-steps-item-subtitle {
      color: ${themeToken.colorError};
      font-size: ${themeToken.fontSizeSM}px;
      font-weight: 400;
      margin-inline-start: 24px;
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${themeToken['branding-primary-6']};
  border-radius: ${themeToken.paddingXL}px;
  padding: 9px 8px 9px 24px;
  margin-bottom: 16px;
  align-items: center;
`;
