import { StyledButton } from '@lib/theme/components/Button';
import { Flex, Modal, Typography } from 'antd';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

const termsAndConditions = [
  'Repellendus commodi cum iure voluptatibus. Vitae voluptatem est. Facere quisquam cumque ullam sint eum ut. Doloremque non et cum sed excepturi. Commodi est beatae consequuntur. Impedit dolores sed sequi quo ea aperiam autem et iste.',

  'Asperiores et eos in dolores vel asperiores. Aut sequi dolorem ea eius distinctio aliquid sit enim. Velit aut eum sint eum nulla voluptatum. Repellat illum dolorum aut dicta dolor ratione ipsam. Non in impedit iste sed consequatur sed amet. Atque qui qui fugiat pariatur et repellat.',

  'Magnam quae necessitatibus sit hic consequuntur ut culpa vitae et. Ipsam voluptatem repudiandae velit sequi quae debitis vero velit tempore. Optio omnis libero ut natus ex qui ipsam. Reprehenderit est voluptate mvoluptas nemo incidunt dignissimos tenetur numquam. Ex quo in non harum. Eum deserunt corrupti recusandae et voluptas sit fugiat consequatur.',

  'Asperiores et eos in dolores vel asperiores. Aut sequi dolorem ea eius distinctio aliquid sit enim. Velit aut eum sint eum nulla voluptatum. Repellat illum dolorum aut dicta dolor ratione ipsam. Non in impedit iste sedconsequatur sed amet. Atque qui qui fugiat pariatur et repellat.',
];

const { Text, Title } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 700px;
    width: max-content;
    .ant-modal-body {
      flex-direction: column;
      display: flex;
      align-items: center;
      h3 {
        margin-top: ${themeToken.marginXXS}px;
        margin-bottom: ${themeToken.marginLG}px;
      }
    }
    .ant-modal-footer {
      margin-top: ${themeToken.marginLG}px;
    }
  }
`;

const TermsAndConditionsModal = ({
  isTermsAndConditionsModalOpen,
  setIsTermsAndConditionsModalOpen,
}: {
  isTermsAndConditionsModalOpen: boolean;
  setIsTermsAndConditionsModalOpen: (value: boolean) => void;
}) => {
  return (
    <StyledModal
      centered
      className="modal-container"
      open={isTermsAndConditionsModalOpen}
      footer={[
        <StyledButton
          size="large"
          type="primary"
          key="submit"
          style={{
            width: '100%',
          }}
          onClick={() => setIsTermsAndConditionsModalOpen(false)}
        >
          Done
        </StyledButton>,
      ]}
      onCancel={() => setIsTermsAndConditionsModalOpen(false)}
    >
      <Text
        style={{
          color: themeToken['branding-natural-6'],
        }}
      >
        You Should Know
      </Text>
      <Title level={3}>Rasmal Terms & conditions</Title>
      <Flex vertical gap={30}>
        {termsAndConditions.map((term, index) => (
          <Text
            key={index}
            style={{
              color: themeToken['branding-natural-9'],
            }}
          >
            {term}
          </Text>
        ))}
      </Flex>
    </StyledModal>
  );
};

export default TermsAndConditionsModal;
