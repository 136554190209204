import styled from 'styled-components';
import { Table } from 'antd';
import themeToken from '../tokens';

const defaultTheme = {
  headerColor: themeToken['table-color-text'],
  colorText: themeToken['table-color-text'],
  borderColor: themeToken['table-color-split'],
  headerSplitColor: themeToken['table-color-split'],
  headerBg: themeToken['table-color-fill-alter'],
  colorIcon: themeToken['table-color-text-disabled'],
};

export const tableTheme = {
  ...defaultTheme,
};

export const StyledTable = styled(Table)<{ hasTitle: boolean }>`
  .ant-table-cell-fix-left {
    @media (max-width: ${themeToken.screenXS}px) {
      background: white;
    }
  }
  &.ant-table-wrapper {
    .ant-pagination {
      justify-content: center;
      align-items: center;
      @media (min-width: ${themeToken.screenMD}px) {
        justify-content: flex-end;
      }
    }
    .ant-table {
      & > .ant-table-title {
        border: 0;
        padding: ${(props) => (props.hasTitle ? `0 0 ${themeToken.padding}px` : 0)};
        line-height: ${themeToken.sizeLG}px;
      }

      .ant-table-container {
        overflow: auto;
        border-radius: 6px;
        border: 1px solid ${themeToken['branding-natural-1']};

        & > .ant-table-content {
          .table-wrapper table {
            table-layout: fixed !important;
          }

          .ant-table-thead {
            height: 72px;
          }

          th {
            padding: ${themeToken.padding}px ${themeToken.paddingXS}px;
            .ant-table-column-title {
              font-size: ${themeToken.fontSizeSM}px;
              line-height: ${themeToken.sizeMD}px;
            }
          }

          .ant-table-tbody {
            td {
              border: 0.5px solid #0000000f;
            }

            .ant-table-row-expand-icon-cell {
              border-right: 0;
            }

            .ant-table-row-expand-icon-cell + td {
              border-left: 0;
            }
            .expand-parent {
              td {
                background: ${themeToken['branding-natural-5']};
                border-top: 1px solid ${themeToken['branding-secondary-2']} !important;

                &:first-child {
                  background: ${themeToken['branding-natural-5']};
                  border-bottom: 0;
                }

                &:nth-child(2) {
                  border-bottom: 0;
                }
              }
            }

            .ant-table-expanded-row {
              td {
                background: ${themeToken['branding-natural-5']};
              }
              .ant-table-expanded-row-fixed {
                border-bottom: 1px solid ${themeToken['branding-secondary-2']} !important;
              }
            }
          }

          .ant-table-summary {
            background: ${themeToken['branding-natural-5']};
            color: ${themeToken.colorPrimary};
            font-weight: ${themeToken.fontWeightStrong};

            td {
              border: 0.5px solid #0000000f;

              &:first-child {
                background: ${themeToken['branding-natural-5']};
              }
              background: inherit;

              .ant-typography {
                color: inherit;
              }
            }
          }

          .nestedTable {
            .ant-table {
              background: ${themeToken['branding-natural-5']};

              .ant-table-container {
                border: 0;
              }

              td {
                &:first-child {
                  border: initial;
                }
              }

              .ant-table-row-level-0 {
                td {
                  &:first-child {
                    border-left: 0;
                  }
                  &:nth-child(2) {
                    border-left: 0.5px solid #0000000f;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-table.ant-table-ping-left {
      th:first-child {
        background: #f0f0f0;
      }
    }
  }
`;
