import { Select } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  multipleItemBg: themeToken.optionSelectedBgColor,
  optionSelectedBg: themeToken.optionSelectedBgColor,
  optionActiveBg: themeToken.colorBgTextHover,
};

const globalTheme = {
  borderRadius: themeToken.borderRadiusSM,
};

export const selectTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledSelect = styled(Select)`
  &.ant-select {
    width: 100%;
    & > .ant-select-selector {
      padding: ${themeToken.paddingXXS}px ${themeToken.paddingSM}px;
    }
  }
`;
