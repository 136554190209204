import { Tree } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const { DirectoryTree } = Tree;

const defaultTheme = {
  directoryNodeSelectedBg: themeToken['branding-secondary-1'],
  directoryNodeSelectedColor: themeToken.colorText,
  titleHeight: 28,
};

export const treeTheme = {
  ...defaultTheme,
};

export const StyledTree = styled(Tree)`
  &.ant-tree {
  }
`;
export const StyledDirectoryTree = styled(DirectoryTree)`
  &.ant-tree {
  }
`;
