import { useEffect } from 'react';
import { useCalendlyEventListener } from 'react-calendly';
import { useBookConsultant } from './features/consultation/api/bookConsultant';
import { useSelectedConsultantStore } from './stores/selectedConsultant';
import { useLocation } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import chameleon from '@chamaeleonidae/chmln';
import AppRoutes from './routes';
import { useMixpanel } from './hooks/useMixpanel';
import { useTrackEvent } from './hooks/useTrackEvents';
import { useUserDataStore } from './stores/UserData';

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;
const MIXPANEL_CONFIG = {
  debug: import.meta.env.MODE === 'development',
  ignore_dnt: true,
};

const siteId = {
  staging: 3657639,
  production: 3657505,
};
const hotjarVersion = 6;

if (import.meta.env.MODE === 'production')
  Hotjar.init(siteId.production, hotjarVersion, {
    nonce: 'rAnDoM',
  });
else
  Hotjar.init(siteId.staging, hotjarVersion, {
    nonce: 'rAnDoM',
  });

chameleon.init('SGB71XDIC39VnbTBTkZFxMd3KCTCD9KjVBTvifwXnVGHBh-1Rd0SS-EQasVvFjq7noAVKc', {
  fastUrl: 'https://fast.chameleon.io/',
});

function App() {
  const location = useLocation();
  const { sendMixpanelEvent, identifyMixpanelUser } = useTrackEvent({ isPageView: true });
  const { selectedConsultant } = useSelectedConsultantStore();
  const { mutate: bookConsultant } = useBookConsultant({});
  const mixpanel = useMixpanel();
  const { userData } = useUserDataStore((state) => state);

  useCalendlyEventListener({
    onEventScheduled: (event) => {
      if (selectedConsultant) {
        const body = {
          event_url: event.data.payload.event.uri,
        };
        bookConsultant({
          consultantID: selectedConsultant,
          body,
        });
      }
    },
  });

  useEffect(() => {
    mixpanel?.init(MIXPANEL_TOKEN, MIXPANEL_CONFIG, 'rasmal-mixpanel-tracking');
    identifyMixpanelUser(userData?.userInfo.mail as string);
  }, []);

  useEffect(() => {
    if (userData?.accessToken) {
      const { uid, mail, name } = userData.userInfo;
      /* chameleon.io user identification and data */
      chameleon.identify(uid, {
        email: mail,
        name,
      });
    }
  }, [userData]);

  useEffect(() => {
    const page = location.pathname
      .split('/')
      .filter((val) => val)
      .join('-');

    sendMixpanelEvent(page);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <AppRoutes />;
}

export default App;
