import { useMemo } from 'react';
import { Flex } from 'antd';
import { Outlet } from 'react-router-dom';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import PinnedNotification from './Shared/PinnedNotification';
import dayjs from 'dayjs';
import { LimitsCheckerContext } from './LimitsChecker';

const ExpiryChecker = () => {
  const { expiresAt } = useSelectedCompanyStore((state) => state);

  const expiryInterval = useMemo(() => {
    if (expiresAt) {
      const currentDate = dayjs(new Date());
      const planExpiry = dayjs(new Date(expiresAt));
      const diff = Math.floor(planExpiry.diff(currentDate, 'day', true));
      return diff;
    }
    return null;
  }, [expiresAt]);

  return (
    <Flex vertical>
      {expiryInterval && expiryInterval <= 60 && (
        <PinnedNotification
          type="info"
          expiryInterval={expiryInterval < 0 ? 0 : expiryInterval}
          expiryDate={expiresAt}
        />
      )}

      <Flex
        vertical
        style={{
          marginTop: expiryInterval && expiryInterval <= 60 ? '56px' : 0,
        }}
      >
        <Outlet
          context={
            {
              recheckLimits: () => {},
              limitations: [],
              disableRecordsAddition: (expiryInterval && expiryInterval <= 0) || false,
            } satisfies LimitsCheckerContext
          }
        />
      </Flex>
    </Flex>
  );
};

export default ExpiryChecker;
