import { SVGProps } from 'react';
const ActiveUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M13.605 16.215c-.66.195-1.44.285-2.355.285h-4.5c-.915 0-1.695-.09-2.355-.285.165-1.95 2.168-3.488 4.605-3.488 2.438 0 4.44 1.538 4.605 3.488Z"
    />
    <path
      fill="#6430DA"
      fillOpacity={0.29}
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.35}
      d="M11.25 1.5h-4.5C3 1.5 1.5 3 1.5 6.75v4.5c0 2.835.855 4.387 2.895 4.965.165-1.95 2.168-3.488 4.605-3.488 2.438 0 4.44 1.538 4.605 3.488 2.04-.578 2.895-2.13 2.895-4.965v-4.5C16.5 3 15 1.5 11.25 1.5ZM9 10.627a2.689 2.689 0 0 1-2.685-2.692C6.315 6.45 7.515 5.25 9 5.25s2.685 1.2 2.685 2.685-1.2 2.692-2.685 2.692Z"
    />
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M11.685 7.935c0 1.485-1.2 2.692-2.685 2.692a2.689 2.689 0 0 1-2.685-2.692C6.315 6.45 7.515 5.25 9 5.25s2.685 1.2 2.685 2.685Z"
    />
  </svg>
);
export default ActiveUserIcon;
