import themeToken from '@lib/theme/tokens/index';
const LogoIcon = ({ fill = themeToken['branding-primary-6'] }: { fill?: string }) => {
  return (
    <svg width="102" height="36" viewBox="0 0 102 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_531_621)">
        <path
          d="M1.56982 26.3882V26.8698H9.45223V26.3882L7.63321 25.9067V17.899H9.45223L12.1808 24.3061C12.9398 25.9067 13.9998 26.7092 15.3651 26.7092H19.1558V26.2277L17.7905 25.7462L15.3651 20.1417C14.9093 19.0204 13.9998 18.3806 12.7871 17.899C12.3313 17.7385 11.7249 17.578 10.9681 17.578C12.0302 17.578 13.0903 17.4175 14.1524 16.9382C15.0619 16.6172 15.8209 15.9774 16.4273 15.3376C17.0336 14.5373 17.3368 13.7369 17.3368 12.4551C17.3368 10.6939 16.7304 9.4121 15.3651 8.45127C13.9998 7.49044 11.8776 7.00891 8.69538 7.00891H1.56982V7.49044L3.23833 7.97197V26.2277L1.56982 26.3882ZM7.63321 7.65095H8.69538C9.90806 7.65095 10.9702 8.13248 11.7271 8.9328C12.4839 9.73312 12.7892 11.015 12.7892 12.4551C12.7892 13.8952 12.4861 15.1771 11.7271 15.9774C10.9681 16.7777 10.0586 17.2592 8.69538 17.2592H7.63321V7.65095Z"
          fill={fill}
        />
        <path
          d="M23.7032 19.4819C22.7937 19.6401 22.0347 19.7962 21.2779 19.9545C21.1252 20.2688 20.9747 20.5831 20.822 20.8975C20.5189 21.6844 20.3662 22.3131 20.3662 23.0978C20.3662 24.1991 20.822 25.1421 21.7315 25.7707C22.6411 26.5577 23.8537 26.872 25.3696 26.872C26.5823 26.872 27.6444 26.3994 28.4013 25.6147C28.7044 25.3003 29.0076 24.8277 29.3108 24.3573C29.4634 24.83 29.614 25.1443 29.9171 25.4586C30.5235 26.0873 31.2825 26.5599 32.4951 26.5599H34.9205V26.0873L33.252 25.6147V18.0707C33.252 16.9694 32.9488 15.8704 32.493 14.9274C32.0372 13.9844 31.1276 13.3557 30.2181 12.8831C29.156 12.4105 27.9433 12.0962 26.5801 12.0962C24.9116 12.0962 23.6989 12.4105 22.7894 13.0392C21.8799 13.6678 21.5767 14.2965 21.5767 15.2395C21.5767 15.8682 21.7294 16.3408 22.1831 16.8112C22.4862 17.1255 23.0926 17.4398 23.6989 17.4398H24.4579L24.7611 17.2815V13.037L25.0643 12.8787C25.3674 12.7204 25.6706 12.7204 25.9738 12.7204C27.0359 12.7204 27.7928 13.0347 28.2486 13.8217C28.7044 14.6086 29.0076 16.022 29.0076 18.0663V18.8532C27.3391 18.8532 25.5201 19.1675 23.7011 19.4819H23.7032ZM28.8571 23.5682C28.7044 24.0408 28.4013 24.3551 28.2508 24.6695C27.6444 25.4564 27.0381 25.7707 26.4317 25.7707C25.9759 25.7707 25.5222 25.4564 25.2191 24.9838C24.9159 24.5112 24.7632 23.7265 24.7632 22.6252C24.7632 21.9965 24.9159 21.2096 25.0664 20.5809C25.2191 20.2666 25.2191 19.9523 25.3696 19.794C25.5222 19.794 25.9759 19.794 26.4317 19.6357C27.1907 19.4774 27.9476 19.4774 28.8571 19.4774V23.5637V23.5682Z"
          fill={fill}
        />
        <path
          d="M42.1963 26.2411C40.831 26.2411 39.6183 25.4542 38.7088 24.0408C38.253 23.4121 37.9498 22.6252 37.6466 21.8405H36.5845V26.5555H37.1908C37.3435 26.3972 37.494 26.3972 37.7972 26.2411C38.1003 26.0851 38.4035 25.7685 38.7067 25.2981C39.0098 25.6125 39.313 25.7707 39.7688 26.0851C40.6783 26.5577 41.5878 26.872 42.8005 26.872C46.8944 26.872 49.0166 25.4564 49.0166 22.7857C49.0166 21.9988 48.8639 21.3701 48.4102 20.8997C47.9544 20.2711 47.5007 19.7984 46.8944 19.4841C46.288 19.1698 45.529 18.6972 44.6195 18.2268C43.4069 17.5981 42.6479 17.1255 42.0415 16.6551C41.4352 16.1825 41.132 15.5539 41.132 14.9252C41.132 14.2965 41.2847 13.9822 41.5878 13.5096C41.891 13.1953 42.3468 12.8809 42.8005 12.8809C44.0132 12.8809 45.0754 13.6679 45.9849 15.0812C46.4407 15.7099 46.7439 16.4968 47.047 17.2816H48.1092V12.5666H47.5029L46.8965 12.8809C46.4407 13.1953 46.1375 13.5096 45.987 13.8239C45.6839 13.5096 45.3807 13.3513 44.9249 13.037C44.1659 12.5644 43.2563 12.25 42.3468 12.25C40.3752 12.25 39.012 12.5644 38.1025 13.3513C37.193 13.98 36.7371 14.923 36.7371 16.0242C36.7371 16.8112 36.8898 17.5959 37.3435 18.2246C37.7993 18.8532 38.253 19.3258 38.8593 19.7962C39.4657 20.2666 40.2247 20.5832 41.2847 21.2118C42.3468 21.8405 43.2563 22.3131 43.71 22.7835C44.1659 23.2561 44.469 23.7265 44.469 24.3551C44.469 25.1421 44.3164 25.6125 43.8627 26.0851C43.5595 26.0851 42.9532 26.2434 42.1942 26.2434L42.1963 26.2411Z"
          fill={fill}
        />
        <path
          d="M69.7869 7.68887C69.331 8.00097 68.8774 8.62518 68.5742 9.4032L64.0267 20.9421L58.1138 9.4032C57.658 8.62295 57.2043 7.99874 56.7484 7.68887C56.1421 7.22072 55.3831 7.06467 54.4736 7.06467H51.1387V7.53282L52.8072 8.00097V25.3093L50.6851 26.0895V26.5577H55.8389V26.0895L53.7168 25.3093V10.6516L61.9023 26.8698H62.6613L69.0279 10.6516V25.7774L67.3594 26.2456V26.7137H75.0913V26.2456L73.4228 25.7774V8.00097L75.0913 7.53282V7.06467H72.2101C71.1479 7.06467 70.2384 7.22072 69.7847 7.68887H69.7869Z"
          fill={fill}
        />
        <path
          d="M92.0713 25.9268L90.4027 25.4542V17.9102C90.4027 16.8089 90.0996 15.7099 89.6437 14.7669C89.1879 13.8239 88.2784 13.1952 87.3689 12.7226C86.3067 12.25 85.0941 11.9357 83.7309 11.9357C82.0624 11.9357 80.8497 12.25 79.9402 12.8787C79.0307 13.5073 78.7275 14.136 78.7275 15.079C78.7275 15.7076 78.8802 16.1803 79.3338 16.6506C79.637 16.965 80.2433 17.2793 80.8497 17.2793H81.6087L81.9119 17.121V12.8764L82.215 12.7182C82.5182 12.5599 82.8214 12.5599 83.1245 12.5599C84.1867 12.5599 84.9435 12.8742 85.3994 13.6611C85.8552 14.4481 86.1584 15.8615 86.1584 17.9057V18.8487C84.6425 18.8487 82.974 19.1631 81.0045 19.4774C80.095 19.6357 79.336 19.7917 78.5791 19.95C78.4265 20.2643 78.276 20.5787 78.1233 20.893C77.8201 21.6799 77.6675 22.3086 77.6675 23.0933C77.6675 24.1946 78.1233 25.1376 79.0328 25.7663C79.9423 26.5532 81.155 26.8675 82.6709 26.8675C83.8835 26.8675 84.9457 26.3949 85.7025 25.6102C86.0057 25.2959 86.3089 24.8233 86.6121 24.3529C86.7647 24.8255 86.9152 25.1398 87.2184 25.4542C87.8247 26.0828 88.5837 26.5554 89.7964 26.5554H92.2218L92.0713 25.9268ZM86.1584 23.5682C86.0057 24.0408 85.7025 24.3551 85.552 24.6694C84.9457 25.4564 84.3394 25.7707 83.733 25.7707C83.2772 25.7707 82.8235 25.4564 82.5203 24.9838C82.2172 24.5112 82.0645 23.7264 82.0645 22.6252C82.0645 21.9965 82.2172 21.2096 82.3677 20.5809C82.5203 20.2666 82.5203 19.9522 82.6709 19.794C82.8235 19.794 83.2772 19.794 83.733 19.6357C84.492 19.4774 85.2489 19.4774 86.1584 19.4774V23.5637V23.5682Z"
          fill={fill}
        />
        <path
          d="M99.6502 9.57932C99.6502 7.84938 98.8912 7.06467 97.2248 7.06467H93.7373V7.53728L95.4058 8.00989V25.9268L93.7373 26.3994V26.872H101.317V26.3994L99.648 25.9268V9.57932H99.6502Z"
          fill={fill}
        />
        <path d="M65.8115 30.8491H0.660156V33.5733H65.8115V30.8491Z" fill={fill} />
        <path d="M101.34 0H0.681641V2.71529H101.34V0Z" fill={fill} />
        <path
          d="M76.3555 32.2647H73.4571C73.47 32.5389 73.5345 32.7729 73.6485 32.9669C73.7624 33.1608 73.9151 33.308 74.1021 33.4083C74.2892 33.5086 74.4935 33.5577 74.7171 33.5577C75.0073 33.5577 75.2503 33.4841 75.4503 33.3392C75.6481 33.1943 75.7793 32.9959 75.8438 32.7484H76.3168C76.2308 33.1029 76.048 33.3905 75.7706 33.6134C75.4911 33.8363 75.1407 33.9478 74.7192 33.9478C74.3903 33.9478 74.0957 33.872 73.8355 33.7182C73.5754 33.5643 73.3711 33.3481 73.2249 33.0672C73.0787 32.7863 73.0034 32.4586 73.0034 32.0819C73.0034 31.7051 73.0765 31.3774 73.2206 31.0943C73.3646 30.8112 73.5689 30.5949 73.8291 30.4456C74.0892 30.2962 74.386 30.2204 74.7192 30.2204C75.0525 30.2204 75.3428 30.2962 75.5922 30.4456C75.8416 30.5949 76.033 30.7978 76.1684 31.0519C76.3039 31.3061 76.3705 31.5892 76.3705 31.8968C76.3705 32.0551 76.3662 32.1777 76.3576 32.2624L76.3555 32.2647ZM75.7577 31.1968C75.6481 31.0073 75.4997 30.8624 75.3127 30.7666C75.1256 30.6707 74.9192 30.6217 74.6977 30.6217C74.3645 30.6217 74.0806 30.7331 73.8463 30.9538C73.6119 31.1745 73.4808 31.4866 73.4571 31.8879H75.9126C75.9169 31.6182 75.8653 31.3886 75.7556 31.1991L75.7577 31.1968Z"
          fill={fill}
        />
        <path
          d="M77.2006 31.101C77.3446 30.8201 77.5446 30.6039 77.7983 30.4523C78.052 30.3007 78.338 30.2227 78.6584 30.2227C78.9787 30.2227 79.2733 30.3029 79.5163 30.4656C79.7592 30.6262 79.9312 30.829 80.0344 31.072V30.2762H80.4817V35.6086H80.0344V33.1029C79.9312 33.3459 79.7571 33.5488 79.5141 33.7093C79.269 33.8698 78.9809 33.9523 78.6476 33.9523C78.3315 33.9523 78.0477 33.8742 77.794 33.7204C77.5403 33.5666 77.3425 33.3481 77.1984 33.065C77.0544 32.7819 76.9834 32.4542 76.9834 32.0841C76.9834 31.7141 77.0565 31.3863 77.2006 31.1055V31.101ZM79.8624 31.3172C79.7463 31.0965 79.5915 30.9249 79.3916 30.8067C79.1937 30.6886 78.9744 30.6284 78.7358 30.6284C78.4971 30.6284 78.267 30.6864 78.0714 30.8C77.8757 30.916 77.7209 31.0809 77.6069 31.3016C77.493 31.5223 77.4371 31.7809 77.4371 32.0819C77.4371 32.3828 77.493 32.637 77.6069 32.8599C77.7209 33.0828 77.8736 33.2545 78.0714 33.3704C78.267 33.4886 78.4885 33.5465 78.7358 33.5465C78.983 33.5465 79.1937 33.4864 79.3916 33.3682C79.5894 33.25 79.7463 33.0784 79.8624 32.8577C79.9785 32.637 80.0344 32.3806 80.0344 32.0886C80.0344 31.7965 79.9764 31.5402 79.8624 31.3195V31.3172Z"
          fill={fill}
        />
        <path
          d="M81.3483 33.8296C81.286 33.7628 81.2559 33.6825 81.2559 33.5844C81.2559 33.4863 81.286 33.4061 81.3483 33.3414C81.4107 33.2768 81.4881 33.2456 81.5805 33.2456C81.673 33.2456 81.7461 33.2768 81.8084 33.3414C81.8708 33.4061 81.9009 33.4863 81.9009 33.5844C81.9009 33.6825 81.8708 33.7628 81.8084 33.8296C81.7461 33.8965 81.6708 33.93 81.5805 33.93C81.4902 33.93 81.4085 33.8965 81.3483 33.8296Z"
          fill={fill}
        />
        <path
          d="M85.4398 30.6663H84.627V33.8965H84.1798V30.6663H83.6938V30.2739H84.1798V30.022C84.1798 29.6274 84.2765 29.3399 84.4701 29.1526C84.6636 28.9676 84.9775 28.8739 85.414 28.8739V29.273C85.1237 29.273 84.9194 29.3309 84.8033 29.4446C84.6872 29.5606 84.627 29.7523 84.627 30.022V30.2739H85.4398V30.6663Z"
          fill={fill}
        />
        <path
          d="M86.1363 29.4914C86.0718 29.4245 86.0396 29.3443 86.0396 29.2462C86.0396 29.1481 86.0718 29.0679 86.1363 29.0054C86.2008 28.9408 86.2761 28.9096 86.3664 28.9096C86.4567 28.9096 86.5319 28.9408 86.5964 29.0054C86.6609 29.0701 86.6932 29.1503 86.6932 29.2462C86.6932 29.3421 86.6609 29.4245 86.5964 29.4914C86.5319 29.5583 86.4567 29.5917 86.3664 29.5917C86.2761 29.5917 86.2008 29.5583 86.1363 29.4914ZM86.59 30.2739V33.8943H86.1428V30.2739H86.59Z"
          fill={fill}
        />
        <path
          d="M90.2691 30.6105C90.5336 30.8781 90.6647 31.2659 90.6647 31.7742V33.8965H90.2239V31.8277C90.2239 31.4354 90.1293 31.1344 89.9401 30.9271C89.7509 30.7198 89.4907 30.615 89.1639 30.615C88.8371 30.615 88.5576 30.7265 88.3576 30.9472C88.1577 31.1679 88.0566 31.4933 88.0566 31.9214V33.8965H87.6094V30.2761H88.0566V30.8937C88.1684 30.673 88.3275 30.5035 88.5361 30.3854C88.7446 30.2672 88.9812 30.2093 89.2456 30.2093C89.6628 30.2093 90.0046 30.343 90.2691 30.6105Z"
          fill={fill}
        />
        <path
          d="M91.4987 33.8296C91.4364 33.7628 91.4062 33.6825 91.4062 33.5844C91.4062 33.4863 91.4364 33.4061 91.4987 33.3414C91.5611 33.2768 91.6385 33.2456 91.7309 33.2456C91.8234 33.2456 91.8965 33.2768 91.9588 33.3414C92.0212 33.4061 92.0513 33.4863 92.0513 33.5844C92.0513 33.6825 92.0212 33.7628 91.9588 33.8296C91.8965 33.8965 91.8212 33.93 91.7309 33.93C91.6406 33.93 91.5589 33.8965 91.4987 33.8296Z"
          fill={fill}
        />
        <path
          d="M94.1221 31.101C94.2662 30.8201 94.4683 30.6039 94.7242 30.4523C94.9801 30.3007 95.2725 30.2227 95.6014 30.2227C96.0315 30.2227 96.3862 30.3341 96.6658 30.5548C96.9453 30.7755 97.1259 31.0765 97.2054 31.4577H96.726C96.6658 31.1969 96.5367 30.9918 96.3389 30.8446C96.1411 30.6975 95.8939 30.6217 95.5993 30.6217C95.3649 30.6217 95.1542 30.6774 94.965 30.7867C94.7779 30.8981 94.6296 31.0631 94.5199 31.2816C94.4103 31.5 94.3565 31.7698 94.3565 32.0863C94.3565 32.4029 94.4103 32.6749 94.5199 32.8956C94.6296 33.1163 94.7758 33.2813 94.965 33.3927C95.1521 33.502 95.3628 33.5577 95.5993 33.5577C95.8939 33.5577 96.139 33.4841 96.3389 33.3348C96.5389 33.1854 96.6658 32.9803 96.726 32.715H97.2054C97.1237 33.0873 96.9431 33.3838 96.6615 33.6112C96.3798 33.8386 96.025 33.95 95.5993 33.95C95.2703 33.95 94.9779 33.8742 94.722 33.7204C94.4662 33.5666 94.2662 33.3504 94.12 33.0695C93.9759 32.7886 93.9028 32.4609 93.9028 32.0841C93.9028 31.7074 93.9759 31.3797 94.12 31.0988L94.1221 31.101Z"
          fill={fill}
        />
        <path
          d="M98.674 33.7204C98.4117 33.5666 98.2053 33.3503 98.0526 33.0694C97.9021 32.7886 97.8247 32.4608 97.8247 32.0841C97.8247 31.7073 97.9021 31.3796 98.0548 31.0987C98.2074 30.8179 98.4181 30.6016 98.6805 30.45C98.9449 30.2984 99.2417 30.2204 99.5685 30.2204C99.8953 30.2204 100.194 30.2962 100.461 30.45C100.727 30.6038 100.936 30.8201 101.086 31.0987C101.237 31.3796 101.314 31.7073 101.314 32.0841C101.314 32.4608 101.237 32.7819 101.084 33.065C100.932 33.3481 100.719 33.5666 100.452 33.7182C100.183 33.8698 99.8845 33.9478 99.5556 33.9478C99.2266 33.9478 98.932 33.872 98.6697 33.7182L98.674 33.7204ZM100.198 33.3815C100.394 33.2723 100.553 33.1096 100.676 32.8911C100.798 32.6726 100.859 32.4029 100.859 32.0863C100.859 31.7698 100.798 31.5 100.68 31.2815C100.56 31.0631 100.403 30.9003 100.207 30.7911C100.011 30.6841 99.7985 30.6284 99.5685 30.6284C99.3384 30.6284 99.1255 30.6819 98.9299 30.7911C98.7342 30.9003 98.5773 31.0631 98.459 31.2815C98.3407 31.5 98.2827 31.7698 98.2827 32.0863C98.2827 32.4029 98.3407 32.6726 98.459 32.8911C98.5751 33.1096 98.7321 33.2723 98.9256 33.3815C99.1191 33.4908 99.332 33.5443 99.562 33.5443C99.7921 33.5443 100.005 33.4908 100.201 33.3815H100.198Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_531_621">
          <rect width="100.68" height="35.6064" fill="white" transform="translate(0.660156)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
