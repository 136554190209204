export default {
  addOns: 'الإضافات',
  legalAdvisor: 'مستشار قانوني',
  consultationServices: 'خدمات استشارية',
  consultantDetails: 'تفاصيل الاستشاري',
  description: 'الوصف',
  aboutCompany: 'حول الشركة',
  workWith: 'عمل مع',
  canHelpWith: 'يمكن المساعدة في',
  hour: 'ساعة',
  bookNow: 'احجز الان',
  viewDetails: 'عرض التفاصيل',
  partners: 'الشركاء',
};
