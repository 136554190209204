import { SVGProps } from 'react';
const ActivePresentationChart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#6430DA"
      fillOpacity={0.29}
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.35}
      d="M4.425 12.75h9.142c1.425 0 2.175-.75 2.175-2.175V1.5h-13.5v9.075C2.25 12 3 12.75 4.425 12.75Z"
    />
    <path
      stroke="#6430DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.35}
      d="M1.5 1.5h15M6 16.5 9 15v-2.25M12 16.5 9 15M5.625 8.25l2.362-1.973c.188-.157.435-.112.563.098l.9 1.5c.128.21.375.248.563.098L12.374 6"
    />
  </svg>
);
export default ActivePresentationChart;
