import { useTranslation } from 'react-i18next';
import { Typography, Flex } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { StyledSharednModal } from '@/components/Shared/StyledSharedModal';
import { StyledButton } from '@/lib/theme/components/Button';
import WarnIcon from '@/components/Icons/WarnIcon';
import { createPortal } from 'react-dom';
import { ReactElement } from 'react';

const { Text } = Typography;

const SharedModal = ({
  open,
  setConfirmDeleteOpen,
  onOk,
  confirmationText,
  title,
  content,
  isDeletePending,
}: {
  open: boolean;
  setConfirmDeleteOpen: (value: boolean) => void;
  onOk?: (e?: React.SetStateAction<boolean>) => void;
  confirmationText: string;
  title: string;
  content: ReactElement;
  isDeletePending?: boolean;
}) => {
  const { t, i18n } = useTranslation('captable', {
    keyPrefix: 'deleteModal',
  });

  return createPortal(
    <StyledSharednModal
      closable={false}
      centered
      className={i18n.language}
      open={open}
      onCancel={() => {
        setConfirmDeleteOpen(false);
      }}
      footer={[
        <StyledButton
          size="small"
          key="deleteCancel"
          style={{
            width: 90,
          }}
          onClick={() => setConfirmDeleteOpen(false)}
        >
          {t('cancel')}
        </StyledButton>,
        <StyledButton
          size="small"
          key="deleteConfirm"
          type="primary"
          style={{
            width: 173,
            fontSize: '14px',
          }}
          onClick={() => {
            if (onOk) {
              onOk();
            }
            setConfirmDeleteOpen(false);
          }}
          disabled={isDeletePending}
        >
          {confirmationText}
        </StyledButton>,
      ]}
    >
      <Flex vertical gap={8}>
        <Flex gap={6} align="center">
          <WarnIcon />
          <Text
            style={{
              color: themeToken.colorText,
              fontSize: themeToken.fontSize,
              fontWeight: 600,
            }}
          >
            {title}
          </Text>
        </Flex>
        <Text
          style={{
            color: themeToken.colorText,
            fontWeight: 400,
            fontSize: themeToken.fontSizeSM,
          }}
        >
          {content}
        </Text>
      </Flex>
    </StyledSharednModal>,
    document.body,
  );
};

export default SharedModal;
