import { StyledInput } from '@lib/theme/components/Input';
import styled from 'styled-components';

export const StyledInputGroup = styled(StyledInput)<{ classNames?: string }>`
  .ant-input-wrapper {
    .ant-input {
      border-radius: ${(props) => (props.classNames === 'is-email' ? '4px' : '0')};
    }
  }

  .ant-input-group-addon {
    width: ${(props) => (props.classNames === 'is-email' ? 0 : 'auto')};
    padding: ${(props) => (props.classNames === 'is-email' ? 0 : '0 12px')};
    display: ${(props) => (props.classNames === 'is-email' ? 'none' : 'table-cell')};
  }
`;
