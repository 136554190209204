import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';

import ShareAltOutlined from '@ant-design/icons/ShareAltOutlined';

import themeToken from '@lib/theme/tokens/index';

import { StyledActionIconContainer } from './StyledActionIconContainer';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;

type Props = {
  disabled: boolean;
  isWhiteBgColor?: boolean;
  hasLabel?: boolean;
  isVertical?: boolean;
  setIsShareFileOpen: () => void;
  setSelectedFile?: () => void;
  closeDropdown?: () => void;
};

const FileShare = ({
  hasLabel = true,
  isWhiteBgColor = false,
  isVertical = true,
  disabled,
  closeDropdown,
  setIsShareFileOpen,
  setSelectedFile,
}: Props) => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');

  return (
    <Flex
      onClick={(e) => {
        if (disabled) return;
        sendMixpanelEvent('share-file-btn-clicked');
        e.stopPropagation();
        setIsShareFileOpen();
        setSelectedFile?.();
        closeDropdown?.();
      }}
      vertical={isVertical}
      align="center"
      gap={isVertical ? 2 : 8}
      style={{ cursor: !disabled ? 'pointer' : 'not-allowed' }}
    >
      <StyledActionIconContainer isWhiteBgColor={isWhiteBgColor} isVertical={isVertical}>
        <ShareAltOutlined style={{ color: themeToken['branding-primary-6'] }} />
      </StyledActionIconContainer>
      {hasLabel && (
        <Text
          style={{
            color: themeToken['branding-primary-3'],
            fontSize: themeToken.fontSizeSM,
            fontWeight: 400,
          }}
        >
          {t('share')}
        </Text>
      )}
    </Flex>
  );
};

export default FileShare;
