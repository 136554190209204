import { Menu } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  itemActiveBg: themeToken.colorBgTextHover,
  itemSelectedBg: themeToken.menuItemSelectedBgColor,
  itemBorderRadius: themeToken.borderRadiusXS,
  itemMarginBlock: 0,
  itemMarginInline: 0,
  itemHeight: themeToken.controlHeightXXL,
  iconMarginInlineEnd: 16,
};

const globalTheme = {};

export const menuTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledMenu = styled(Menu)`
  &.ant-menu {
    color: ${themeToken['branding-natural-8']};

    .ant-menu-item-selected {
      font-weight: ${themeToken.fontWeightStrong};
      border-inline-start: 3px solid ${themeToken['branding-secondary-6']};
      background: ${themeToken['branding-secondary-1']};

      .ant-menu-title-content {
        color: ${themeToken.colorPrimary};
        margin-left: unset;
      }
      &.ant-menu-item-only-child {
        border: unset;
        background: ${themeToken.menuItemSelectedBgColor};
      }
    }

    .ant-menu-submenu-title {
      border-end-end-radius: unset;
      border-start-end-radius: unset;
    }
    &.ant-menu-inline {
      height: 100%;
    }
    & > .ant-menu-item {
      display: flex;
      gap: ${themeToken.padding}px;
    }
    & > .ant-menu-submenu {
      & > .ant-menu-submenu-title {
        display: flex;
        gap: ${themeToken.padding}px;
        padding-inline: ${themeToken.padding}px;
      }
      &.ant-menu-submenu-open {
        background: ${themeToken.menuSubmenuBgColor};
        & > .ant-menu-submenu-title {
          border-inline-start: 3px solid ${themeToken['branding-secondary-6']};
          background: ${themeToken['branding-secondary-1']};
          font-weight: ${themeToken.fontWeightStrong};
          svg {
            filter: invert(16%) sepia(86%) saturate(7295%) hue-rotate(261deg) brightness(92%) contrast(84%);
          }
        }
      }
      & > .ant-menu-sub {
        padding: ${themeToken.padding}px;
        padding-inline-start: ${themeToken.paddingLG}px;
        background: ${themeToken['branding-natural-5']};
        position: relative;
        &::before {
          content: '';
          border-inline-start: ${themeToken.XXXS}px solid ${themeToken.menuItemSelectedBgColor};
          height: calc(100% - ${themeToken.paddingXL}px);
          position: absolute;
        }
        &.ant-menu-item {
          padding: ${themeToken.paddingXS}px ${themeToken.padding}px !important;
          margin-inline-start: ${themeToken.paddingXS}px;
          height: ${themeToken.controlHeightMD}px;
          border-radius: ${themeToken.borderRadiusXS}px;

          &:not(:last-child) {
            margin-block-end: ${themeToken.paddingXXS}px;
          }
        }
      }
    }
  }
`;
