export const ImageFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159978)">
      <path
        d="M7.02414 1.25H14.8378L21.3503 8.04437V21.6244C21.3503 23.0734 20.1738 24.25 18.7247 24.25H7.02414C5.57512 24.25 4.39856 23.0734 4.39856 21.6244V3.87558C4.39856 2.42656 5.57512 1.25 7.02414 1.25Z"
        fill="#554688"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.6702 7.28255H15.541V1.98518L20.6702 7.28255Z" fill="white" stroke="#EDEFF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9239 12.7441H9.82495C9.3683 12.7441 8.9928 13.1196 8.9928 13.5763V17.4528C8.9928 17.9095 9.36827 18.285 9.82495 18.285H15.9239C16.3805 18.285 16.7459 17.9095 16.7459 17.4528V13.5763C16.7459 13.1196 16.3806 12.7441 15.9239 12.7441ZM11.4892 13.7894C11.9865 13.7894 12.3822 14.1953 12.3822 14.6824C12.3822 15.1796 11.9865 15.5856 11.4892 15.5856C10.992 15.5856 10.586 15.1796 10.586 14.6824C10.586 14.1953 10.992 13.7894 11.4892 13.7894ZM16.1979 17.4528C16.1979 17.605 16.0761 17.7369 15.9239 17.7369H9.82495C9.67272 17.7369 9.55094 17.605 9.55094 17.4528V17.2905L10.6571 16.1843L11.5704 17.0977C11.682 17.2093 11.8545 17.2093 11.9662 17.0977L14.2596 14.8042L16.1979 16.7425V17.4528Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159978">
        <rect width="24" height="24" fill="white" transform="translate(0.875 0.75)" />
      </clipPath>
    </defs>
  </svg>
);
