import { axiosClient } from '@/lib/axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { UserDTO } from '@/types';
import { AxiosError } from 'axios';

export const verifySocialAuth = async (): Promise<UserDTO> => {
  const { data } = await axiosClient.get<UserDTO>('/auth/me', { withCredentials: true });
  return data;
};

type UseSocialAuthDTO = {
  config?: QueryFunction<typeof verifySocialAuth>;
};

export const useSocialAuth = ({ config }: UseSocialAuthDTO) => {
  return useQuery<UserDTO, AxiosError<{ error: string }>>({
    queryKey: ['social-auth'],
    queryFn: verifySocialAuth,
    ...config,
  });
};
