import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
export const StyledLogoImage = styled.img<{ isVisible?: boolean }>`
  margin-bottom: 32px;
  @media (min-width: ${themeToken.screenMD}px) {
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
  }
`;

export const StyledLeftDotsImage = styled.img`
  position: absolute;
  left: 14px;
  top: 35px;
  @media (min-width: ${themeToken.screenMD}px) {
    bottom: 32px;
    left: 32px;
    top: unset;
  }
`;

export const StyledRightDotsImage = styled.img`
  position: absolute;
  right: 24px;
  bottom: 300px;
  @media (min-width: ${themeToken.screenMD}px) {
    right: 0;
    bottom: unset;
    top: 0;
  }
`;
