import { Button } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  defaultBg: themeToken.white,
  colorLink: themeToken.black,
  paddingInline: themeToken.paddingLG,
  paddingContentVertical: themeToken.paddingXXS,
  borderRadius: themeToken.borderRadiusSM,
  defaultShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.24)',
  controlHeight: themeToken.controlHeightLG,
  controlHeightLG: themeToken.controlHeightXXL,
  controlHeightSM: themeToken.controlHeight,
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.24)',
  paddingInlineLG: themeToken.paddingLG,
};

const primaryVariantTheme = {};

const textVariantTheme = {
  textHoverBg: 'rgba(0, 0, 0, 0.06)',
};

const linkVariantTheme = {
  colorLinkHover: '#554688',
};

const ghostVariantTheme = {
  defaultGhostColor: themeToken.colorPrimary,
  defaultGhostBorderColor: themeToken.colorPrimary,
};

export const buttonTheme = {
  ...defaultTheme,
  ...primaryVariantTheme,
  ...textVariantTheme,
  ...linkVariantTheme,
  ...ghostVariantTheme,
};

export const StyledButton = styled(Button)`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn-round {
      border-radius: ${themeToken.borderRadiusSM}px;
      display: flex;
      align-items: center;
      .ant-btn-primary {
        background-color: ${themeToken['branding-secondary-6']};
      }
    }
  }
  &.ant-btn-dangerous.ant-btn-primary {
    background-color: ${themeToken.colorError};
  }
  &.ant-btn-default.ant-btn-background-ghost,
  &.ant-btn-dashed.ant-btn-background-ghost {
    border-color: ${themeToken.colorBgContainer};
    color: ${themeToken.colorBgContainer};
  }
  &.ant-btn-background-ghost.ant-btn-dangerous {
    background-color: transparent;
    border-color: ${themeToken.colorError};
    color: ${themeToken.colorError};
  }
`;
