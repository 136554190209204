export const tempValuationHistoryData = {
  latest_share_name: '[hamdy]ESOP 2',
  latest_share_date: '2024-03-06T14:31:05.000Z',
  latest_valuation: 377500,
  total_capital_raised: 123000,
  graph: [
    {
      share_name: 'CS-1',
      total_valuation: 10000,
      share_price: 10,
      shares: 1000,
      share_date: '2022-11-15T14:05:48.000Z',
    },
    {
      share_name: 'CS-2',
      total_valuation: 20000,
      share_price: 10,
      shares: 2000,
      share_date: '2022-11-15T14:09:48.000Z',
    },
    {
      share_name: 'CS-3',
      total_valuation: 30000,
      share_price: 10,
      shares: 3000,
      share_date: '2022-11-15T14:10:48.000Z',
    },
    {
      share_name: 'PS-4',
      total_valuation: 74000,
      share_price: 20,
      shares: 3700,
      share_date: '2022-11-15T14:11:48.000Z',
    },
    {
      share_name: 'PS-5',
      total_valuation: 126000,
      share_price: 30,
      shares: 4200,
      share_date: '2022-11-15T14:12:48.000Z',
    },
    {
      share_name: 'PS-6',
      total_valuation: 182000,
      share_price: 40,
      shares: 4550,
      share_date: '2022-11-15T14:13:48.000Z',
    },
    {
      share_name: 'ESOP',
      total_valuation: 222000,
      share_price: 40,
      shares: 5550,
      share_date: '2022-11-15T14:14:48.000Z',
    },
    {
      share_name: 'PS-7',
      total_valuation: 327500,
      share_price: 50,
      shares: 6550,
      share_date: '2022-11-15T14:15:48.000Z',
    },
    {
      share_name: '[hamdy]ESOP 2',
      total_valuation: 377500,
      share_price: 50,
      shares: 7550,
      share_date: '2024-03-06T14:31:05.000Z',
    },
  ],
};
