import { useTranslation } from 'react-i18next';
import { Card, Flex, Grid, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { CompanyValuationDashboardData, RevenueCostModule } from '@/features/valuation/types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { formatNumber } from '@/utils/formatNumber';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const RevenueAndCost = ({ data }: { data: CompanyValuationDashboardData['revcost_module'] | undefined }) => {
  const { fullCompanyData } = useSelectedCompanyStore((state) => state);
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const screens = useBreakpoint();
  const chartData = Object.values(data || {}).map((item: RevenueCostModule) => ({
    name: item.year,
    Cost: item.costs,
    Revenue: item.revenues,
  }));

  return (
    <Card
      bodyStyle={{
        padding: '0px',
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
      }}
      style={{
        width: '100%',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '343px',
      }}
    >
      <Flex vertical justify="space-between" style={{ height: '100%' }}>
        <Text style={{ color: themeToken.colorPrimary, fontWeight: themeToken.fontWeightLG, marginBottom: '17.5px' }}>
          {t('revenueAndCost')}
        </Text>
        <ResponsiveContainer width="95%" height={273}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              left: screens.md ? 20 : 10,
              bottom: 5,
            }}
            barSize={screens.md ? 50 : 30}
          >
            <CartesianGrid stroke={themeToken['branding-natural-3']} />
            <XAxis dataKey="name" tickLine={false} axisLine={false} fontSize={12} />
            <YAxis />

            <Legend
              wrapperStyle={{
                insetInlineStart: '45%',
                width: 'fit-content',
              }}
              formatter={(value: string) => t(value.toLowerCase())}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              contentStyle={{
                borderRadius: themeToken.borderRadius,
                borderColor: '#cccccc',
              }}
              formatter={(value: number, name: string) => [
                `${formatNumber(value)} ${fullCompanyData?.company_data.company_currency.code}`,
                t(name.toLowerCase()),
              ]}
            />
            <Bar dataKey="Cost" fill="#A7B1C9" />
            <Bar dataKey="Revenue" fill=" #6040D4" />
          </BarChart>
        </ResponsiveContainer>
      </Flex>
    </Card>
  );
};

export default RevenueAndCost;
