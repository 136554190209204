export default {
  upgradeCompanyProfileCapabilities: 'Upgrade Your Company Profile & Capabilities',
  perfectPlanForYou: 'We have Got a plan that is a perfect for you',
  name: 'Name',
  tag: 'Tag',
  annualPlanPrice: 'Annual Plan Price',
  validDays: 'Valid {{duration}} Day',
  allIsFree: 'All is free',
  slogan: 'Slogan',
  unlimited: 'Unlimited',
  upTo: 'Up to',
  currentPlan: 'Current Plan',
  go: 'Go',
  features: 'Features',
  everythingInFreePlanPlus: 'everything in our free plan plus..',
  featuresIconsMap: 'Features Icons Map',
  check: 'Check',
  close: 'Close',
  'Most Popular': 'Most Popular',
  'Save Plan': 'Save Plan',
  'Suitable for Small & Middle Size Companies': 'Suitable for Small & Middle Size Companies',
  'Suitable for Big Company': 'Suitable for Big Company',
  Shareholders: 'Shareholders',
  'Equity Classes': 'Equity Classes',
  'Convertible Instruments': 'Convertible Instruments',
  'Option Pools': 'Option Pools',
  'Manage Users': 'Manage Users',
  Captable: 'Cap Table',
  'Vesting Schedules': 'Vesting Schedules',
  Dataroom: 'Data Room',
  'Data room Folders': 'Data Room Folders',
  'Uploaded Files': 'Uploaded Files',
  'Active data room shares': 'Active Data Room Shares',
  'Scenario Modeling': 'Scenario Modeling',
  'Scenerio Modeling': 'Scenario Modeling',
  'Exit Scenario Modeling': 'Exit Scenario Modeling',
  Free: 'Free',
  noAccess: "Sorry .. You don't have access to",
  pageAvailable: 'This Page is Available Only with Professional Plan',
  knowMore: 'Know more about the most important feature',
  explorePlans: 'Explore Our Plans',
  'company dashboard': 'Company Dashboard',
  'my investments': 'My Investments',
  'captable overview': 'Cap Table Overview',
  'captable equities': 'Cap Table Equities',
  'captable options': 'Cap Table Options',
  'captable convertible instruments': 'Cap Table Convertible Instruments',
  'captable vesting schedules': 'Cap Table Vesting Schedules',
  'investment round': 'Investment Round',
  'Exit Modeling': 'Exit Modeling',
  Valuation: 'Valuation',
  dataroom: 'Data Room',
  'dataroom shared logs': 'Data Room Shared Logs',
  'Dataroom Analytics': 'Data Room Analytics',
  'users list': 'Users List',
  'roles list': 'Roles List',
  goPremium: 'Go Premium',
  noAccessPage: {
    title: "Oops! It looks like you don't have access to this page",
    description:
      'It seems you do not currently have permission to view this page. If you require access, kindly reach out to your administrator and request the necessary permissions.',
    thanks: 'Thank you for your understanding',
  },
  mostPopularTag: 'Most Popular',
  upgradeYourLimits: 'Upgrade Your Limits',
  validDuration: 'Valid {{duration}} Day',
  upgradePlanButton: 'Upgrade Plan',
  continueFreePlanButton: 'Continue with a free plan',
  planName: '{{name}} Plan',
  'Enjoy the captable': 'Enjoy the Cap Table',
  "Manage you company's data": "Manage your company's data",
  "Manage your company's data": "Manage your company's data",
  'See your future through the scenario': 'See your future through the scenario',
  upgradeCompanyProfile: 'Upgrade Company Profile',
  detailsAboutThePlanAndPromocode: 'Details about the plan and promocode',
  upgradeTo: 'Upgrade to',
  paidAnnually: 'Paid Annually',
  details: 'Details',
  promoCode: 'Promo Code',
  enterPromoCode: 'Enter Promo code',
  apply: 'Apply',
  reset: 'Reset',
  summary: 'Summary',
  plan: 'Plan',
  discount: 'Discount',
  total: 'Total',
  goToPayment: 'Go to Payment',
  paymentReceivedSubscriptionActive: 'Payment received & subscription active!',
  welcomeBoardEnjoyAccess: 'Welcome onboard! Enjoy Your Access',
  yourRecentPaymentReceived: 'Your recent payment has been received. Your next billing date is',
  done: 'Done',
  'Easily control who can access and modify your company data.':
    'Easily control who can access and modify your company data.',
  'Simplify equity management and visualize ownership structures.':
    'Simplify equity management and visualize ownership structures.',
  'Securely share critical documents with stakeholders.': 'Securely share critical documents with stakeholders.',
  'Predict and plan for future financial outcomes.': 'Predict and plan for future financial outcomes.',
  'Obtain accurate business valuations for better decision-making.':
    'Obtain accurate business valuations for better decision-making.',
};
