const FolderSettingsIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 11.7812C11.2599 11.7812 12.2812 10.7599 12.2812 9.5C12.2812 8.2401 11.2599 7.21875 10 7.21875C8.7401 7.21875 7.71875 8.2401 7.71875 9.5C7.71875 10.7599 8.7401 11.7812 10 11.7812Z"
        fill="white"
        stroke="#2A186A"
        strokeWidth="1.14062"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.396 10.1689V8.8306C2.396 8.03976 3.04235 7.38581 3.84079 7.38581C5.21714 7.38581 5.77985 6.41247 5.08787 5.21862C4.69245 4.53424 4.92818 3.64456 5.62016 3.24914L6.93568 2.49633C7.53641 2.13893 8.31204 2.35185 8.66943 2.95258L8.75308 3.09706C9.43745 4.29091 10.5629 4.29091 11.2549 3.09706L11.3385 2.95258C11.6959 2.35185 12.4715 2.13893 13.0722 2.49633L14.3878 3.24914C15.0797 3.64456 15.3155 4.53424 14.9201 5.21862C14.2281 6.41247 14.7908 7.38581 16.1671 7.38581C16.958 7.38581 17.6119 8.03216 17.6119 8.8306V10.1689C17.6119 10.9598 16.9656 11.6137 16.1671 11.6137C14.7908 11.6137 14.2281 12.5871 14.9201 13.7809C15.3155 14.4729 15.0797 15.355 14.3878 15.7504L13.0722 16.5032C12.4715 16.8606 11.6959 16.6477 11.3385 16.047L11.2549 15.9025C10.5705 14.7086 9.44506 14.7086 8.75308 15.9025L8.66943 16.047C8.31204 16.6477 7.53641 16.8606 6.93568 16.5032L5.62016 15.7504C4.92818 15.355 4.69245 14.4653 5.08787 13.7809C5.77985 12.5871 5.21714 11.6137 3.84079 11.6137C3.04235 11.6137 2.396 10.9598 2.396 10.1689Z"
        stroke="#2A186A"
        strokeWidth="1.14062"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderSettingsIcon;
