const BuildingIcon = ({ width, height }: { width: string; height: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.815 3.93569V10.2888H15.1855V3.93569C15.1855 2.73581 16.156 1.76514 17.3561 1.76514H42.6443C43.8444 1.76514 44.815 2.73581 44.815 3.93569Z"
        fill="#DCE0E9"
      />
      <path d="M15.1855 5.99146H44.815V10.2885H15.1855V5.99146Z" fill="#CAD0DF" />
      <path
        d="M50.5138 10.7388V55.5889H9.48438V10.7388C9.48438 9.08874 10.8165 7.75659 12.4578 7.75659H47.5402C49.1812 7.75659 50.5138 9.08874 50.5138 10.7388Z"
        fill="#DCE0E9"
      />
      <path
        d="M35.6911 44.4094V55.6504H24.3086V44.4094C24.3086 43.3591 25.1647 42.5034 26.2146 42.5034H33.7853C34.8352 42.5034 35.6911 43.3591 35.6911 44.4094Z"
        fill="#B9C1D4"
      />
      <path
        d="M46.1024 35.153V41.4001C46.1024 42.1411 45.5023 42.7413 44.7612 42.7413H38.5053C37.764 42.7413 37.1641 42.1411 37.1641 41.4001V35.153C37.1641 34.4119 37.764 33.8118 38.5053 33.8118H44.7612C45.5023 33.8118 46.1024 34.4119 46.1024 35.153Z"
        fill="white"
      />
      <path
        d="M46.1024 24.3361V30.5832C46.1024 31.3242 45.5023 31.924 44.7612 31.924H38.5053C37.764 31.924 37.1641 31.3242 37.1641 30.5832V24.3361C37.1641 23.5946 37.764 22.9949 38.5053 22.9949H44.7612C45.5023 22.9949 46.1024 23.5946 46.1024 24.3361Z"
        fill="white"
      />
      <path
        d="M46.1024 13.5172V19.7643C46.1024 20.5054 45.5023 21.1055 44.7612 21.1055H38.5053C37.764 21.1055 37.1641 20.5054 37.1641 19.7643V13.5172C37.1641 12.7762 37.764 12.176 38.5053 12.176H44.7612C45.5023 12.176 46.1024 12.7762 46.1024 13.5172Z"
        fill="white"
      />
      <path
        d="M34.4743 24.3366V30.5837C34.4743 31.3247 33.8743 31.9244 33.1331 31.9244H26.8773C26.1361 31.9244 25.5361 31.3247 25.5361 30.5837V24.3366C25.5361 23.5951 26.1361 22.9954 26.8773 22.9954H33.1331C33.8743 22.9954 34.4743 23.5951 34.4743 24.3366Z"
        fill="white"
      />
      <path
        d="M34.4696 13.5189V19.7661C34.4696 20.5071 33.8694 21.1072 33.1284 21.1072H26.8724C26.1312 21.1072 25.5312 20.5071 25.5312 19.7661V13.5189C25.5312 12.7779 26.1312 12.1777 26.8724 12.1777H33.1284C33.8694 12.1777 34.4696 12.7779 34.4696 13.5189Z"
        fill="white"
      />
      <path
        d="M22.8346 35.1532V41.4003C22.8346 42.1414 22.2347 42.7415 21.4934 42.7415H15.2377C14.4964 42.7415 13.8965 42.1414 13.8965 41.4003V35.1532C13.8965 34.4122 14.4964 33.812 15.2377 33.812H21.4934C22.2347 33.812 22.8346 34.4122 22.8346 35.1532Z"
        fill="white"
      />
      <path
        d="M22.8348 24.3353V30.5825C22.8348 31.3235 22.2347 31.9237 21.4936 31.9237H15.2377C14.4964 31.9237 13.8965 31.3235 13.8965 30.5825V24.3353C13.8965 23.5943 14.4964 22.9941 15.2377 22.9941H21.4936C22.2347 22.9941 22.8348 23.5943 22.8348 24.3353Z"
        fill="white"
      />
      <path
        d="M22.8346 13.5172V19.7643C22.8346 20.5054 22.2347 21.1055 21.4934 21.1055H15.2377C14.4964 21.1055 13.8965 20.5054 13.8965 19.7643V13.5172C13.8965 12.7762 14.4964 12.176 15.2377 12.176H21.4934C22.2347 12.176 22.8346 12.7762 22.8346 13.5172Z"
        fill="white"
      />
      <path
        d="M13.3446 44.7271C13.3446 47.9251 10.7521 50.5176 7.55411 50.5176C4.35614 50.5176 1.76367 47.9251 1.76367 44.7271C1.76367 41.5291 4.35614 34.5249 7.55411 34.5249C10.7521 34.5249 13.3446 41.5291 13.3446 44.7271Z"
        fill="#6430DA"
      />
      <path
        d="M7.55423 57.0443C7.06695 57.0443 6.67188 56.6493 6.67188 56.162V42.5213C6.67188 42.034 7.06695 41.6389 7.55423 41.6389C8.0415 41.6389 8.43658 42.034 8.43658 42.5213V56.162C8.43658 56.6493 8.0415 57.0443 7.55423 57.0443Z"
        fill="#2A186A"
      />
      <path
        d="M58.2352 44.7271C58.2352 47.9251 55.6427 50.5176 52.4447 50.5176C49.2468 50.5176 46.6543 47.9251 46.6543 44.7271C46.6543 41.5291 49.2468 34.5249 52.4447 34.5249C55.6427 34.5249 58.2352 41.5291 58.2352 44.7271Z"
        fill="#6430DA"
      />
      <path
        d="M52.4439 57.0446C51.9566 57.0446 51.5615 56.6495 51.5615 56.1622V42.5215C51.5615 42.0342 51.9566 41.6392 52.4439 41.6392C52.9312 41.6392 53.3262 42.0342 53.3262 42.5215V56.1622C53.3262 56.6495 52.9312 57.0446 52.4439 57.0446Z"
        fill="#2A186A"
      />
      <path
        d="M58.2343 56.4358C58.2343 56.9386 58.0313 57.3797 57.7048 57.7063C57.3782 58.0329 56.937 58.2358 56.4342 58.2358H3.56371C2.56654 58.2358 1.76367 57.4327 1.76367 56.4358C1.76367 55.9326 1.9666 55.4914 2.29295 55.1652C2.61953 54.8387 3.0607 54.6357 3.56371 54.6357H56.4342C57.4312 54.6357 58.2343 55.4388 58.2343 56.4358Z"
        fill="#554688"
      />
    </svg>
  );
};

export default BuildingIcon;
