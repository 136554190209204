import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { List, Flex, Dropdown, Grid, Typography, Checkbox } from 'antd';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import type { MenuProps } from 'antd/es/menu/menu';

import { usePermissionsStore } from '@/stores/Permissions';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import { debounce } from '@/utils/debounce';

import FileDownload from './DownloadFile';
import FileDelete from './FileDelete';
import FileShare from './FileShare';
import FilesIcons from './FilesIcons';
import EditFolder from './EditFolder';
import EditFile from './EditFile';
import OpenFile from './OpenFile';
import CopyFile from './CopyFile';
import ListItemWithBadge from './ListItemWithBadge';
import type { FolderData, FoldersList, ModalName } from '../types';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type Props = {
  isFetchFolderDataLoading: boolean;
  setFoldersBreadCrumbs: (
    folderBreadCrumb:
      | { title: string; id: number }[]
      | ((prevState: { title: string; id: number }[]) => { title: string; id: number }[]),
  ) => void;
  item: FoldersList;
  showModal: (modalName: ModalName) => void;
  setIsFilePreviewOpen: Dispatch<SetStateAction<boolean>>;
  setFolderSettings: Dispatch<
    SetStateAction<
      | {
          id: number;
          name: string;
          permissions: string[];
        }
      | undefined
    >
  >;
  setSelectedFile: Dispatch<SetStateAction<FolderData | undefined>>;
  handleSelectItem: (itemID: number, isSelected: boolean) => void;
  isSelected?: boolean;
  uuid?: string;
};

const ListItem = ({
  isFetchFolderDataLoading,
  setFoldersBreadCrumbs,
  item,
  showModal,
  setIsFilePreviewOpen,
  setFolderSettings,
  setSelectedFile,
  handleSelectItem,
  isSelected,
  uuid,
}: Props) => {
  const { i18n } = useTranslation('dataroom');
  dayjs.locale(i18n.language);

  const { limitations, disableRecordsAddition } = useOutletContext<LimitsCheckerContext>() || {};
  const { permissions } = usePermissionsStore((state) => state);
  const screens = useBreakpoint();
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [showListActions, setShowListActions] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const fileNameRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const canShareFileOrFolder = useMemo(() => {
    if (uuid) return false;
    if (disableRecordsAddition) return false;
    const sharePlanlimit = limitations?.find(({ key }) => key === 'share');
    if (sharePlanlimit && sharePlanlimit.limit === 0) return true;
    if (sharePlanlimit && sharePlanlimit.usage === sharePlanlimit.limit) return false;
    return true;
  }, [limitations, disableRecordsAddition, uuid]);

  const canCopyNewFile = useMemo(() => {
    if (uuid) return false;
    if (disableRecordsAddition) return false;
    const filePlanlimit = limitations?.find(({ key }) => key === 'file');
    if (filePlanlimit && filePlanlimit.limit === 0) return true;
    if (filePlanlimit && filePlanlimit.usage === filePlanlimit.limit) return false;
    return true;
  }, [limitations, disableRecordsAddition, uuid]);

  const isFolder = item.file_mimetype.toLowerCase() === 'folder';
  const canManage = permissions.includes('company.dataroom.management');
  const canShare = permissions.includes('company.dataroom.share');
  const canDownload = permissions.includes('company.dataroom.download');

  const handleDownloadStart = () => {
    setIsFileDownloading(true);
  };
  const handleDownloadEnd = () => {
    setIsFileDownloading(false);
  };

  const handleEditFolderClick = useCallback(() => {
    const itemSettings = [];
    if (item.downloadable) itemSettings.push('downloadable');
    if (item.watermarked) itemSettings.push('watermarked');
    setFolderSettings({ id: item.id, name: item.name, permissions: itemSettings });
  }, [item.downloadable, item.id, item.name, item.watermarked, setFolderSettings]);

  const createActionComponents = useCallback(
    (isDropdown?: boolean) => {
      const actions = [];

      if (!isFolder) {
        actions.push({
          key: '1',
          label: (
            <OpenFile
              key="open-action"
              setSelectedFile={setSelectedFile}
              setIsFilePreviewOpen={setIsFilePreviewOpen}
              isVertical={!isDropdown}
              file={item}
              closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
            />
          ),
        });
      }

      if (!uuid) {
        actions.push(
          {
            key: '2',
            label: (
              <FileShare
                key="share-action"
                disabled={!canShare || !canShareFileOrFolder}
                isWhiteBgColor
                isVertical={!isDropdown}
                closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
                setIsShareFileOpen={() => showModal('shareFile')}
                setSelectedFile={() => setSelectedFile(item)}
              />
            ),
          },
          {
            key: '3',
            label: (
              <FileDelete
                key="delete-action"
                isWhiteBgColor
                isVertical={!isDropdown}
                setSelectedFile={() => setSelectedFile(item)}
                disabled={!canManage || item.protected}
                setIsDeleteModalOpen={() => showModal('deleteModal')}
                closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
              />
            ),
          },
        );

        if (isFolder) {
          actions.push({
            key: '4',
            label: (
              <EditFolder
                key="settings-action"
                isWhiteBgColor
                disabled={!canManage || item.protected}
                setIsEditFolderModalOpen={() => showModal('editFolder')}
                isVertical={!isDropdown}
                closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
                onClickHandler={() => handleEditFolderClick()}
              />
            ),
          });
        } else {
          actions.push({
            key: '5',
            label: (
              <EditFile
                key="rename-action"
                isWhiteBgColor
                isVertical={!isDropdown}
                disabled={!canManage}
                setSelectedFile={() => setSelectedFile(item)}
                setIsEditModalOpen={() => showModal('editFile')}
                closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
              />
            ),
          });
          actions.push({
            key: '6',
            label: (
              <CopyFile
                key="copy-action"
                isWhiteBgColor
                isVertical={!isDropdown}
                disabled={!canManage || !canCopyNewFile}
                setSelectedFile={() => setSelectedFile(item)}
                setIsCopyModalOpen={() => showModal('copyFile')}
                closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
              />
            ),
          });
        }
      }

      if (!isFolder) {
        actions.push({
          key: '6',
          label: (
            <FileDownload
              key="download-action"
              isVertical={!isDropdown}
              file={item}
              isWhiteBgColor
              entityType={item.entity_type}
              fileId={item.id}
              disabled={(uuid ? !item.downloadable : !canDownload) || isFileDownloading}
              closeDropdown={isDropdown ? () => setIsDropdownOpen(false) : undefined}
              handleDownloadStart={handleDownloadStart}
              handleDownloadEnd={handleDownloadEnd}
              uuid={uuid}
            />
          ),
        });
      }

      return actions;
    },
    [
      canCopyNewFile,
      canDownload,
      canManage,
      canShare,
      canShareFileOrFolder,
      handleEditFolderClick,
      isFileDownloading,
      isFolder,
      item,
      setIsFilePreviewOpen,
      setSelectedFile,
      showModal,
      uuid,
    ],
  );

  const dropdownActionsItems = useCallback((): MenuProps['items'] => {
    return createActionComponents(true);
  }, [createActionComponents]);

  const rowActionItems = useCallback(() => {
    return createActionComponents().map((action) => action.label);
  }, [createActionComponents]);

  const listActions = useMemo(() => {
    if (!screens.md)
      return [
        <Dropdown
          key="mobile-actions"
          open={isDropdownOpen}
          overlayStyle={{ width: '160px' }}
          menu={{
            items: dropdownActionsItems(),
          }}
          placement="bottomLeft"
          arrow
        >
          <StyledButton
            style={{
              boxShadow: 'none',
              background: themeToken['branding-natural-5'],
              minWidth: '34px',
              width: '34px',
              height: '34px',
              border: 0,
            }}
            shape="circle"
            onClick={(e) => {
              e.stopPropagation();
              setIsDropdownOpen(true);
            }}
            icon={<EllipsisOutlined />}
          ></StyledButton>
        </Dropdown>,
      ];
    else return rowActionItems();
  }, [screens.md, dropdownActionsItems, isDropdownOpen, rowActionItems]);

  const checkTruncation = () => {
    if (fileNameRef.current) {
      const { scrollWidth, clientWidth } = fileNameRef.current;
      setIsTruncated(scrollWidth > clientWidth);
    }
  };

  useEffect(() => {
    checkTruncation();

    const handleResize = debounce(() => {
      checkTruncation();
    }, 500);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ListItemWithBadge downloadable={item.downloadable} watermarked={item.watermarked} isFolder={isFolder}>
      <List.Item
        key={item.id}
        id={`file-${item?.id}`}
        onMouseEnter={(e) => {
          setShowListActions((e.target as HTMLElement).id);
        }}
        onMouseLeave={() => {
          setShowListActions('');
        }}
        style={{
          paddingBlock: `${themeToken.paddingXL}px ${themeToken.paddingSM}px`,
          cursor: 'pointer',
        }}
        actions={showListActions === `file-${item.id}` ? listActions : []}
        onClick={() => {
          if (item.entity_type?.toLowerCase() !== 'file') {
            if (!isFetchFolderDataLoading)
              setFoldersBreadCrumbs((prevState) => [...prevState, { title: item.name, id: item.id }]);
          } else {
            setSelectedFile(item);
            setIsFilePreviewOpen(true);
          }
        }}
      >
        <Flex gap={8} style={{ width: '50%' }}>
          <List.Item.Meta
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            avatar={
              <Flex
                style={{
                  height: '38px',
                }}
                align="center"
                gap={16}
              >
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  onChange={(ev) => {
                    handleSelectItem(item.id, ev.target.checked);
                  }}
                  checked={isSelected}
                />
                <FilesIcons mimeType={item?.file_mimetype} />
              </Flex>
            }
            title={
              <Text
                ref={fileNameRef}
                style={{
                  color: themeToken['table-color-text'],
                  fontSize: themeToken.fontSizeLG,
                  fontWeight: 400,
                  display: 'inline-block',
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop: '4px',
                }}
                ellipsis={
                  isTruncated && {
                    tooltip: item?.name,
                  }
                }
              >
                {item?.name}
              </Text>
            }
          />

          {screens.md && (
            <Flex gap={24}>
              {item.file_size > 0 && (
                <Text
                  style={{
                    color: themeToken['branding-natural-6'],
                    fontSize: themeToken.fontSize,
                    fontWeight: 400,
                  }}
                >
                  {Math.round(item?.file_size / 1024).toFixed(0)}KB
                </Text>
              )}

              {item.created && (
                <Text
                  style={{
                    color: themeToken['branding-natural-6'],
                    fontSize: themeToken.fontSize,
                    fontWeight: 400,
                  }}
                >
                  {dayjs(item?.created).format('MMMM D, YYYY, hh:mm A')}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      </List.Item>
    </ListItemWithBadge>
  );
};

export default ListItem;
