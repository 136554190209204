import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import type { CompanyData } from '../types';

type CompanyPayload = {
  companyID?: number;
};

type CompanyResponse = {
  data: CompanyData;
};

export const getCompany = async ({ companyID }: CompanyPayload): Promise<CompanyData> => {
  const { data } = await axiosClient.get<CompanyResponse>(`/companies/${companyID}`);
  return data?.data;
};

type UseGetCompanyOptions = {
  companyID?: number;
  config?: Partial<UseQueryOptions<CompanyData>>;
};

export const useGetCompany = ({ companyID, config }: UseGetCompanyOptions) => {
  return useQuery({
    queryKey: ['company', companyID],
    queryFn: () => getCompany({ companyID }),
    ...config,
    enabled: !!companyID,
  });
};
