export default {
  companyReviews: 'تقارير الشركة',
  addMainInfo: 'اضف المعلومات الأساسية',
  mainInfo: 'المعلومات الأساسية',
  mainInfoDescription:
    'غالبًا ما يعتبر المستثمرون الفريق عاملاً رئيسيًا في قراراتهم. الخبرة في الصناعة، والتكامل، والانسجام بين الأعضاء تساهم في بناء الفريق.',
  gicsProgram: 'البرنامج',
  gicsSector: 'الصناعة',
  companyDescription: 'وصف الشركة',
  companyWebsite: 'موقع الشركة الالكتروني',
  cancel: 'الغاء',
  submit: 'ارسال',
  revisit: 'الذهاب للصفحة',
  submitNow: 'ارسال',
  addNow: 'أضف المعلومات',
  notSubmitted: 'لم تسلم',
  optional: 'اختياري',
  done: 'تم التسليم',
  allIsDone: 'تم الانتهاء',
  completeSteps: 'يرجى إكمال جميع الخطوات لتسليم التقرير. يجب عليك إكمال معلومات الخطوات  {{count}} أولاً.',
  submitReport: 'ٍسلم التقرير',
  miskReport: ' تقرير مسك',
  sendMiskReport: 'أرسل تقرير مسك',
  addFinancialInfo: 'اضف المعلومات المالية',
  financialInfo: 'المعلومات المالية',
  financialInfoDescription:
    'غالبًا ما يعتبر المستثمرون الفريق عاملاً رئيسيًا في قراراتهم. الخبرة في الصناعة، والتكامل، والانسجام بين الأعضاء تساهم في بناء الفريق.',
  teamInfo: ' معلومات الفريق',
  addTeamInfo: 'أضف معلومات الفريق',
  miskDescription: '  لاكمال العملية الرجاء تعبئة البيانات قبل {{date}} ,متبقي {{count}} يوم ',
  miskReportSentsuccessfully: 'تم تسليم التقرير بنجاح',
  seeYou: 'نراك في الجولة القادمة',
};
