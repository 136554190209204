import styled, { CSSProperties } from 'styled-components';
import { Card, Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { Title, Text } = Typography;

const StyledCard = styled(Card)<{ dark?: string }>`
  &.ant-card {
    flex: 1;
    background-color: ${(props) => (props.dark ? themeToken.colorPrimary : themeToken.white)};
    color: ${(props) => (props.dark ? themeToken.white : themeToken.colorPrimary)};
    & > .ant-card-body {
      height: 100%;
      padding: 16px;
      border-radius: ${themeToken.borderRadiusLG}px;
      h5 {
        color: ${(props) => (props.dark ? themeToken.white : themeToken.colorPrimary)};
        font-size: 14px;
      }
      & > .ant-flex {
        height: 100%;
      }
      .details {
        .ant-typography {
          font-size: 12px;
          color: #70649b;
        }
      }
      .shareholder_box {
        padding: 12px;
        background: #edeff4;
        border-radius: 4px;
        & > .ant-typography {
          color: ${themeToken.colorPrimary};
          font-size: 12px;
        }
      }
      & > .quick_actions {
        & > .ant-btn {
          gap: 18px;
          background: ${themeToken['branding-natural-5']};
          justify-content: space-between;
          & > svg {
            &:lang(ar) {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
`;

type Props = {
  title?: string;
  subtitle?: string;
  dark?: string;
  style?: CSSProperties;
  children: JSX.Element;
};

const DashboardCard = ({ title, subtitle, dark, style, children }: Props) => {
  return (
    <StyledCard headStyle={{ border: 0 }} dark={dark} style={style}>
      <Flex vertical>
        {title && <Title level={5}>{title}</Title>}
        {subtitle && <Text style={{ color: '#866DDE' }}>{subtitle}</Text>}
      </Flex>
      {children}
    </StyledCard>
  );
};

export default DashboardCard;
