import { type Config, init } from 'mixpanel-browser';
import { type ProviderProps, useMemo } from 'react';
import { type MixpanelContext, MixpanelAppContext } from './mixpanelContext';

export interface MixpanelProviderProps extends Omit<ProviderProps<MixpanelContext>, 'value'> {
  config?: Partial<Config>;
  name?: string;
  token?: string;
}

const { Provider: MixpanelAppProvider } = MixpanelAppContext;

export function MixpanelProvider({ children, config: _config, name: _name, token }: MixpanelProviderProps) {
  const name = useMemo(() => _name ?? 'mixpanel-browser', [_name]);

  const config = useMemo(
    () => ({
      track_pageview: false,
      ..._config,
    }),
    [_config],
  );

  const context = useMemo(() => (token ? init(token, config, name) : undefined), [config, name, token]);

  return <MixpanelAppProvider value={context}>{children}</MixpanelAppProvider>;
}
