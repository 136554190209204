import { axiosClient } from '@/lib/axios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';
import { Limitation } from '@/types/limitations';

type CheckLimitsPayload = {
  companyID: number;
  limitKeys: Limitation[];
};

type CheckLimitsData = {
  limitations: {
    key: Limitation;
    limit: number;
    usage: number;
    expiration_date: string;
    expired: boolean;
    is_limit: boolean;
  }[];
  is_subscription_expired: boolean;
};

export const checkFeatureLimit = async ({ companyID, limitKeys }: CheckLimitsPayload): Promise<CheckLimitsData> => {
  const { data } = await axiosClient.post<{ data: CheckLimitsData }>('/premium/subscription/limitations', {
    companyId: companyID,
    keys: limitKeys,
  });
  return data.data;
};

type UseCheckFeatureLimitsOptions = {
  config?: Partial<UseMutationOptions<CheckLimitsData, AxiosError<{ message: string }>, CheckLimitsPayload>>;
};

export const useCheckFeatureLimits = ({ config }: UseCheckFeatureLimitsOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    ...config,
    mutationFn: checkFeatureLimit,
  });
};
