export default {
  contracts: 'العقود',
  main: ' قائمة العفود',
  contractsHistory: 'العقود المنشئة',
  contractsList: 'قائمة العقود',
  contractListDescription: 'انشئ هقود باحترافية وسهولة',
  searchText: 'بحث',
  view: 'معاينة',
  startCreating: 'ابدأ',
  generatedocumentHistory: 'قائمة العقود المنشئة',
  fileName: 'اسم الملف',
  createdAt: 'تاريخ الانشاء',
  createdBy: 'منشئ العقد',
  actions: 'الخيارات',
  reviewContract: 'راجع العقد بحذر',
  back: 'عودة',
  generate: 'انشئ العقد',
  addContractInformation: 'اضف معلومات العقد',
  confirmAndGenerate: 'تأكيد انشاء العقد',
  confirmation: 'تأكيد',
  documentName: 'اسم العقد',
  delete: 'حذف',
  cancel: 'الغاء',
  edit: 'تعديل',
  save: 'حفظ',
  sureToCancel: 'متأكد من الالغاء',
  sureToDelete: 'متأكد من الحذف',
  addNewRecord: 'إضافة سجل جديد',
  viewInDataRoom: 'استعراض في غرفة البيانات',
  confirmationModalContent:
    'يرجى التأكيد على أنك قد قمت بمراجعة وتعديل جميع الحقول المدخلة. بمجرد التأكيد، سيتم إنشاء العقد. تأكد من أن جميع المعلومات دقيقة وكاملة',
  documentGenratorInProgress: 'العقد قيد الانشاء',
  documentData: '{{name}} جاهز للتنزيل',
  notDocumentData: 'من فضلك انتظر  ({{name}})',
  download: 'تنزيل',
};
