const PresentationChartIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.425 12.75H13.5675C14.9925 12.75 15.7425 12 15.7425 10.575V1.5H2.24249V10.575C2.24999 12 3 12.75 4.425 12.75Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.5 1.5H16.5" stroke={stroke} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 16.5L9 15V12.75" stroke={stroke} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16.5L9 15" stroke={stroke} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.625 8.25L7.9875 6.2775C8.175 6.12 8.4225 6.165 8.55 6.375L9.45 7.875C9.5775 8.085 9.825 8.1225 10.0125 7.9725L12.375 6"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PresentationChartIcon;
