import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import AuthorizedRoute from '@/components/AuthorizedRoute';
import LimitsChecker from '@/components/LimitsChecker';
import ExpiryChecker from '@/components/ExpiryChecker';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const Users = lazy(() => import('../views/users/Users'));
const EditUser = lazy(() => import('../views/users/EditUser'));
const AddNewUser = lazy(() => import('../views/users/AddNewUser'));
const AccessLevels = lazy(() => import('../views/accessLevels/AccessLevels'));
const EditAccessLevel = lazy(() => import('../views/accessLevels/EditAccessLevel'));
const AddAccessLevel = lazy(() => import('../views/accessLevels/AddAccessLevel'));
const ActivityLogs = lazy(() => import('../views/activityLogs/ActivityLogs'));

export const UsersManagementRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route path="users">
          <Route element={<AuthorizedRoute allowedPermissions={['company.users.list']} />}>
            <Route element={<LimitsChecker feature="users" limitKeys={['limits.company.users']} />}>
              <Route index element={<Users />} />
            </Route>
          </Route>
          {/* //* temp route till fixing convention */}
          <Route element={<AuthorizedRoute allowedPermissions={['company.users.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route path="edit/:id" element={<EditUser />} />
            </Route>
          </Route>
        </Route>

        <Route path="user">
          <Route element={<AuthorizedRoute allowedPermissions={['company.users.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route path="new" element={<AddNewUser />} />
            </Route>
          </Route>
          <Route element={<AuthorizedRoute allowedPermissions={['company.users.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route path="edit/:id" element={<EditUser />} />
            </Route>
          </Route>
        </Route>

        <Route path="access-levels">
          <Route element={<AuthorizedRoute allowedPermissions={['company.roles.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route index element={<AccessLevels />} />
            </Route>
          </Route>
          {/* //* temp route till fixing convention */}
          <Route element={<AuthorizedRoute allowedPermissions={['company.roles.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route path="edit/:id" element={<EditAccessLevel />} />
            </Route>
          </Route>
        </Route>

        <Route path="access-level">
          <Route element={<AuthorizedRoute allowedPermissions={['company.roles.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route path="new" element={<AddAccessLevel />} />
            </Route>
          </Route>
          <Route element={<AuthorizedRoute allowedPermissions={['company.roles.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route path="edit/:id" element={<EditAccessLevel />} />
            </Route>
          </Route>
        </Route>

        <Route path="activity-logs">
          <Route element={<AuthorizedRoute allowedPermissions={['company.activity-logs.list']} />}>
            <Route element={<ExpiryChecker />}>
              <Route index element={<ActivityLogs />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
