import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { Flex } from 'antd';
export const CompaniesSidebarContainer = styled(Flex)`
  background-color: white;
  padding-block: ${themeToken.padding}px;
  border-right: 1px solid ${themeToken['branding-natural-1']};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
