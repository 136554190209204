import styled from 'styled-components';
import { Button } from 'antd';
import themeToken from '@lib/theme/tokens/index';

export const StyledSocialButton = styled(Button)`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${themeToken.borderRadiusSM}px;
  gap: 4px;
  color: ${themeToken['branding-natural-8']};
  background-color: ${themeToken['branding-primary-1']};
  box-shadow: none;
  border: none;
`;
