import { useNavigate } from 'react-router-dom';
import { Flex, Grid, Image, Tooltip, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import SettingsIcon from '@/components/Icons/SettingsIcon';
import HamburgerIcon from '@/components/Icons/HamburgerIcon';
import { Dispatch, SetStateAction, useState } from 'react';
import CloseIcon from '@/components/Icons/CloseIcon';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import { StyledButton } from '@/lib/theme/components/Button';
import { EditCompanyIcon } from '@/components/Icons/EditCompanyIcon';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;

type Props = {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  isCompanyLoading: boolean;
};

const ResponsiveSidebar = ({ isSidebarCollapsed, setIsSidebarCollapsed, isCompanyLoading }: Props) => {
  const { t } = useTranslation('layout', {
    keyPrefix: 'sidebar',
  });
  const { sendMixpanelEvent } = useTrackEvent({ isGeneralEvent: true });
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  return (
    <Flex
      align="center"
      style={{
        borderBottom: `1px solid ${themeToken.menuSubmenuBorderColor}`,
        width: '100%',
        background: 'white',
        paddingInlineEnd: `${themeToken.padding}px`,
        height: `${themeToken.controlHeightXXL}px`,
      }}
    >
      <Flex
        style={{
          height: '100%',
          minWidth: '61px',
          background: isSidebarCollapsed ? themeToken['branding-secondary-6'] : themeToken.white,
          cursor: 'pointer',
          borderRight: `1px solid `,
          borderColor: isSidebarCollapsed ? themeToken['branding-secondary-6'] : themeToken.menuSubmenuBorderColor,
        }}
        align="center"
        justify="center"
        onClick={() => {
          setIsSidebarCollapsed(!isSidebarCollapsed);
        }}
      >
        {isSidebarCollapsed ? <HamburgerIcon /> : <CloseIcon onClick={() => {}} />}
      </Flex>
      {isSidebarCollapsed ? (
        <>
          <Flex
            gap={12}
            align="center"
            style={{
              paddingInlineStart: `${themeToken.padding}px`,
              width: '100%',
            }}
          >
            {isCompanyLoading ? (
              <CellSkeleton width="30px" block={false} />
            ) : (
              selectedCompany && (
                <Image
                  preview={false}
                  src={selectedCompany?.logo}
                  alt={`${selectedCompany?.name} logo`}
                  width={30}
                  height={30}
                  placeholder
                />
              )
            )}
            <Flex
              gap={!screens.sm ? 16 : 32}
              align="center"
              style={{
                width: '100%',
              }}
            >
              {isCompanyLoading ? (
                <CellSkeleton />
              ) : (
                selectedCompany && (
                  <Text
                    style={{
                      fontWeight: `${themeToken.fontWeightStrong}`,
                      color: `${themeToken['branding-primary-6']}`,
                      width: screens.xs ? '90%' : '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    ellipsis={{
                      tooltip: isTextOverflowing && selectedCompany?.name,
                      onEllipsis: (ellipsis) => {
                        setIsTextOverflowing(ellipsis);
                      },
                    }}
                  >
                    {selectedCompany?.name}
                  </Text>
                )
              )}
            </Flex>
          </Flex>
          <Flex
            align="center"
            style={{
              marginInlineStart: 'auto',
            }}
          >
            {isCompanyLoading ? (
              <CellSkeleton width="36px" />
            ) : (
              selectedCompany && (
                <Tooltip title={t('editCompany')}>
                  <StyledButton
                    type="text"
                    onClick={() => {
                      sendMixpanelEvent('company-edit-btn-clicked');
                      navigate(`/company/${selectedCompany?.cid}/edit`);
                    }}
                    disabled={!selectedCompany?.is_owner}
                    style={{
                      padding: themeToken.paddingXS,
                    }}
                  >
                    <EditCompanyIcon />
                  </StyledButton>
                </Tooltip>
              )
            )}
            {false && (
              <StyledButton
                type="text"
                style={{
                  padding: themeToken.paddingXS,
                }}
              >
                <SettingsIcon width={18} height={18} />
              </StyledButton>
            )}
          </Flex>
        </>
      ) : (
        <Text
          style={{
            fontWeight: `${themeToken.fontWeightStrong}`,
            color: `${themeToken['branding-primary-6']}`,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {t('mainMenu')}
        </Text>
      )}
    </Flex>
  );
};

export default ResponsiveSidebar;
