import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';
import { StyledInput } from '@/lib/theme/components/Input';

export const HeaderSearchInput = styled(StyledInput)`
  &.ant-input-affix-wrapper {
    display: none;
    border: unset;
    @media (min-width: ${themeToken.screenMD}px) {
      display: flex;
    }
  }
`;
