import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Flex, MenuProps, Typography } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useGetNotifications } from '@/features/company/api/notifications/getNotifications';
import { useMarkNotificationsRead } from '@/features/company/api/notifications/markNotificationsRead';
import { useMarkAllNotificationsRead } from '@/features/company/api/notifications/markAllNotificationsRead';
import { useDeleteNotifications } from '@/features/company/api/notifications/deleteNotification';
import DeleteIcon from '@/components/Icons/DeleteIcon';

import { StyledNotificationBadge } from './NotificationBadge';

const { Text } = Typography;

const NotificationDropdown = () => {
  const { t, i18n } = useTranslation('layout', {
    keyPrefix: 'notifications',
  });
  const { selectedCompany, fullCompanyData } = useSelectedCompanyStore();
  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const [deleteIconID, setDeleteIconID] = useState<number>();

  const { data: notificationsData, isLoading: isNotificationsListLoading } = useGetNotifications({
    companyID: selectedCompany?.cid,
    queryParams: {
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });
  const { mutate: markNotificationRead, isPending: isMarkNotificationLoading } = useMarkNotificationsRead({
    companyID: selectedCompany?.cid as number,
  });
  const { mutate: markAllNotificationRead, isPending: isMarkAllNotificationLoading } = useMarkAllNotificationsRead({
    companyID: selectedCompany?.cid as number,
  });
  const { mutate: deleteNotification, isPending: isDeleteNotificationsLoading } = useDeleteNotifications({
    companyID: selectedCompany?.cid as number,
  });

  const isNotificationsLoading = useMemo(() => {
    return (
      isNotificationsListLoading ||
      isMarkNotificationLoading ||
      isMarkAllNotificationLoading ||
      isDeleteNotificationsLoading
    );
  }, [
    isNotificationsListLoading,
    isMarkNotificationLoading,
    isMarkAllNotificationLoading,
    isDeleteNotificationsLoading,
  ]);

  const notificationsItems: MenuProps['items'] = notificationsData?.data.map((el) => ({
    label: (
      <Flex vertical>
        <Flex gap={12} align="center" justify="space-between">
          <Link
            to="/company/investment-overview"
            onClick={() =>
              !el.is_read &&
              markNotificationRead({
                companyID: selectedCompany?.cid as number,
                notificationID: el.id,
              })
            }
            style={{
              color: el.is_read ? themeToken['branding-natural-6'] : themeToken.colorPrimary,
            }}
          >
            {i18n.language === 'en' ? el.content_en : el.content_ar}
          </Link>
          <StyledButton
            style={{
              padding: `${(themeToken.paddingXXS, themeToken.paddingXS)}`,
              color: themeToken.colorPrimary,
              visibility: deleteIconID === el.id ? 'visible' : 'hidden',
            }}
            size="small"
            type="text"
            onClick={() =>
              deleteNotification({
                companyID: selectedCompany?.cid as number,
                notificationID: el.id,
              })
            }
            disabled={isNotificationsLoading}
          >
            <DeleteIcon />
          </StyledButton>
        </Flex>
        <Text
          style={{
            fontSize: themeToken.fontSizeSM,
            color: themeToken['branding-natural-6'],
            alignSelf: 'flex-end',
          }}
        >
          {dayjs(el.created_at).format('YYYY-MM-DD HH:MM')}
        </Text>
      </Flex>
    ),
    key: el.id,
    onMouseEnter: () => setDeleteIconID(el.id),
    onMouseLeave: () => setDeleteIconID(undefined),
  }));

  const emptyNotificationsItem = {
    label: t('noNotifications'),
    key: 'empty',
    disabled: true,
    style: {
      cursor: 'auto',
    },
  };

  const items: MenuProps['items'] = notificationsItems?.length
    ? [
        {
          label: (
            <StyledButton
              type="text"
              style={{
                padding: `${themeToken.paddingXS}px`,
                marginInlineStart: 'auto',
                fontSize: `${themeToken.fontSizeSM}px`,
                height: '18px',
              }}
              size="small"
              disabled={isNotificationsLoading}
              onClick={() =>
                markAllNotificationRead({
                  companyID: selectedCompany?.cid as number,
                })
              }
            >
              {t('markAllAsRead')}
            </StyledButton>
          ),
          key: 'mark_all',
          disabled: true,
          style: {
            cursor: 'auto',
          },
        },
        {
          type: 'divider',
        },
        ...notificationsItems,
      ]
    : [emptyNotificationsItem];

  useEffect(() => {
    if (notificationsData?.metadata) {
      const { total } = notificationsData.metadata;
      setPaginationParams((prev) => ({ ...prev, total }));
    }
  }, [notificationsData?.metadata]);

  return (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
      <StyledNotificationBadge count={fullCompanyData?.unread_notifications_count} overflowCount={99}>
        <StyledButton
          type="text"
          style={{
            padding: `${themeToken.paddingXS}px`,
          }}
          disabled={isNotificationsLoading}
        >
          <BellOutlined />
        </StyledButton>
      </StyledNotificationBadge>
    </Dropdown>
  );
};

export default NotificationDropdown;
