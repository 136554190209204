import { Tabs } from 'antd';
import styled from 'styled-components';
import themeToken from '../tokens';

const defaultTheme = {
  cardBg: themeToken.colorPrimary,
  itemActiveColor: themeToken.colorPrimary,
  itemColor: themeToken['branding-natural-6'],
  itemSelectedColor: '#FFFFFF',
  inkBarColor: themeToken['branding-primary-4'],
};

export const tabsTheme = {
  ...defaultTheme,
};

export const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    flex: 1;
    .ant-tabs-nav {
      min-width: 144px;
      .ant-tabs-nav-list {
        @media (min-width: ${themeToken.screenLG}px) {
          background: ${themeToken['branding-natural-5']};
          padding: ${themeToken.paddingXS}px 0 0 ${themeToken.paddingXS}px;
          border-radius: ${themeToken.borderRadiusLG}px 0 0 ${themeToken.borderRadiusLG}px;
        }
        & > .ant-tabs-tab {
          @media (min-width: ${themeToken.screenLG}px) {
            text-align: center;
          }
          padding: ${themeToken.paddingXS}px ${themeToken.paddingMD}px;
          &.ant-tabs-tab-active {
            font-weight: ${themeToken.fontWeightStrong};
            background: ${themeToken.colorPrimary};
          }
          & > .ant-tabs-tab-btn {
            width: 100%;
          }
        }
      }
    }
    .ant-tabs-content {
      .ant-tabs-tabpane {
        padding-inline: 24px;
      }
    }
  }
`;
