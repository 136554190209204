export default {
  sendOTP: 'Send OTP',
  accountVerification: 'Account Verification',
  weHaveSentOTP:
    "We've sent a unique OTP to your registered {{method}} {{contact}}. Please enter the OTP below to confirm your identity.",
  enterCode: 'Enter Code',
  resendOTPAfter: 'Resend OTP After',
  useEmailToReceiveOTP: 'Use email to receive OTP',
  usePhoneToReceiveOTP: 'Use phone to receive OTP',
};
