import { axiosClient } from '@/lib/axios';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

type EmailSignInResponse = {
  data: {
    message: 'string';
  };
};

export const emailSignin = async (email: string): Promise<EmailSignInResponse> => {
  const { data } = await axiosClient.post<EmailSignInResponse>('/auth/email-login', { email });
  return data;
};

type UseEmailSignInDTO = {
  config?: MutationFunction<typeof emailSignin>;
  handleAfterSuccess: () => void;
  handleAfterFailure: (code?: number) => void;
};

export const useEmailSignInUser = ({ config, handleAfterSuccess, handleAfterFailure }: UseEmailSignInDTO) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error: AxiosError<{ message?: string; error?: string }>) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message || error.response?.data.error,
      });
      handleAfterFailure(error.response?.status);
    },
    onSuccess: async (res) => {
      toaster.success({
        message: 'Signed In Successfully',
        description: res.data.message,
      });
      handleAfterSuccess?.();
    },
    ...config,
    mutationFn: emailSignin,
  });
};
