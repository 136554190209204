import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { CaptableHistoryData } from '../../types';

type CaptableResponseData = {
  data: CaptableHistoryData[];
  metadata: Metadata;
};

type CaptableParams = {
  companyID: number;
  queryParams?: {
    sort_by?: keyof CaptableHistoryData;
    sort_dir?: 'asc' | 'desc';
    q?: string;
  } & PaginationParams;
};

export const getCaptableHistory = async ({ companyID, queryParams }: CaptableParams): Promise<CaptableResponseData> => {
  const { data } = await axiosClient.get<CaptableResponseData>(`/companies/${companyID}/cap-table/history`, {
    params: queryParams,
  });
  return data;
};
type QueryFnType = typeof getCaptableHistory;

type UseGetCaptableHistoryOptions = {
  companyID: number;
  queryParams?: CaptableParams['queryParams'];
  config?: QueryFunction<QueryFnType>;
};

export const useGetCaptableHistory = ({ companyID, queryParams, config }: UseGetCaptableHistoryOptions) => {
  return useQuery({
    queryKey: ['captable-history', companyID, queryParams],
    queryFn: () => getCaptableHistory({ companyID, queryParams }),
    ...config,
  });
};
