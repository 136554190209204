import { Suspense, lazy } from 'react';

import { Route, Routes } from 'react-router-dom';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import LimitsChecker from '@/components/LimitsChecker';
import ExpiryChecker from '@/components/ExpiryChecker';
import FallbackSpin from '@/components/Shared/FallbackSpin';
import CaptableHistory from '../views/CaptableHistory';
const CaptableOverview = lazy(() => import('../views/CaptableOverview'));
const Equities = lazy(() => import('../views/equities/Equities'));
const AddNewEquity = lazy(() => import('../views/equities/AddNewEquity'));
const IssueShares = lazy(() => import('../views/equities/IssueShares'));
const EquitiesDetails = lazy(() => import('../views/equities/EquityDetails'));
const Options = lazy(() => import('../views/options/Options'));
const AddNewOption = lazy(() => import('../views/options/AddNewOption'));
const GrantOptions = lazy(() => import('../views/options/GrantOptions'));
const OptionsDetails = lazy(() => import('../views/options/OptionDetails'));
const ConvertibleInstruments = lazy(() => import('../views/ConvertibleInstruments'));
const AddNewConvertibleInstruments = lazy(() => import('../views/AddNewConvertibleInstruments'));
const VestingSchedule = lazy(() => import('../views/VestingSchedules/VestingSchedule'));
const AddNewVestingSchedule = lazy(() => import('../views/VestingSchedules/AddNewVestingSchedule'));
const EditVestingSchedule = lazy(() => import('../views/VestingSchedules/EditVestingSchedule'));

export const CaptableRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.overview']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="overview" element={<CaptableOverview />} />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.overview']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="history" element={<CaptableHistory />} />
          </Route>
        </Route>

        <Route path="equities">
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.equity.list']} />}
          >
            <Route element={<LimitsChecker feature="classes" limitKeys={['limits.company.equities']} />}>
              <Route index element={<Equities />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.equity.add']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path="new" element={<AddNewEquity />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.equity.add']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path=":id/issue" element={<IssueShares />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.equity.list']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path=":id" element={<EquitiesDetails />} />
            </Route>
          </Route>
        </Route>

        <Route path="options">
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.option.list']} />}
          >
            <Route element={<LimitsChecker feature="options" limitKeys={['limits.company.options']} />}>
              <Route index element={<Options />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.option.add']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path="new" element={<AddNewOption />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.option.add']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path="grant/:id" element={<GrantOptions />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.option.list']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path=":id" element={<OptionsDetails />} />
            </Route>
          </Route>
        </Route>

        <Route path="convertible-instruments">
          <Route
            element={
              <AuthorizedRoute
                allowedPermissions={['company.cap-table', 'company.cap-table.convertible-instrument.list']}
              />
            }
          >
            <Route
              element={<LimitsChecker feature="convertibles" limitKeys={['limits.company.convertible-instruments']} />}
            >
              <Route index element={<ConvertibleInstruments />} />
            </Route>
          </Route>
          <Route
            element={
              <AuthorizedRoute
                allowedPermissions={['company.cap-table', 'company.cap-table.convertible-instrument.add']}
              />
            }
          >
            <Route element={<ExpiryChecker />}>
              <Route path="new" element={<AddNewConvertibleInstruments />} />
            </Route>
          </Route>
        </Route>

        <Route path="vesting-schedule">
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.vesting.list']} />}
          >
            <Route element={<LimitsChecker feature="schedules" limitKeys={['limits.company.vesting_plan']} />}>
              <Route index element={<VestingSchedule />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.vesting.add']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path="new" element={<AddNewVestingSchedule />} />
            </Route>
          </Route>
          <Route
            element={<AuthorizedRoute allowedPermissions={['company.cap-table', 'company.cap-table.vesting.edit']} />}
          >
            <Route element={<ExpiryChecker />}>
              <Route path="edit/:id" element={<EditVestingSchedule />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
