import { StarIcon } from '@/assets/Icon/star';
import { StyledButton } from '@/lib/theme/components/Button';
import themeToken from '@lib/theme/tokens/index';
import { Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import PlansInfoIcon from '../Icons/PlansInfoIcon';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ListItem = styled.li`
  margin-inline: 8px !important;
  &:before {
    content: '•';
    margin-inline-end: 5px;
    color: white;
  }
`;

const PinnedNotification = ({
  type,
  limits,
  feature,
  expiryInterval,
  expiryDate,
}: {
  type: 'info' | 'error';
  limits?: {
    key: string;
    limit: number;
    usage: number;
    expiration_date: string;
    percentage: number;
  }[];
  feature?: string;
  expiryInterval?: number;
  expiryDate?: string | null;
}) => {
  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'pinnedNotification',
  });
  dayjs.locale(i18n.language);

  const navigate = useNavigate();
  const { sendMixpanelEvent } = useTrackEvent({ isGeneralEvent: true });
  return (
    <Flex
      justify="space-between"
      align="center"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        width: '100%',
        background: themeToken[type === 'error' ? 'branding-red-primary-6' : 'branding-secondary-6'],
        paddingBlock: themeToken.paddingSM,
        paddingInline: themeToken.paddingXL,
        height: type === 'error' ? (limits?.length === 1 ? '80px' : '175px') : '64px',
      }}
    >
      <Flex vertical={type === 'error'} gap={type === 'error' ? 0 : 8}>
        {type === 'info' && <PlansInfoIcon />}
        {type === 'error' ? (
          <Text
            style={{
              fontSize: themeToken.fontSize,
              fontWeight: 400,
              color: themeToken.white,
            }}
          >
            {limits && limits.length === 1 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    limits[0].percentage < 100
                      ? t('exceedMax', {
                          feature: t(feature as string),
                          usage: limits[0].usage,
                          limit: limits[0].limit,
                        })
                      : t('exceededMax', { feature: t(feature as string), limit: limits[0].limit }),
                }}
              ></div>
            ) : limits && limits.length > 1 ? (
              <>
                <Text
                  style={{
                    color: themeToken.white,
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: t('headsUp', { feature: t(feature as string) }) }}></div>
                </Text>
                <ul style={{ listStyle: 'none', margin: 0 }}>
                  {limits.map((limit) => (
                    <ListItem key={limit.key}>
                      <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{t(limit.key)}</span>:{' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${t('limitUsage', { usage: limit.usage, limit: limit.limit })} `,
                        }}
                      />
                      <span style={{ fontWeight: 'bold' }}>{t(limit.key + 's')}</span>
                    </ListItem>
                  ))}
                </ul>
                <Text
                  style={{
                    color: themeToken.white,
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('addMoreUsage', { feature: t(feature as string) }),
                    }}
                  ></span>
                </Text>
              </>
            ) : null}
          </Text>
        ) : (
          <Text
            style={{
              fontSize: themeToken.fontSize,
              fontWeight: 400,
              color: themeToken.white,
            }}
          >
            <Text style={{ fontSize: themeToken.fontSize, color: themeToken.white }}>
              {feature === 'valuation' ? (
                expiryInterval !== undefined && expiryInterval <= 0 ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('valuationSubscriptionEnded', {
                        expiryDate: dayjs(expiryDate).format('MMM DD, YYYY'),
                      }),
                    }}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('valuationSubscriptionEndsSoon', {
                        expiryDate: dayjs(expiryDate).format('MMM DD, YYYY'),
                        expiryInterval,
                      }),
                    }}
                  />
                )
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('yourSubscriptionEndsSoon', {
                      expiryDate: dayjs(expiryDate).format('MMM DD, YYYY'),
                      expiryInterval,
                    }),
                  }}
                />
              )}
            </Text>
          </Text>
        )}
      </Flex>
      <Flex>
        <StyledButton
          size="small"
          ghost
          style={{
            marginInline: 'auto',
            height: '40px',
            padding: `${themeToken.paddingSM}px ${themeToken.paddingLG}px`,
            color: themeToken.white,
            borderColor: themeToken.white,
          }}
          icon={<StarIcon />}
          onClick={() => {
            sendMixpanelEvent(type === 'error' ? 'explore-our-plans-btn-clicked' : 'renew-now-btn-clicked');
            navigate('/go-premium/plans');
          }}
        >
          {type === 'error' ? t('exploreOurPlans') : t('renewNow')}
        </StyledButton>
      </Flex>
    </Flex>
  );
};

export default PinnedNotification;
