const MostPopularIcon = () => {
  return (
    <svg width="73" height="91" viewBox="0 0 73 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddd_6911_85830)">
        <path
          d="M9 4.50918H66C67.1046 4.50918 68 5.40461 68 6.50918V84.5689L38.5 68.5092L9 84.5689V4.50918Z"
          fill="#52C41A"
        />
      </g>
      <path d="M9.10793 4.5093L9.10776 14.5093L-0.000806084 14.5094L9.10793 4.5093Z" fill="#237804" />
      <defs>
        <filter
          id="filter0_ddd_6911_85830"
          x="4"
          y="0.509178"
          width="69"
          height="90.0597"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6911_85830" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_6911_85830" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_6911_85830" result="effect2_dropShadow_6911_85830" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_6911_85830" result="effect3_dropShadow_6911_85830" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6911_85830" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MostPopularIcon;
