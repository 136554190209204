import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Flex, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { formatNumber } from '@/utils/formatNumber';

import type { CaptableHistoryData } from '../types';

export const captableHistoryColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'history'>,
  lang: string,
): ColumnsType<CaptableHistoryData | Record<string, number>> => [
  {
    title: t('actionType'),
    dataIndex: 'entity_type',
    render: withLoadingSkeleton(isLoading, (val: string) => t(val)),
  },
  {
    title: t('transaction'),
    dataIndex: 'name',
    render: withLoadingSkeleton(isLoading, (val: string, record) => (
      <Flex gap={8}>
        {val}

        {record?.converted_from_name && (
          <Tag
            color="magenta"
            style={{
              height: 'max-content',
            }}
          >
            {record?.converted_from_name}
          </Tag>
        )}
      </Flex>
    )),
  },
  {
    title: t('shareholderName'),
    dataIndex: 'user_name',
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
  {
    title: t('amount'),
    dataIndex: 'amount',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val)),
  },
  {
    title: t('approvalDate'),
    dataIndex: 'approval_date',
    render: withLoadingSkeleton(isLoading, (val: string) => dayjs(val).locale(lang).format('ddd MMM DD YYYY')),
  },
];
