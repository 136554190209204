import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledPlanUpgradeModal = styled(Modal)`
  .ant-modal-content {
    background: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: unset;
  }
`;
