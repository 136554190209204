import { Flex } from 'antd';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

export const PageWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${themeToken.borderRadiusLG}px;
  background-color: #fff;
  padding: ${themeToken.padding}px ${themeToken.padding}px;
  box-shadow: ${themeToken.boxShadow};
  min-height: calc(100vh - 60px - 66px - 48px);
`;
