import { useEffect } from 'react';
import styled from 'styled-components';
import { Form, Divider, Flex, Typography, Checkbox } from 'antd';

import themeToken from '@lib/theme/tokens/index';

import { useCreateNewFolder } from '../api/createNewFolder';
import { useEditFolder } from '../api/editFolder';

import { CreateNewFolderPayload } from '../types';

import { StyledInput } from '@/lib/theme/components/Input';
import { StyledButton } from '@/lib/theme/components/Button';
import { StyledForm } from '@/components/Shared/Form';
import CommonModal from '@/features/captable/components/Shared/CommonModal';

import FolderIcon from '@/components/Icons/FolderIcon';

import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { LimitsCheckerContext } from '@/components/LimitsChecker';

const { Text } = Typography;

const StyledCheckBox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${themeToken['branding-primary-6']};
      border-color: ${themeToken['branding-primary-6']};
    }
  }
`;

type Props = {
  isCreateNewFolderOpen: boolean;
  handleCancel: () => void;
  parentFolderLevel: number;
  folderId: number;
  folderSettings?: { id: number; name: string; permissions: string[] };
};

const CreateNewFolderForm = ({
  isCreateNewFolderOpen,
  handleCancel,
  parentFolderLevel,
  folderId,
  folderSettings,
}: Props) => {
  const { t } = useTranslation('dataroom');
  const { recheckLimits } = useOutletContext<LimitsCheckerContext>() || {};
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [form] = Form.useForm();

  const resetForm = () => {
    handleCancel();
    form.resetFields();
    recheckLimits?.();
  };

  const { mutate: createNewFolderMutation, isPending } = useCreateNewFolder({
    parentFolderLevel,
    postSuccess: resetForm,
  });

  const { mutate: editFolderMutation, isPending: isEditPending } = useEditFolder({
    parentFolderLevel: folderId === 0 ? 1 : 2,
    postSuccess: resetForm,
  });

  const onFinish = (values: { name: string; permissions?: ('watermarked' | 'downloadable')[] }): void => {
    const payload: CreateNewFolderPayload = {
      name: values.name,
      folderId,
      watermarked: values.permissions?.includes('watermarked') ? 1 : 0,
      downloadable: values.permissions?.includes('downloadable') ? 1 : 0,
    };

    if (folderSettings) {
      const { folderId: _, ...rest } = payload;
      editFolderMutation({ company_id: selectedCompany?.cid as number, folder_id: folderSettings.id, payload: rest });
    } else createNewFolderMutation({ company_id: selectedCompany?.cid as number, payload });
  };

  useEffect(() => {
    if (folderSettings) {
      form.setFieldsValue({ name: folderSettings?.name, permissions: folderSettings?.permissions });
    }
  }, [form, folderSettings]);

  return (
    <CommonModal isModalOpen={isCreateNewFolderOpen} handleCancel={resetForm}>
      <Flex gap={14}>
        <FolderIcon />
        <Text
          style={{
            color: themeToken['branding-primary-6'],
            fontWeight: 600,
            fontSize: themeToken.fontSizeLG,
            marginBottom: themeToken.marginXXS,
          }}
        >
          {t('createNewFolder')}
        </Text>
      </Flex>
      <Divider
        style={{
          marginBlock: themeToken.margin,
          borderColor: themeToken['branding-natural-3'],
        }}
      />

      <StyledForm
        form={form}
        initialValues={{
          name: folderSettings?.name,
          permissions: folderSettings?.permissions,
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Flex
          align="start"
          vertical
          style={{
            flex: 1,
          }}
        >
          <Form.Item
            label={t('folderName')}
            name="name"
            style={{
              width: '100%',
            }}
          >
            <StyledInput placeholder={t('folderName')} />
          </Form.Item>

          <Form.Item name="permissions" label={t('folderOptions')} required tooltip={t('fieldRequired')}>
            <Checkbox.Group>
              <StyledCheckBox value="downloadable">{t('downloadable')}</StyledCheckBox>
              <StyledCheckBox value="watermarked">{t('watermarked')}</StyledCheckBox>
            </Checkbox.Group>
          </Form.Item>

          <Divider
            style={{
              marginBlock: themeToken.margin,
              borderColor: themeToken['branding-natural-3'],
            }}
          />

          <Flex style={{ width: '100%' }} justify="space-between">
            <StyledButton ghost type="primary" onClick={resetForm}>
              {t('back')}
            </StyledButton>
            <StyledButton disabled={isPending || isEditPending} type="primary" htmlType="submit">
              {t('submit')}
            </StyledButton>
          </Flex>
        </Flex>
      </StyledForm>
    </CommonModal>
  );
};

export default CreateNewFolderForm;
