export const XlsFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159956)">
      <path
        d="M4.27637 3.24324V3.24323C4.27634 1.8026 5.45395 0.625 6.89461 0.625H14.7133L21.2238 7.42075V20.998C21.2238 22.4492 20.0531 23.625 18.6056 23.625H6.89461C5.45487 23.625 4.27637 22.4483 4.27637 20.998V3.24324Z"
        fill="#00733B"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.5439 6.65858H15.4176V1.36077L20.5439 6.65858Z" fill="white" stroke="#EDEFF4" />
      <path
        d="M11.2387 17.5992H10.0738L9.27672 16.259L8.47963 17.5992H7.30591L8.68986 15.2692L7.47236 13.2459H8.64604L9.27669 14.297L9.89857 13.2459H11.0723L9.86352 15.278L11.2387 17.5992ZM11.6504 17.5992V13.2459H12.7628V16.6532H14.6548V17.5992H11.6504V17.5992ZM16.4942 17.6517C16.0562 17.6517 15.6709 17.5116 15.338 17.2401C15.0139 16.9773 14.83 16.6444 14.8037 16.2503L15.7585 15.97C15.7848 16.1802 15.8723 16.3642 16.0213 16.5043C16.1789 16.6445 16.3541 16.7145 16.5468 16.7145C16.7044 16.7145 16.8358 16.6795 16.9409 16.6094C17.0373 16.5393 17.0898 16.443 17.0898 16.3291C17.0898 16.2328 17.046 16.1539 16.9672 16.0839C16.8884 16.0226 16.7833 15.97 16.6606 15.9262C16.538 15.8824 16.3891 15.8386 16.2402 15.8036C16.0825 15.7598 15.9249 15.7072 15.776 15.6372C15.6183 15.5759 15.4781 15.497 15.3555 15.4007C15.2241 15.3131 15.1278 15.1817 15.0489 15.0153C14.9701 14.8576 14.9263 14.6649 14.9263 14.4459C14.9263 14.0868 15.0752 13.789 15.3818 13.5525C15.6884 13.3072 16.0562 13.1934 16.4854 13.1934C16.9146 13.1934 17.2912 13.2985 17.6066 13.4999C17.9219 13.7101 18.1234 13.9817 18.2022 14.3145L17.2037 14.735C17.1599 14.551 17.0723 14.4021 16.9496 14.2883C16.827 14.1832 16.6694 14.1218 16.4854 14.1218C16.3453 14.1218 16.2402 14.1569 16.1526 14.2095C16.0737 14.262 16.0387 14.3408 16.0387 14.4372C16.0387 14.5248 16.0913 14.6036 16.1964 14.6649C16.3015 14.7175 16.4416 14.7613 16.5993 14.7875C16.7569 14.8138 16.9321 14.8576 17.1161 14.9189C17.3088 14.989 17.4752 15.0591 17.6416 15.1554C17.7993 15.243 17.9307 15.3919 18.0358 15.5846C18.1496 15.7861 18.2022 16.0226 18.2022 16.2941C18.2022 16.697 18.0445 17.0299 17.7292 17.2751C17.4138 17.5204 17.0023 17.6517 16.4942 17.6517Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159956">
        <rect width="24" height="24" fill="white" transform="translate(0.75 0.125)" />
      </clipPath>
    </defs>
  </svg>
);
