import { InputNumber } from 'antd';
import styled from 'styled-components';

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-input-wrap {
    input {
      height: 40px;
    }
  }
`;
