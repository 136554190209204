import { Breadcrumb } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  iconFontSize: themeToken.fontSizeIcon,
  itemColor: themeToken.colorPrimaryHover,
  lastItemColor: themeToken.breadcrumbLastItemColor,
  linkColor: themeToken.colorPrimaryHover,
  linkHoverColor: themeToken.colorPrimary,
  separatorColor: themeToken.colorPrimaryHover,
  paddingXXS: themeToken.paddingXS,
};

const globalTheme = {
  colorBgTextHover: themeToken.colorBgTextHover,
  fontFamily: 'Cairo',
  fontSize: themeToken.fontSizeXSM,
  lineHeight: 1.8,
};

export const breadcrumbTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledBreadcrumb = styled(Breadcrumb)`
  &.ant-breadcrumb {
    margin-bottom: ${themeToken.marginSM}px;
    margin-top: ${themeToken.marginXXS}px;
    & > ol {
      align-items: center;
      li {
        display: inline-flex;
        span {
          &.ant-dropdown-trigger {
            display: inline-flex;
            align-items: center;
            font-size: ${themeToken.fontSizeSM}px;
            line-height: 20px;
          }
        }
        .ant-breadcrumb-link {
          display: flex;
          align-items: center;
          column-gap: ${themeToken.sizeXXS}px;
          & > a {
            display: flex;
            gap: 4px;
            align-items: center;
          }
        }
      }
    }
  }
`;
