const ChartSquareIcon = ({
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.58235 8.36255H5.59486C5.12236 8.36255 4.73987 8.74501 4.73987 9.21751V13.0575H7.58235V8.36255V8.36255Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57081 4.94995H8.43079C7.95829 4.94995 7.57581 5.33246 7.57581 5.80496V13.05H10.4183V5.80496C10.4183 5.33246 10.0433 4.94995 9.57081 4.94995Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.411 9.63745H10.4235V13.05H13.266V10.4925C13.2585 10.02 12.876 9.63745 12.411 9.63745Z"
        stroke={stroke}
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75009 16.5H11.2501C15.0001 16.5 16.5001 15 16.5001 11.25V6.75C16.5001 3 15.0001 1.5 11.2501 1.5H6.75009C3.00009 1.5 1.50009 3 1.50009 6.75V11.25C1.50009 15 3.00009 16.5 6.75009 16.5Z"
        fill="white"
        fillOpacity="0.29"
        stroke={stroke}
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChartSquareIcon;
