import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { UseGetCompanyValuationStatus } from '../api/status';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { Flex, Spin } from 'antd';

export const RouteWrapper = ({ children, routeName }: { children: ReactElement; routeName: string }) => {
  const selectedCompanyID = useSelectedCompanyStore((state) => state.selectedCompany?.cid);
  const { data: status, isLoading } = UseGetCompanyValuationStatus({
    urlParams: { companyID: selectedCompanyID as number },
  });

  const isNegativeValuation = status?.valuation_negative;

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{
          height: '100vh',
          width: '100%',
        }}
      >
        <Spin />
      </Flex>
    );
  }

  if (!status) {
    return <Navigate to="/valuation/dashboard" replace />;
  }
  if (!status.has_valuation && routeName !== 'dashboard') {
    return <Navigate to="/valuation/dashboard" replace />;
  }

  if (!status.valuation_registered && status.has_valuation && routeName !== 'questionnaire') {
    return <Navigate to="/valuation/questionnaire" replace />;
  }

  if (!status.questionnaires && (routeName === 'reports' || routeName === 'financial')) {
    return <Navigate to="/valuation/questionnaire" replace />;
  }

  if (!status.financials && routeName === 'reports') {
    return <Navigate to="/valuation/financial" replace />;
  }
  if (isNegativeValuation && routeName === 'reports') {
    return <Navigate to="/valuation/dashboard" replace />;
  } else {
    return children;
  }
};
