import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import themeToken from '@lib/theme/tokens/index';

import type { FolderData } from '../types';
import { useTrackEvent } from '@/hooks/useTrackEvents';

const { Text } = Typography;

type Props = {
  file: FolderData;
  setSelectedFile: (file: FolderData) => void;
  setIsFilePreviewOpen: (open: boolean) => void;
  isVertical?: boolean;
  closeDropdown?: () => void;
};

const OpenFile = ({ file, setSelectedFile, setIsFilePreviewOpen, closeDropdown, isVertical = true }: Props) => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');

  return (
    <Flex
      key="Open-action"
      vertical={isVertical}
      align="center"
      gap={isVertical ? 2 : 8}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        sendMixpanelEvent('open-file-btn-clicked');
        e.stopPropagation();

        setSelectedFile(file);
        setIsFilePreviewOpen(true);
        closeDropdown?.();
      }}
    >
      <InfoCircleOutlined style={{ color: themeToken['branding-primary-6'] }} />
      <Text
        style={{
          color: themeToken['branding-primary-3'],
          fontSize: themeToken.fontSizeSM,
          fontWeight: 400,
        }}
      >
        {t('open')}
      </Text>
    </Flex>
  );
};
export default OpenFile;
