import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface SelectedConsultantState {
  selectedConsultant: number | null;
  setSelectedConsultant: (consultantID: number | null) => void;
}

export const useSelectedConsultantStore = create<SelectedConsultantState>()(
  devtools(
    persist(
      (set) => ({
        selectedConsultant: null,
        setSelectedConsultant: (selectedConsultant) => set(() => ({ selectedConsultant })),
      }),
      { name: 'selectedConsultantStore' },
    ),
  ),
);
