export const TextFileIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8593_159976)">
      <path
        d="M4.40051 3.37443V3.37442C4.40049 1.92344 5.57392 0.75 7.02494 0.75H14.8357L21.3495 7.53952V21.1256C21.3495 22.5772 20.1755 23.75 18.7311 23.75H7.02494C5.57395 23.75 4.40051 22.5766 4.40051 21.1256V3.37443Z"
        fill="#251D36"
        stroke="#EDEFF4"
      />
      <path opacity="0.302" d="M20.6704 6.78449H15.5433V1.48578L20.6704 6.78449Z" fill="white" stroke="#EDEFF4" />
      <path
        d="M8.43463 17.7739V14.3616H7.18726V13.4141H10.7915V14.3616H9.5501V17.7739H8.43463ZM14.8454 17.7739H13.676L12.8784 16.4365L12.0808 17.7739H10.9054L12.2907 15.447L11.0793 13.4141H12.2487L12.8784 14.4695L13.5021 13.4141H14.6775L13.4662 15.453L14.8454 17.7739ZM16.2008 17.7739V14.3616H14.9594V13.4141H18.5636V14.3616H17.3162V17.7739H16.2008Z"
        fill="#F6F7FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_159976">
        <rect width="24" height="24" fill="white" transform="translate(0.875 0.25)" />
      </clipPath>
    </defs>
  </svg>
);
