import styled from 'styled-components';
import { Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';

const { Title } = Typography;

export const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
  &.ant-typography.title {
    margin-top: ${themeToken.marginXXS}px;
    margin-bottom: ${themeToken.marginXL}px;
  }
`;
