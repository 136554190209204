import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

import { StyledButton } from '@/lib/theme/components/Button';

import goPremiumModalImg from '@/assets/premium/goPremiumModalImg.png';

import TaskIcon from '@/components/Icons/TaskIcon';
import ChartSquareIcon from '@/components/Icons/ChartSquareIcon';
import GraphIcon from '@/components/Icons/GraphIcon';
import { StarIcon } from '@/assets/Icon/star';
import { StyledPlanUpgradeModal } from './StyledPlanUpgradeModal';
import MostPopularIcon from '@/components/Icons/MostPopularIcon';
import GradientTitle from './GradientTitle';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import { useGetPlans } from '../api/getPlans';
import { useEffect, useMemo, useState } from 'react';
import { Plan } from '../types';
import PresentationChartIcon from '@/components/Icons/PresentationChartIcon';
import { UserIcon } from '@/components/Icons/UserIcon';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const PlanUpgradeModal = ({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) => {
  const { t } = useTranslation('plans');
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { data: plansResponse, isLoading: isPlansLoading } = useGetPlans({});
  const [recommendedPlan, setRecommendedPlan] = useState<Plan | undefined>();

  const featuresIconsMap: Record<string, JSX.Element> = useMemo(
    () => ({
      user: <UserIcon stroke="#6430DA" width={24} height={24} />,
      cap: <GraphIcon stroke="#6430DA" width={24} height={24} />,
      folder: <TaskIcon stroke="#6430DA" width={24} height={24} />,
      chart: <ChartSquareIcon stroke="#6430DA" width={24} height={24} />,
      presentation: <PresentationChartIcon stroke="#6430DA" width={24} height={24} />,
    }),
    [],
  );

  useEffect(() => {
    if (plansResponse) {
      setRecommendedPlan(() => plansResponse.data.find(({ recommended }) => recommended));
    }
  }, [plansResponse]);

  return (
    <StyledPlanUpgradeModal closeIcon={false} open={isOpen} onCancel={onCancel} width={892} footer={null} centered>
      <Flex vertical>
        <Flex justify="space-between" style={{ background: '#fff' }}>
          <Flex
            vertical
            gap={24}
            justify="space-between"
            style={{
              position: 'relative',
              padding: !screens.xs ? `${themeToken.paddingLG}px ${themeToken.paddingXL}px` : `${themeToken.padding}px`,
              width: '100%',
            }}
          >
            <Flex
              style={{
                position: 'absolute',
                top: -15,
                insetInlineEnd: 40,
              }}
            >
              <MostPopularIcon />
            </Flex>
            <Flex
              style={{
                position: 'absolute',
                top: 3,
                insetInlineEnd: 52,
              }}
            >
              <Text
                style={{
                  width: '42px',
                  fontSize: themeToken.fontSizeHeading7,
                  fontWeight: themeToken.fontWeightStrong,
                  color: themeToken.white,
                  textAlign: 'center',
                }}
              >
                {recommendedPlan?.tag && t(recommendedPlan.tag)}
              </Text>
            </Flex>

            <Flex vertical gap={16}>
              <GradientTitle
                level={3}
                style={{
                  fontWeight: 600,
                }}
              >
                {t('upgradeYourLimits')}
              </GradientTitle>

              <Flex
                justify="space-between"
                align="center"
                style={{ width: '100%', marginBlockStart: screens.xs ? '8px' : '0' }}
              >
                <Flex>
                  {isPlansLoading ? (
                    <CellSkeleton width="90px" />
                  ) : (
                    <Text
                      style={{
                        color: themeToken['branding-primary-10'],
                        fontWeight: 600,
                        fontSize: themeToken.fontSizeLG,
                      }}
                    >
                      {t('planName', { name: recommendedPlan?.name })}
                    </Text>
                  )}
                </Flex>

                <Flex gap={themeToken.paddingXS} align="center">
                  {isPlansLoading ? (
                    <>
                      <CellSkeleton width="87px" height="40px" shape="square" block={false} />
                      <Flex vertical align="start" justify="center">
                        <CellSkeleton width="90px" />
                        <CellSkeleton width="90px" />
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Text
                        style={{
                          color: themeToken['branding-primary-10'],
                          fontWeight: 600,
                          fontSize: screens.xs ? '26px' : '32px',
                        }}
                      >
                        SAR&nbsp;{recommendedPlan?.price}
                      </Text>
                      <Flex vertical align="start" justify="center">
                        <Text
                          style={{
                            color: themeToken['branding-natural-8'],
                            fontWeight: 600,
                            fontSize: themeToken.fontSizeSM,
                          }}
                        >
                          {t('annualPlanPrice')}
                        </Text>
                        <Text
                          style={{
                            color: themeToken['branding-natural-8'],
                            fontWeight: 600,
                            fontSize: themeToken.fontSizeSM,
                          }}
                        >
                          {t('validDuration', { duration: recommendedPlan?.duration })}
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Flex>

              <Flex vertical gap={themeToken.paddingMD}>
                {isPlansLoading
                  ? Array(3)
                      .fill(0)
                      .map((_, index) => (
                        <Flex key={index} gap={themeToken.padding}>
                          <Flex style={{ marginTop: '4px' }}>
                            <CellSkeleton width="20px" height="20px" shape="square" />
                          </Flex>

                          <Flex vertical gap={4}>
                            <CellSkeleton width="120px" />
                            <CellSkeleton width="220px" />
                          </Flex>
                        </Flex>
                      ))
                  : recommendedPlan?.features.map(({ name, description, icon, id }) => (
                      <Flex key={id} gap={themeToken.padding}>
                        <Flex style={{ marginTop: '4px' }}>{featuresIconsMap[icon]}</Flex>

                        <Flex vertical gap={4}>
                          <Text
                            style={{
                              color: themeToken['branding-primary-6'],
                              fontWeight: 700,
                              fontSize: themeToken.fontSizeLG,
                            }}
                          >
                            {t(name)}
                          </Text>
                          <Text
                            style={{
                              color: themeToken['branding-primary-10'],
                              fontSize: themeToken.fontSizeSM,
                            }}
                          >
                            {t(description)}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
              </Flex>
            </Flex>
            <Flex vertical>
              <StyledButton
                size="small"
                type="primary"
                style={{
                  marginInline: 'auto',
                  width: screens.xs ? '100%' : '303px',
                  height: '40px',
                  background: themeToken['branding-secondary-6'],
                  padding: `${themeToken.paddingSM}px ${themeToken.paddingLG}px`,
                }}
                icon={<StarIcon />}
                onClick={() => navigate('/go-premium/plans')}
              >
                {t('upgradePlanButton')}
              </StyledButton>

              <StyledButton
                size="small"
                type="text"
                style={{
                  color: themeToken['branding-primary-10'],
                }}
                onClick={onCancel}
              >
                {t('continueFreePlanButton')}
              </StyledButton>
            </Flex>
          </Flex>
          {!screens.xs && (
            <Flex>
              <img width={493} src={goPremiumModalImg} alt="" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </StyledPlanUpgradeModal>
  );
};

export default PlanUpgradeModal;
