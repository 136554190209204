import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import FallbackSpin from '@/components/Shared/FallbackSpin';

const Profile = lazy(() => import('../views/Profile'));

export const UserRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route path="profile" element={<Profile />} />
      </Routes>
    </Suspense>
  );
};
