import { axiosClient } from '@/lib/axios';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

type PhoneSignInResponse = {
  data: {
    message: 'string';
  };
};

export const phoneSignin = async (phone: string): Promise<PhoneSignInResponse> => {
  const { data } = await axiosClient.post<PhoneSignInResponse>('/auth/phone-login', { phone });
  return data;
};

type UsePhoneSignInDTO = {
  config?: MutationFunction<typeof phoneSignin>;
  handleAfterSuccess: () => void;
  handleAfterFailure: (code?: number) => void;
};

export const usePhoneSignInUser = ({ config, handleAfterSuccess, handleAfterFailure }: UsePhoneSignInDTO) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error: AxiosError<{ message: string }>) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
      handleAfterFailure(error.response?.status);
    },
    onSuccess: async (res) => {
      toaster.success({
        message: 'Signed In Successfully',
        description: res.data.message,
      });
      handleAfterSuccess();
    },
    ...config,
    mutationFn: phoneSignin,
  });
};
