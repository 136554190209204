const FolderIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1667 10.084V15.584C20.1667 19.2507 19.25 20.1673 15.5833 20.1673H6.41668C2.75001 20.1673 1.83334 19.2507 1.83334 15.584V6.41732C1.83334 2.75065 2.75001 1.83398 6.41668 1.83398H7.79168C9.16668 1.83398 9.46918 2.23732 9.99168 2.93398L11.3667 4.76732C11.715 5.22565 11.9167 5.50065 12.8333 5.50065H15.5833C19.25 5.50065 20.1667 6.41732 20.1667 10.084Z"
        fill="#6430DA"
        fillOpacity="0.15"
        stroke="#6430DA"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.33334 1.83398H15.5833C17.4167 1.83398 18.3333 2.75065 18.3333 4.58398V5.84898"
        stroke="#6430DA"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderIcon;
